import { render, staticRenderFns } from "./homeitem2.vue?vue&type=template&id=55c8e52a&scoped=true"
import script from "./homeitem2.vue?vue&type=script&lang=js"
export * from "./homeitem2.vue?vue&type=script&lang=js"
import style0 from "./homeitem2.vue?vue&type=style&index=0&id=55c8e52a&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55c8e52a",
  null
  
)

export default component.exports