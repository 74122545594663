export const code= [
  {
    id:1,
    'code': '86',
    'msType': 1130,
    'country': 'CN',
    name:'中国'
  },
  {
    id:2,
    'code': '886',
    'msType': 1131,
    'country': 'TW',
    name:'中国台湾'
  },
  {
    id:3,
    'code': '852',
    'msType': 1132,
    'country': 'HK',
    name:'中国香港'
  },
  {
    id:4,
    'code': '44',
    'msType': 1133,
    'country': 'UK',
    name:'英国'
  },
  {
    id:5,
    'code': '1',
    'msType': 1134,
    'country': 'CA',
    name:'加拿大'
  },
  {
    id:6,
    'code': '1',
    'msType': 1135,
    'country': 'US',
    name:'美国'
  },
  {
    id:7,
    'code': '62',
    'msType': 1136,
    'country': 'ID',
    name:'印尼'
  },
  {
    id:8,
    'code': '49',
    'msType': 1137,
    'country': 'GM',
    name:'德国'
  },
  {
    id:9,
    'code': '33',
    'msType': 1138,
    'country': 'FR',
    name:'法国'
  },
  {
    id:10,
    'code': '61',
    'msType': 1139,
    'country': 'AS',
    name:'澳大利亚'
  },
  {
    id:11,
    'code': '39',
    'msType': 1140,
    'country': 'IT',
    name:'意大利'
  },
  {
    id:12,
    'code': '82',
    'msType': 1141,
    'country': 'KS',
    name:'韩国'
  },
  {
    id:13,
    'code': '81',
    'msType': 1142,
    'country': 'JA',
    name:'日本'
  },
  {
    id:14,
    'code': '7',
    'msType': 1143,
    'country': 'RS',
    name:'俄罗斯'
  },
  {
    id:15,
    'code': '63',
    'msType': 1144,
    'country': 'PH',
    name:'菲律宾'
  },
  {
    id:16,
    'code': '65',
    'msType': 1145,
    'country': 'SN',
    name:'新加坡'
  },
  {
    id:17,
    'code': '853',
    'country': 'MO',
    name:'中国澳门'
  },
  {
    id:18,
    'code': '60',
    'country': 'MY',
    name:'马来西亚'
  },
  {
    id:19,
    'code': '34',
    'country': 'ES',
    name:'西班牙'
  },
  {
    id:20,
    'code': '64',
    'country': 'NZ',
    name:'新西兰'
  },
  {
    id:21,
    'code': '66',
    'country': 'TH',
    name:'泰国'
  },
  {
    id:22,
    'code': '358',
    'country': 'FI',
    name:'芬兰'
  },
  {
    id:23,
    'code': '31',
    'country': 'NL',
    name:'荷兰',
  },
  {
    id:24,
    'code': '94',
    'country': 'LK',
    name:'斯里兰卡',
  },
  {
    id:25,
    'code': '41',
    'country': 'CH',
    name:'瑞士',
  },
  {
    id:26,
    'code': '32',
    'country': 'BE',
    name:'比利时',
  },
  {
    id:27,
    'code': '46',
    'country': 'SE',
    name:'瑞典',
  },
  {
    id:28,
    'code': '353',
    'country': 'IE',
    name:'爱尔兰',
  }
]
