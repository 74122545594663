import Vue from 'vue'
import 'amfe-flexible'
import App from './App.vue'
import '@/assets/styles/global.less'
import '@/assets/styles/animate.css'
import router from "./router"
import http from "./fetch"
import VueClipboard from 'vue-clipboard2'
import WX from 'weixin-js-sdk'
Vue.use(VueClipboard)
Vue.prototype.$http = http
Vue.prototype.$wx = WX
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App),
  mounted() {
    // document.dispatchEvent(new Event('render-event'))
    document.dispatchEvent(new Event('custom-render-trigger'))
  },
}).$mount('#app')
