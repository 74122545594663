<template>
  <div class="navigation" ref="scrollContainer" @scroll="handleScroll">
    <ul>
      <li v-for="time in listData" :key="time.id">
        <div class="listBox">
          <div class="left">
            <div class="leftBox">
              <img v-show="time.jioyizhuangtai == 1 && time.hierarchy <= 2" src="../assets/images/agent/yonghuB.png"
                alt="图片">
              <img v-show="time.hierarchy > 2" src="../assets/images/agent/yongjinxj.png" alt="图片">
              <img v-show="time.jioyizhuangtai == 0 && time.hierarchy <= 2" src="../assets/images/agent/yongjinbbb.png"
                alt="图片">
            </div>
            <div class="middleBox">
              <div class="middleA">
                <p>昵称:{{ time.nice_name }}</p>
              </div>
              <div class="middleB">
                <p>手机号:{{ time.phone }}</p>
                <p style="color: #FF0B13;margin-left: 10px;">{{ time.hierarchy > 2 ? '下家邀请' : '' }}</p>
                <p style="color: #FF0B13;margin-left: 10px;">{{ time.jioyizhuangtai == 0 && time.hierarchy == 2 ? '暂未充值'
    : '' }}</p>
              </div>
              <!-- <div class="middleC">
                <p>{{ time.invitation_time | formatDate }}</p>
              </div> -->
            </div>
            <!-- <div v-show="time.jioyizhuangtai == 0 && time.hierarchy == 2"> -->
            <!-- <p style="font-size: 15px; color: #FF0B13;margin-left: 15px;">暂未充值</p> -->
            <!-- </div> -->
          </div>
          <div class="rigthBox">
            <p>{{ time.invitation_time | formatDate }}</p>
          </div>
        </div>
      </li>
      <div class="tipsBox" v-if="loading">
        <p>加载中...</p>
      </div>
      <div class="tipsBox" v-if="!loading">
        <p>- 没有更多内容了 -</p>
      </div>
    </ul>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data() {
    return {
      page: 1,
      limit: 8,
      listData: [],
      user_id: '',
      loading: false,
      finish: true
    };
  },
  filters: {
    formatDate(timestamp) {
      const date = new Date(timestamp * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  },
  created() {
    var token = sessionStorage.getItem('userinfo');
    if (!token) {
      this.$router.push('/m-logon');
    }

    this.user_id = JSON.parse(sessionStorage.getItem('userinfo')).user_id;
    this.getData();
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
  methods: {
    async getData() {
      if (this.loading) return;
      this.loading = true;
        var thisc = this;
        axios.post('https://teach-api.go-master.com/v4/Gomaster/getAegntData', {
          user_id: this.user_id,
          page: this.page,
          limit: this.limit,
        })
          .then(function (response) {
            thisc.listData.push(...response.data);
            // thisc.listData.unshift(...response.data);
            thisc.loading = false;
          })
          .catch(function (error) {
            console.error('Error fetching user list:', error);
          });
    },

    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container.scrollTop + container.clientHeight >= container.scrollHeight - 200 ) {
        // alert(`触发！${this.page}`);
        this.page += 1;
          this.getData();
      }
    },

  }

};
</script>

<style scoped lang="less">
.navigation {
  margin: 0 10px;
  height: 70vh;
  overflow-y: auto;

  .tipsBox {
    text-align: center;
    font-size: 13px;
    color: #979797;
    margin: 20px 0;
  }

  li {
    list-style: none;

    .listBox {
      box-sizing: border-box;
      padding: 0 20px 0 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 70px;
      background-color: #ffffff;
      margin-top: 10px;
      border-radius: 10px;

      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .leftBox {
        padding-right: 5px;
        text-align: center;

        img {
          width: 35px;
          height: 35px;
        }
      }

      .middleBox {
        margin-left: 10px;

        .middleA {
          color: black;
          font-size: 14px;
          margin-bottom: 5px;

        }

        .middleB {
          color: rgb(0, 0, 0);
          font-size: 10px;
          margin-top: 5px;
          display: flex;
        }

        .middleC {
          color: rgb(190, 190, 190);
          font-size: 10px;
        }
      }

      .rigthBox {
        font-size: 12px;

        img {
          width: 25px;
          height: 25px;
          text-align: center;
        }
      }
    }
  }
}
</style>