<template>
  <div class="report">
    <div class="head">
      <div class="head-image">
        <img :src="require(`../assets/images/${Info.avatar}.png`)" alt="">
      </div>
      <div class="head-info">
        <span class="level">{{Info.rank || ''}}</span>
        <span class="name">{{Info.nickName || ''}}</span>
      </div>
    </div>
    <div class="content">
      <div class="learn-time content-item">
        <div class="content-item-title">学习时间</div>
        <div class="content-item-body">
          <p>在 GO Master 坚持学习</p>
          <p style="margin-top: 30px;color:#ffab09;font-weight: 600;"><span
              style="font-size: 48px;margin-right: 5px">{{Info.studyTime|| 0}}</span>天</p>
        </div>
      </div>

      <div class="content-item">
        <div class="content-item-title">课程记录</div>
        <div class="content-item-body">
          <div class="learn-course">
            <div class="block-three">
              <div class="block-three-icon"></div>
              <p><span class="three-num">{{parseInt(Info.attendClass / 60) || 0}}</span> 分钟 </p>
              <p>认真听课</p>
            </div>
            <div class="block-three">
              <div class="block-three-icon"></div>
              <p><span class="three-num">{{Info.interactiveCount || 0}}</span> 次 </p>
              <p>课堂互动</p>
            </div>
            <div class="block-three">
              <div class="block-three-icon"></div>
              <p><span class="three-num">{{Info.achievement || 0}}</span> 颗 </p>
              <p>熊猫币</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-item">
        <div class="content-item-title">练习记录</div>
        <div class="content-item-body circle">
          <Circles class="circle" v-model="currentRate" :clockwise="false" size="30vw" :stroke-width="50" :rate="Info.exceedPerson"
                   color="#fdd928" layer-color="#f8f7fe" :text="`已超越\n${Info.exceedPerson|| 0}% \n的小朋友`"></Circles>
          <div class="record-box">
            <div class="record-item">
              <p class="info"><span class="right num">{{Info.rightCount|| 0}}</span> 题</p>
              <p>正确题数</p>
            </div>
            <div class="record-item">
              <p class="info"><span class="error num">{{Info.wrongCount || 0}}</span> 题</p>
              <p>错误题数</p>
            </div>
            <div class="record-item">
              <p class="info" v-if="Info.duration"><span class="num">{{Info.duration[0]}}</span> 分<span class="num">{{Info.duration[1]}}</span> 秒</p>
              <p>所用时长</p>
            </div>
            <div class="record-item">
              <p class="info"><span class="num">{{Info.winCount || 0}}</span> 胜<span class="num">{{Info.loseCount || 0}}</span> 负</p>
              <p>对弈结果</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-item">
        <div class="content-item-title">知识点</div>
        <div class="content-item-body left">
          <div class="knowledge-item" v-for="(item,index) in Info.knowledgePoints" :key="index">
            <span class="icon"></span><span class="knowledge-name">{{ item }}</span>
          </div>
        </div>
      </div>
      <div class="content-item go-master">
        <div class="content-item-title video-title">GO Master</div>
        <div class="video-box">
          <video src="https://boke-test-upload.oss-cn-shanghai.aliyuncs.com/jlxtest/gomastertest/video/GoMasterIntro1112.mp4" controls="controls" width="100%" style="border-radius: 20px" poster="../assets/images/video_cover.png"></video>
        </div>
      </div>
    </div>

    <div class="bottom-btn">
      <Button round color="#FF9700" style="width: 90%;margin-top: 8px;" class="btn" @click="handleOpen">限时免费 立即下载</Button>
    </div>
  </div>

</template>

<script>
import { Button } from 'vant'
import Circles from 'vant/lib/circle'
import 'vant/lib/circle/style'
import {Progress} from "vant"
import {getBrowser} from "@/assets/js/tool";

export default {
  name: "report",
  components: {
    Circles,
    Progress,
    Button
  },
  data() {
    return {
      knowledge: ['围棋的起源', '什么是棋盘', '什么是气', '星位的辨认', '打吃'],
      currentRate: 0,
      tempForm: {
        userId: '',
        lessonNo: ''
      },
      Info:{
        avatar:'face_1',
        percentage:0,
        duration:[0,0]
      }
    }
  },
  created() {
    const query = this.$route.query
    if(JSON.stringify(query) !== '{}'){
      this.tempForm.userId = query.userId
      this.tempForm.lessonNo = query.lessonNo
    }
    document.title = 'GoMaster-学习报告'
  },
  mounted() {
    if(this.tempForm.userId !== '' && this.tempForm.lessonNo !== ''){
      this.getInfo()
    }

  },
  methods: {
    handleOpen() {
      if (getBrowser.versions.ios) {
        // window.location.href="https://apps.apple.com/cn/app/go-master-%E5%9C%A8%E7%BA%BF%E5%8F%8C%E8%AF%AD%E5%9B%B4%E6%A3%8B/id1566542127"
        window.open("https://apps.apple.com/cn/app/go-master-%E5%9C%A8%E7%BA%BF%E5%8F%8C%E8%AF%AD%E5%9B%B4%E6%A3%8B/id1566542127")
        // window.location.href="mobRestoreScene://ssdk2c574691c6986"
      }else if(getBrowser.versions.weixin){
        window.location.href="https://a.app.qq.com/o/simple.jsp?pkgname=com.GoMaster.Bilingual"
      } else if (getBrowser.versions.android) {
        window.location.href="https://api.go-master.com/api/download/android"
      }else{
        window.location.href="https://api.go-master.com/api/download/android"
      }
    },
    getInfo() {
      this.$http.get("study/report", this.tempForm).then((res) => {
        res.duration = [parseInt(res.duration/60),(res.duration - parseInt(res.duration/60) * 60)]
        this.Info = res
      });
    }
  }
}
</script>

<style lang="less" scoped>
//
.report {
  position: relative;
  width: 100vw;
  //height: 300vh;
  padding-bottom: 150px;
  .bottom-btn{
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
    background: #ffffff;
    text-align: center;
    .btn{
      font-size: 18px;
      font-family: PingFangTC, PingFangTC-Regular;
    }
  }
  .head {
    width: 100vw;
    height: 50vh;
    overflow: hidden;
    background-image: url("../assets/images/head_bg.png");
    background-size: 100% 100%;
    z-index: 5;

    .head-image {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 4px solid white;
      margin: 50px auto 0;
      overflow: hidden;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    .head-info {
      width: 170px;
      height: 40px;
      background: white;
      border-radius: 20px;
      margin: 10px auto;
      display: table;

      span {
        height: 30px;
        font-size: 16px;
        margin: 0 10px;
        vertical-align: middle;
        display: table-cell;
        text-align: center;
      }

      .level {
        width: 50px;
        background: #1fc0fc;
        color: white;
        padding: 5px 10px;
        border-radius: 15px;
      }

      .name {
        font-family: inherit;
        font-weight: 600;
      }
    }
  }

  .content {
    position: absolute;
    top: 37vh;
    width: 100vw;
    height: auto;
    background: #9bdeff;
    overflow: hidden;
    font-size: 18px;

    .content-item {
      width: 90%;
      min-height: 20vh;
      background: white;
      border-radius: 20px;
      margin: 20px auto;

      &.learn-time {
        margin-top: 0;
      }

      &.go-master{
        margin-bottom: 70px;
      }

      .video-box{
        width: 95%;
        margin: 33px auto;
      }

      .content-item-title {
        width: 50%;
        height: 32px;
        margin: 0 auto;
        background-image: url("../assets/images/block_div_title.png");
        background-size: 100% 100%;
        font-size: 16px;
        font-weight: 600;
        color: white;
        text-align: center;
        line-height: 32px;

        &.video-title{
          font-family: PingFangSC-Semibold, sans-serif;
          font-size: 18px;
          background-image: url("../assets/images/sewq_img_title2.png");
        }
      }

      .content-item-body {
        text-align: center;
        padding: 3vh 0;

        &.left {
          text-align: left;
        }

        .learn-course {
          width: 90%;
          margin: 0 auto;

          .block-three {
            width: 30%;
            height: 15vh;
            background-color: #fff6e7;
            border-radius: 10px;
            display: inline-block;
            margin-top: 20px;

            > p {
              font-size: 14px;
              color: #5e340c;
              font-weight: 600;

              &:nth-child(3) {
                font-size: 12px;
                font-weight: 500;
                color: #b29778;
              }

              .three-num {
                font-size: 20px;
              }
            }

            &:nth-child(2) {
              margin: 0 5%;
            }

            &:nth-child(3) {
              .block-three-icon {
                background-image: url("../assets/images/icon_2.png");
              }
            }

            &:nth-child(4) {
              .block-three-icon {
                background-image: url("../assets/images/icon_3.png");
              }
            }


            .block-three-icon {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              overflow: hidden;
              margin: -20px auto 10px;
              background-image: url("../assets/images/icon_1.png");
              background-size: 100% 100%;
            }
          }


          .process {
            width: 100%;
            //height: 15vh;
            background-color: #f2f8ff;
            border-radius: 10px;
            padding: 10px 15px;
            text-align: left;

            span {
              color: #3769ac;
              font-size: 14px;

              &:nth-child(2) {
                color: #81a4c8;
                float: right;
              }
            }

            .van-progress {
              margin-top: 10px;
            }

            p {
              margin-top: 10px;
              font-size: 12px;
              color: #81a4c8;
              text-align: center;
            }
          }
        }

        &.circle {
          margin-top: 15px;
        }

        .circle {
          //width: 40%;
          margin-right: 5%;
          //overflow: hidden;
        }

        .record-box {
          vertical-align: top;
          width: 55%;
          display: inline-block;
          background-color: #f0f9ff;
          border-radius: 10px;

          .record-item {
            margin: 10px 0;
            font-size: 14px;
            display: inline-block;
            width: 50%;
            color: #90adcf;

            .info {
              color: #366cac;
            }

            //text-align: left;
            .num {
              font-size: 18px;
              font-weight: 600;
              color: #366cac;
            }

            .right {
              color: #48cc83;
            }

            .error {
              color: #f3746f;
            }
          }
        }

        .knowledge-item {
          text-align: left;
          text-indent: 20px;
          margin: 10px 0;
          font-size: 12px;
          display: inline-block;
          width: 50%;
          font-weight: 600;

          .icon {
            width: 20px;
            height: 20px;
            display: inline-block;
            background-image: url("../assets/images/small_icon.png");
            background-size: 100% 100%;
            vertical-align: middle;
          }

          .knowledge-name {
            vertical-align: middle;
          }
        }
      }
    }
  }
}
</style>
