<template>
  <div class="home-item-3">
    <BlockTitle>多年教研打磨<br />看得见的用心和信心</BlockTitle>
    <ul class="swipe-btns">
      <li
        v-for="(o, i) in list"
        :key="i"
        :class="active == i ? 'active-btn' : ''"
        @click="handleSetActiveSwipe(i)"
      >
        {{ o.btn }}
      </li>
    </ul>

    <div class="swipe-w">
      <div class="swipe-w-content">
        <i class="swipe-w-content-l"></i>
        <i class="swipe-w-content-r"></i>
        <Swipe
        ref="swipe"
          :loop="false"
          touchable
          :show-indicators="false"
          stop-propagation
          indicator-color="#FFB538"
          @change="handleSwipeChange"
        >
          <SwipeItem
            v-for="(o, i) in list"
            :key="i"
            @touchmove="handlePreventDefault"
          >
            <img :src="o.img" alt="" />
            <div class="swipe-desc">
              {{ o.text }}
            </div>
          </SwipeItem>
        </Swipe>
        <div class="monkey animated swing infinite"></div>
      </div>
    </div>
  </div>
</template>

<script>
import BlockTitle from "./blocktitle";
import { Swipe, SwipeItem } from "vant";
export default {
  name: "home-item-3",
  components: {
    BlockTitle,
    Swipe,
    SwipeItem,
  },
  data() {
    return {
      active: 0,
      list: [
        {
          btn: "双师教学模式",
          img: require("../assets/images/srwq_introduse_pic01_img@2x.png"),
          text: "课程提供中文教学、全英文教学、中英文教学三种模式，报名后都可以根据小朋友需要灵活选择。",
        },
        {
          btn: "双语灵活选择",
          img: require("../assets/images/srwq_introduse_pic02_img@2x.png"),
          text: "注重孩子思路培养举一反三式教学， 微信群实时答疑课后班主任跟踪。",
        },
        {
          btn: "配套练习",
          img: require("../assets/images/srwq_introduse_pic03_img@2x.png"),
          text: "我们提供配套的APP给孩子AI闯关练棋，不定期举办的班级、全球围棋赛事更能促进孩子的学习动力。",
        },
      ],
    };
  },
  methods: {
    handleSwipeChange(index){
      this.active=index
    },
    handleSetActiveSwipe(index){
      this.active=index
      this.$refs.swipe.swipeTo(index)
    },
    handlePreventDefault(e) {
      e.preventDefault();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
::v-deep.home-item-3 {
  width: 100%;
  background: #f7f9fc;
  padding-bottom: 0px;
  .swipe-btns {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
    li {
      width: 108px;
      height: 37px;
      text-align: center;
      line-height: 37px;
      opacity: 1;
      background: #ffffff;
      border: 1px solid #d6dae6;
      border-radius: 19px;
      font-size: 14px;
      font-family: PingFangTC, PingFangTC-Medium;
      font-weight: 500;
      text-align: center;
      color: #999999;
    }
    .active-btn {
      background: #2fccc4;
      color: #ffffff;
      border-color: #2fccc4;
    }
  }
  .swipe-w {
    width: 351px;
    margin: 0 auto;
    height: 300px;
    padding-top: 20px;
    &-content {
      width: 100%;
      height: 201px;
      background: #fbfbfc;
      border-radius: 21px;
      box-shadow: 1px 1px 12px 3px rgba(140, 174, 214, 0.31);
      position: relative;
      padding: 12px 28px 12px 18px;
      .monkey {
        width: 120px;
        height: 93px;
        background: url("../assets/images/srwq_middle_monkey_img@2x.png")
          no-repeat;
        background-position: 0 0;
        background-size: cover;
        position: absolute;
        bottom: -47px;
        left: 27px;
        transform-origin: bottom center;
        animation-duration: 3s;
        animation-timing-function: linear;
      }
      .monkey {
        width: 120px;
        height: 93px;
        background: url("../assets/images/srwq_middle_monkey_img@2x.png")
          no-repeat;
        background-position: 0 0;
        background-size: cover;
        position: absolute;
        bottom: -55px;
        left: 27px;
        transform-origin: bottom center;
        animation-duration: 3s;
        animation-timing-function: linear;
      }
      &-l {
        width: 5px;
        height: 23px;
        opacity: 1;
        background: #e7e7e7;
        border-radius: 3px;
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
      &-r {
        width: 15px;
        height: 15px;
        opacity: 1;
        background: #e7e7e7;
        border-radius: 50%;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
      .van-swipe {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 7px;
        .van-swipe-item {
          position: relative;
          img {
            display: block;
            width: 100%;
            height: auto;
          }
          .swipe-desc {
            width: 100%;
            height: 65px;
            opacity: 1;
            background: #2fccc4;
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: 11px;
            font-family: PingFangSC-Regular, sans-serif;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 16px;
            padding: 10px 25px 0 16px;
          }
        }
      }
    }
  }
}
</style>
