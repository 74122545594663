<template>
  <div class="dou">
    <div class="dou-item dou-item-1">
      <img src="@/assets/images/dou/sewq_banner@2x.png" alt="" />
      <div class="dou-item-1-box">
        <section class="dou-item-1-box-top">
          <img
            src="@/assets/images/dou/sewq_banner_gift_img@2x.png"
            alt=""
            class="dou-item-1-box-top-gift"
          />
          <img
            :src="courses[courseType].desc"
            alt=""
            class="dou-item-1-box-top-desc"
          />
        </section>
        <img
          :src="courses[courseType].card"
          alt=""
          class="dou-item-1-box-bottom"
        />
      </div>
    </div>
    <div class="dou-item dou-item-2">
      <img
        src="@/assets/images/dou/sewq_reason_title@2x.png"
        alt=""
        class="dou-item-title"
      />
      <img src="@/assets/images/dou/sewq_reason_detile@2x.png" alt="" />
    </div>
    <div class="dou-item dou-item-3">
      <img
        src="@/assets/images/dou/sewq_teacher_title@2x.png"
        alt=""
        class="dou-item-title"
      />
      <section>
        <Swipe
          autoplay="3000"
          class="teacher"
          :loop="true"
          :show-indicators="true"
          touchable
          stop-propagation
          indicator-color="#FFB538"
          width="300px"
        >
          <SwipeItem v-for="(o, i) in teachers" :key="i" class="teacher-swipe">
            <img :src="o" alt="" />
          </SwipeItem>
        </Swipe>
      </section>
    </div>
    <div class="dou-item dou-item-3 dou-item-4">
      <img
        src="@/assets/images/dou/sewq_feature_title@2x.png"
        alt=""
        class="dou-item-title"
      />
      <section>
        <Swipe
          autoplay="2000"
          class="teacher"
          :loop="true"
          :show-indicators="true"
          touchable
          stop-propagation
          indicator-color="#FFB538"
          width="300px"
        >
          <SwipeItem
            v-for="(o, i) in advantages"
            :key="i"
            class="teacher-swipe"
          >
            <img :src="o" alt="" />
          </SwipeItem>
        </Swipe>
      </section>
    </div>
    <div class="dou-item dou-item-5">
      <br />
      <img src="@/assets/images/dou/sewq_learningpage@2x.png" alt="" />
    </div>
    <div class="dou-item dou-item-6">
      <img
        src="@/assets/images/dou/sewq_learningdetile_title@2x.png"
        alt=""
        class="dou-item-title"
      />
      <img src="@/assets/images/dou/sewq_learningdetile@2x.png" alt="" />
    </div>
    <div class="dou-item dou-item-7">
      <img
        src="@/assets/images/dou/sewq_problem_title@2x.png"
        alt=""
        class="dou-item-title"
      />
      <img src="@/assets/images/dou/sewq_problem@2x.png" alt="" />
    </div>
    <div class="copyright">
      沪ICP备2021009246号-3 | 营业执照 ©2021<br />上海玖连星教育科技有限公司
      所有权利保留
    </div>
    <div class="apply">
      <div class="apply-inner">
        <div class="apply-inner-l animated pulse infinite">
          <img src="@/assets/images/dou/¥@2x.png" alt="" />
          <img src="@/assets/images/dou/6@2x.png" alt="" />
        </div>
        <router-link to="/m-dou-pay"
          ><img
            src="@/assets/images/dou/sewq_start_btn@2x.png"
            class="apply-inner-r"
        /></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from "vant";
export default {
  components: { Swipe, SwipeItem },
  name: "Dou1",
  data() {
    return {
      courseType: 1,
      courses: [
        {
          card: require("@/assets/images/dou/sewq_banner_0@2x.png"),
          button: require("@/assets/images/dou/0@2x.png"),
          desc: require("@/assets/images/dou/sewq_banner_content_0@2x.png"),
        },
        {
          card: require("@/assets/images/dou/sewq_banner_6@2x.png"),
          button: require("@/assets/images/dou/6@2x.png"),
          desc: require("@/assets/images/dou/sewq_banner_content_6@2x.png"),
        },
      ],
      teachers: [
        require("@/assets/images/dou/sewq_teacher_01@2x.png"),
        require("@/assets/images/dou/sewq_teacher_02@2x.png"),
        require("@/assets/images/dou/sewq_teacher_03@2x.png"),
        require("@/assets/images/dou/sewq_teacher_04@2x.png"),
      ],
      advantages: [
        require("@/assets/images/dou/sewq_feature_01@2x.png"),
        require("@/assets/images/dou/sewq_feature_02@2x.png"),
      ],
      showDialog: false,
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
::v-deep.dou {
  width: 100%;
  background: #fef8f1;
  padding-bottom: 72px;
  &-item {
    width: 100%;
    & > img {
      display: block;
      width: 100%;
    }
    &-title {
      display: block;
      height: 29px;
      width: auto !important;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
  &-item-1 {
    position: relative;
    &-box {
      width: 100%;
      height: 180px;
      position: absolute;
      left: 0;
      bottom: 0;
      &-top {
        width: 100%;
        img {
          display: block;
        }
        &-gift {
          width: 155px;
          position: absolute;
          left: 0;
          top: -60px;
          animation: mySwing 4s linear infinite;
        }
        &-desc {
          height: 27px;
          position: absolute;
          left: 160px;
          top: 6px;
        }
      }
      &-bottom {
        width: 120px;
        position: absolute;
        left: 20px;
        top: 0;
        transform: translateY(80%);
      }
    }
  }
  &-item-2 {
    width: 100%;
  }
  &-item-3 {
    .dou-item-title {
      margin-bottom: 20px;
    }
    section {
      width: 340px;
      height: 227px;
      margin: 0 auto;
      background: #ffffff;
      box-shadow: 0px 6px 15px 0px rgba(255, 169, 9, 0.2);
      border-radius: 25px;
      padding: 10px 18px;
      .van-swipe {
        height: 200px;
      }
      .van-swipe-item {
        img {
          display: block;
          width: 100%;
          border-radius: 20px;
        }
      }
      .van-swipe__indicators {
        bottom: 0;
        .van-swipe__indicator {
          width: 8px;
          height: 8px;
        }
      }
    }
  }
  &-item-4 {
    section {
      height: 259px;
      .van-swipe {
        height: 220px;
      }
    }
  }
  .copyright {
    height: 40px;
    background: rgba(255, 214, 9, 0.22);
    font-size: 8px;
    font-family: FZY3JW--GB1-0, FZY3JW--GB1;
    font-weight: normal;
    color: #dbc77e;
    text-align: center;
    line-height: 1.4;
    padding-top: 5px;
  }
  .apply {
    width: 100%;
    height: 72px;
    background: #ffd609;
    padding-left: 17px;
    position: fixed;
    bottom: 0;
    &-inner {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;
      justify-content: flex-start;
      transform: translateY(-10px);
      &-l {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
        justify-content: flex-start;
        img {
          &:nth-of-type(1) {
            width: 18px;
          }
          &:nth-of-type(2) {
            width: 90px;
          }
        }
      }
      &-r {
        height: 50px;
        margin-left: 10px;
        transform: translateY(12px);
      }
    }
  }
}
</style>
