<template>
  <div class="m-wrap">
    <!-- <TopBar v-if="showTop" @closed="handleAfterClose"></TopBar> -->
    <div class="m-header">
      <div class="m-header-logo">
        <router-link to="/"
          ><img src="@/assets/images/srwq_logo_img@2x.png" alt=""
        /></router-link>
      </div>
      <div class="m-header-nav">
        <Popover
          v-model="showPopover"
          trigger="click"
          placement="bottom-end"
          get-container=".m-wrap"
        >
          <template #default>
            <ul>
              <li>
                <a :href="`${baseUrl}`">首页</a>
              </li>
              <li><a :href="`${baseUrl}m-system`">课程体系</a></li>
              <!-- <li><a :href="`${baseUrl}m-course/courseIndex`" >教学中心</a></li> -->
              <li @click="gotoRouter"><a>教学中心</a></li>
              <li><a :href="`${baseUrl}download`">客户端下载</a></li>
              <li><a @click="outLogin">退出登录</a></li>

            </ul>
          </template>
          <template #reference>
            <img
              v-show="!showPopover"
              src="@/assets/images/srwq_list_icon@2x.png"
              alt=""
              class="m-header-nav-menu"
            />
            <img
              v-show="showPopover"
              src="@/assets/images/srwq_btn@2x.png"
              alt=""
              class="m-header-nav-menu"
            />
          </template>
        </Popover>
      </div>
    </div>
  </div>
</template>

<script>
import { Popover } from "vant";
import TopBar from "./topbar";

export default {
  name: "m-header",
  components: { Popover, TopBar },
  data() {
    return {
      user_id:false,
      showPopover: false,
      baseUrl:process.env.VUE_APP_BASE_ROUTER,
      showTop: true,
      timer: null,
    };
  },
  mounted() {
    !sessionStorage.getItem("closeTop")
      ? window.addEventListener("touchstart", this.handleClearInterval)
      : "";
    !sessionStorage.getItem("closeTop")
      ? window.addEventListener("touchmove", this.handleScroll)
      : "";
  },
  beforeDestroy() {
    !sessionStorage.getItem("closeTop")
      ? window.removeEventListener("touchstart", this.handleClearInterval)
      : "";
    !sessionStorage.getItem("closeTop")
      ? window.removeEventListener("touchmove", this.handleScroll)
      : "";
  },
  methods: {
    gotoRouter(){
      this.user_id = sessionStorage.getItem('userinfo');
      if(this.user_id){
        window.location.href = `${this.baseUrl}m-course/courseIndex`;
      }else{
        window.location.href = `${this.baseUrl}m-logon`;
      }
    },
    handleAfterClose() {
      window.removeEventListener("touchstart", this.handleClearInterval);
      window.removeEventListener("touchmove", this.handleScroll);
      this.handleClearInterval();
    },
    handleClearInterval() {
      clearInterval(this.timer);
      this.timer = null;
    },
    handleScroll() {
      let top;
      if (!this.timer) {
        this.timer = setInterval(() => {
          top = document.querySelector("#app").scrollTop;
          if (top >= 120) {
            this.showTop = false;
          } else {
            this.showTop = true;
          }
        }, 100);
      }
    },
    outLogin(){
      localStorage.removeItem('userinfo');
      sessionStorage.removeItem('userinfo');
			this.$router.push('/m-logon');
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
::v-deep.m-wrap {
  width: 100%;
  position: fixed;
  z-index: 9999;
  .m-header {
    width: 100%;
    height: 46px;
    opacity: 1;
    background: #ffffff;
    box-shadow: -2px 0px 12px 5px rgba(107, 141, 181, 0.07);
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;

    &-logo {
      height: 42px;
      img {
        display: block;
        height: 100%;
      }
    }
    &-nav {
      height: 35px;
      width: 35px;
      &-menu {
        width: 100%;
        transform: translate(-22px, 0);
        &:nth-of-type(2) {
          transform: translate(-22px, 5px);
        }
      }
    }
  }
  .van-popup {
    .van-popover__arrow {
      display: none;
    }
    .van-popover__content {
      transform: translateY(-10px);
      position: relative;
      z-index: 5;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
      padding-top: 10px;
      ul {
        opacity: 1;
        padding: 0 20px;
        li {
          width: 100%;
          a {
            display: block;
            width: 100%;
            font-size: 14px;
            font-family: PingFangTC, PingFangTC-Medium;
            text-align: center;
            color: #2fccc4;
            line-height: 60px;
            white-space: nowrap;
          }
          &:not(:nth-last-of-type(1)) {
            a {
              border-bottom: 1px solid #dedede;
            }
          }
        }
      }
    }
  }
}
</style>
