<template>
  <div
    v-if="!$route.meta.hideTopAndBottom"
    :class="showTop ? 'top-bar' : 'top-bar has-close'"
  >
    <img
      src="@/assets/images/srwq_deleted_icon@2x.png"
      alt=""
      class="top-bar-close"
      @click="handleClose"
    />
    <div class="top-bar-box">
      <img
        src="@/assets/images/app.png"
        alt=""
        class="top-bar-box-logo"
      />
      <div class="top-bar-box-tip">
        <b>GO Master - AI数智人围棋导师</b>
        <span>打开APP，学习操作更流畅</span>
      </div>
      <div class="top-bar-box-open" @click="handleOpen">打开</div>
    </div>
  </div>
</template>

<script>
import { getBrowser } from "../assets/js/tool";
export default {
  name: "top-bar",
  data() {
    return {
      showTop: sessionStorage.getItem("closeTop") ? false : true,
    };
  },
  methods: {
    handleClose() {
      this.showTop = false;
      sessionStorage.setItem("closeTop", "1");
      document.querySelector("#main-content").style.paddingTop = "56px";
      this.$emit("closed");
    },
    handleOpen() {
      if (getBrowser.versions.weixin) {
        window.location.href =
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.GoMaster.Bilingual";
      } else {
        if (getBrowser.versions.ios) {
          window.open(
            "https://apps.apple.com/cn/app/go-master-%E5%9C%A8%E7%BA%BF%E5%8F%8C%E8%AF%AD%E5%9B%B4%E6%A3%8B/id1566542127"
          );
        } else if (getBrowser.versions.android) {
          window.location.href =
            "https://api.go-master.com/api/download/android";
        } else {
          window.location.href =
            "https://api.go-master.com/api/download/android";
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.top-bar {
  width: 100%;
  height: 66px;
  opacity: 1;
  background: #131415;
  padding: 8px 13px;
  position: relative;
  padding-left: 49px;
  transition: all 0.2s ease-in-out;
  img {
    display: block;
  }
  &-close {
    width: 35px;
    height: 35px;
    position: absolute;
    top: 0;
    left: 0;
  }
  &-box {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    &-logo {
      width: 50px;
      height: 51px;
      margin-right: 7px;
    }
    &-tip {
      flex: 1;
      width: 0;
      b,
      span {
        display: block;
        width: 100%;
      }
      b {
        font-size: 13px;
        font-family: PingFangTC, PingFangTC-Semibold;
        text-align: left;
        color: #ffffff;
        margin-bottom: 5px;
      }
      span {
        font-size: 10px;
        font-family: PingFangTC, PingFangTC-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
      }
    }
    &-open {
      width: 66px;
      height: 28px;
      opacity: 1;
      background: #ffcb38;
      border-radius: 16px;
      line-height: 28px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangTC, PingFangTC-Semibold;
      font-weight: 600;
      color: #131415;
    }
  }
}
.has-close {
  height: 0;
  overflow: hidden;
  padding: 0 13px;
}
</style>
