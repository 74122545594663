<template>
  <div class="container">
    <div class="navigation" ref="scrollContainer" @scroll="handleScroll">
      <ul>
        <li v-for="time in listData" :key="time.index">
          <div class="listBox">
            <div class="left">
              <div class="leftBox">
                <img  v-show="time.type == 0 && time.hierarchy != 2" src="../assets/images/agent/yongjin.png" alt="图片">
                <img  v-show="time.type == 1 && time.hierarchy != 2" src="../assets/images/agent/tixiantuB.png" alt="图片">
                <img  v-show="time.type == 2 && time.hierarchy != 2" src="../assets/images/agent/tixianzhong.png" alt="图片">
                <img v-show="time.hierarchy == 2" src="../assets/images/agent/xiajiayonjin.png" alt="图片">
              </div>
              <div class="middleBox">
                <div class="middleA" v-show="time.type == 1 && time.hierarchy != 2">
                  <p>余额提现</p>
                </div>
                <div class="middleA" v-show="time.type == 0 && time.hierarchy != 2">
                  <p>佣金发放</p>
                </div>
                <div class="middleA" v-show="time.type == 2 && time.hierarchy != 2">
                  <p>提现中</p>
                </div>
                <div class="middleA" v-show="time.hierarchy == 2">
                  <p>下家收益</p>
                </div>
                <div class="middleC">
                  <p>{{ time.creation_time | formatDate }}</p>
                </div>
              </div>
            </div>
            <div class="rigthBox" v-show="time.type == 1 || time.type == 2">
              <p>{{ time.transaction }}</p>
            </div>
            <div class="rigthBox" v-show="time.type == 0">
              <p style="color: red;">+{{ time.transaction }}</p>
            </div>
          </div>
        </li>
        <div class="tipsBox" v-if="loading">
          <p>加载中...</p>
        </div>
        <div class="tipsBox" v-if="!loading">
          <p>- 没有更多内容了 -</p>
        </div>
      </ul>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'index_page',
  data() {
    return {
      page: 1,
      limit: 8,
      listData: [],
      user_id: '',
      loading: false,
    };
  },
  filters: {
    formatDate(timestamp) {
      const date = new Date(timestamp * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}年${month}月${day}日`;
    }
  },
  created() {
		var token = sessionStorage.getItem('userinfo');
		if (!token) {
      this.$router.push('/m-logon');
		}

    this.user_id = JSON.parse(sessionStorage.getItem('userinfo')).user_id;
    this.getData();
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
  methods: {
    async getData() {
      if (this.loading) return;
      this.loading = true;
      var thisc = this;
      axios.post('https://teach-api.go-master.com/v4/Gomaster/getFlowingData', {
        user_id: this.user_id,
        page: this.page,
        limit: this.limit,
      })
        .then(function (response) {
          // thisc.listData.unshift(...response.data);
          thisc.listData.push(...response.data);

          thisc.loading = false;
        })
        .catch(function (error) {
          console.error('Error fetching user list:', error);
        });
    },
    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container.scrollTop + container.clientHeight >= container.scrollHeight - 200 ) {
        // alert(`触发！${this.page}`);
        this.page += 1;
          this.getData();
      }
    },
  }

};
</script>

<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #ffffff;
}

.container {
  height: 100vh;
  margin: 0 10px;
}


.span {
  color: rgb(253, 200, 135);
}

.navigation {
  height: 70vh;
  overflow-y: auto;

  .tipsBox {
    text-align: center;
    font-size: 13px;
    color: #979797;
    margin: 20px 0;
  }

  li {
    list-style: none;

    .listBox {
      box-sizing: border-box;
      padding: 0 20px 0 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 70px;
      background-color: #ffffff;
      margin-top: 10px;
      border-radius: 10px;
      overflow-y: auto;

      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .leftBox {
        padding-right: 5px;
        display: flex;
        align-items: center;
        // text-align: center;

        img {
          // text-align: center;
          width: 30px;
          height: 30px;
        }
      }

      .middleBox {
        margin-left: 10px;

        .middleA {
          color: black;
          font-size: 16px;
          margin-bottom: 5px;
        }

        .middleC {
          color: rgb(190, 190, 190);
          font-size: 10px;
          margin-top: 5px;
        }
      }

      .rigthBox {
        font-size: 18px;

        img {
          width: 25px;
          height: 25px;
          text-align: center;
        }
      }
    }
  }
}
</style>