<template>
  <div class="page">
    <div class="card">
      <div class="title">
        <div class="titlebefore"></div>
        订单信息
      </div>
      <div class="info">
        <div class="col">
          <div class="drak">报名账号：</div>
          <div class="valu">{{ this.query.mobile }}</div>
        </div>
        <div class="col">
          <div class="drak">订单信息：</div>
          <div class="valu">{{ this.query.title }}</div>
        </div>
        <div class="col space">
          <div class="drak">下单时间：{{ this.query.time }}</div>
          <div class="drak">已付款</div>
        </div>
        <div class="col">
          <div class="drak">支付金额：</div>
          <div class="valu money">￥{{ this.query.price }}</div>
        </div>
        <div style="text-aline: center">
          <img class="bannerimg" :src="this.query.coverUrl" />
        </div>
      </div>
    </div>
    <div class="card info">
      <div class="title">
        <div class="titlebefore"></div>
        班主任信息
      </div>
      <div class="valu qrtitle">棋妙围棋 小付老师</div>
      <img class="qr" src="../assets/images/fuweuqr.png" />

      <div class="valu add">请务必添加班主任微信，以便享受后期的课程服务</div>
    </div>
    <div style="display: flex">
      <div
        class="btndown"
        v-clipboard:copy="wx"
        v-clipboard:success="handleCopy"
      >
        复制微信
      </div>
      <div class="btndown" @click="downapp">返回APP</div>
    </div>
  </div>
</template>

<script>
import { Icon, Cell, CellGroup, Toast, Button } from "vant";
export default {
  data() {
    return {
      wx: "YuanyuanR0815",
      query: {
        mobile: "156****6401",
        time: "2022-03-12 13:33:12",
        title: "体验课",
        price: "9.9",
        coverUrl:
          "https://upimgtest.pookcdn.com/jlxtest/gomastertest/picture/product/trail_1.png?key=" +
          (new Date().getTime() / (1000 * 60 * 60)).toFixed(),
      },
    };
  },

  created() {
    if (this.$route.query) {
      this.query = this.$route.query;
      if (this.$route.query && this.$route.query.productNo) {
        this.query.coverUrl =
          "https://upimgtest.pookcdn.com/jlxtest/gomastertest/picture/product/" +
          this.$route.query.productNo +
          ".png?key=" +
          (new Date().getTime() / (1000 * 60 * 60)).toFixed();
        this.$http
          .get("/products/" + this.$route.query.productNo)
          .then((res) => {
            if (res) {
              this.query = res;
              this.query.time = this.$route.query.time;
              this.query.mobile = this.$route.query.mobile;
            }
          });
      }
    }
  },
  methods: {
    downapp() {
      console.log(2222);
      window.open("uniwebview://action?key=value&anotherKey=anotherValue");
    },
    handleCopy(e) {
      Toast.success({
        message: "复制成功",
      });
    },
  },
};
</script>

<style>
@font-face {
  font-family: "PingFangSChei";
  src: url("../assets/fonts/苹方黑体-中黑-简.ttf");
}
@font-face {
  font-family: "PingFangSC-Regular, sans-serif";
  src: url("../assets/fonts/苹方黑体-准-简.ttf");
}
.qrtitle {
  margin-top: 9px;
}
.money {
  font-family: PingFangSChei;
  font-size: 16px;
  line-height: 1;
}
.add {
  /* margin-top: 16px; */

  font-family: PingFangSC-Medium, PingFang SC;
}
.valu {
  color: #3e4f66;
}
.info {
  text-align: center;
}
.space {
  justify-content: space-between;
}
.drak {
  color: #7c8ea6;
}
.col {
  padding: 5px 15px;
  display: flex;
}
.qr {
  width: 125px;
  height: 125px;
  /* padding-bottom: 15px; */
}
.btndown {
  width: 150px;
  height: 46px;
  background: #ffd609;
  border-radius: 22.5px;
  color: #333333;
  text-align: center;
  font-size: 15px;
  line-height: 46px;
  margin: 20px auto;
}
.card {
  font-size: 14px;
  width: 335px;
  margin: 10px auto;
  background: #ffffff;
  box-shadow: 0px 8px 24px 2px rgba(84, 102, 143, 0.05);
  border-radius: 16px;
  margin-top: 0px;
}
.bannerimg {
  width: 300px;
  height: 100px;
  margin: 10px auto 10px;
}
.title {
  padding-top: 20px;
  margin-left: 17px;
  display: flex;
  font-size: 16px;
  color: #3e4f66;
  line-height: 1;
  font-weight: 600;
}
.titlebefore {
  width: 11px;
  height: 16px;
  margin-right: 12px;
  background: url(../assets/images/sewq_img_shopping_tab@2x.png);
  background-size: 100% 100%;
}
.page {
  background: #f7f8fb;
  font-size: 14px;
  padding-top: 20px;
  min-height: 100vh;
}
</style>