<template>
  <div style="margin: 0 auto; font-size: 37.5px">
    <img class="conimg" src="@/assets/images/sewq_img_01@2x.png" />
    <img class="imgm" src="@/assets/images/dou/sewq_img_top_9.9@2x.png" />
    <img class="conimg topce" src="@/assets/images/sewq_img_02@2x.png" />
    <img class="conimg" src="@/assets/images/sewq_img_03@2x.png" />
    <img class="conimg" src="@/assets/images/sewq_img_04@2x.png" />
    <img
      class="conimg"
      style="margin-bottom: 60px"
      src="@/assets/images/sewq_img_05@2x.png"
    />
    <div class="conbot">
      <img
        class="img_money"
        src="@/assets/images/dou/sewq_img_bottom_9.9@2x.png"
      />
      <img class="btnbuy" src="@/assets/images/dou/sewq_btn_buy@2x.png" @click="pageto()"/>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    pageto(){
      this.$router.push({
        path:'/new-order'
      })
    }
  }
};
</script>

<style>

.conimg {
  display: block;
  margin: 0 auto;
  width: 100vw;
  max-width: 20rem;
}
.topce{
  margin-top: -88px;
}
.imgm {
  width: 126.5px;
  height: 88px;
  top: -230px;
  position: relative;
  margin-left: 15px;
  margin-top: -21px;
}
.img_money {
  width: 126.5px;
  height: 88px;
  margin-left: 15px;
  /* margin-top: -21px; */
}
.conbot {
  position: fixed;
  bottom: 0;
  display: block;
  width: 100vw;
  max-width: 20rem;
  /* height: 2rem; */
  background: url(../assets/images/sewq_img_bottom_bg@2x.png) no-repeat;
  background-size: 100% 100%;
}
.btnbuy {
  width: 221px;
  height: 73.5px;
  position: relative;
  bottom: -10px;
}
</style>