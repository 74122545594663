import http from 'axios'
import {Toast} from 'vant'
//判断是否是微信浏览器
export function isWxBrowser() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
}
//base64转文件
export function convertBase64UrlToImgFile(urlData, fileName) {
    let arr = urlData.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });

}
//获取地址栏参数
export function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}

export function removeQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    const newVars = vars.filter(item => {
        const pair = item.split("=");
        if (pair && pair.length) {
            return pair[0] !== variable
        }
        return false;
    });
    return newVars.join("&");
}




export const getBrowser = {
    versions: function () {

        var u = navigator.userAgent
        return {

            trident: u.indexOf('Trident') > -1,                        /*IE内核*/

            presto: u.indexOf('Presto') > -1,          /*opera内核*/

            webKit: u.indexOf('AppleWebKit') > -1, /*苹果、谷歌内核*/

            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,        /*火狐内核*/

            mobile: !!u.match(/AppleWebKit.*Mobile.*/),        /*是否为移动终端*/

            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), /*ios终端*/

            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, /*android终端或者uc浏览器*/

            iPhone: u.indexOf('iPhone') > -1,          /*是否为iPhone或者QQHD浏览器*/

            iPad: u.indexOf('iPad') > -1,      /*是否iPad*/

            webApp: u.indexOf('Safari') == -1,          /*是否web应该程序，没有头部与底部*/

            souyue: u.indexOf('souyue') > -1,

            superapp: u.indexOf('superapp') > -1,

            weixin: u.toLowerCase().indexOf('micromessenger') > -1,

            Safari: u.indexOf('Safari') > -1

        };

    }()
}
//微信浏览器内获取openid
export function handleInWxGetOpenId() {
    const redirectUrl = window.location.href;
    const appid = process.env.VUE_APP_WX_APPID
    const code = getQueryVariable("code");
    let openid =  sessionStorage.getItem("openid")
        ? JSON.parse(sessionStorage.getItem("openid"))
        : "";
    if (code && !openid) {
        http
            .post(process.env.VUE_APP_BASE_API+"/auth/wechat/openid", { code })
            .then((res) => {
                if (res.status===200) {
                    sessionStorage.setItem("openid", JSON.stringify(res.data.openid));
                }else{
                    Toast({
                        icon:"clear",
                        forbidClick:true,
                        message:res.statusText,
                        duration: 2000
                    })
                }

            })
            .catch((err) => {
                console.log('catch')
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
                    window.location.href.split("?")[0] + "?" + removeQueryVariable("code")
                )}&response_type=code&scope=snsapi_base#wechat_redirect`;
            });
    } else if (!code && !openid) {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
            redirectUrl
        )}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
    }
}
