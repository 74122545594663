var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dou"},[_c('div',{staticClass:"dou-item dou-item-1"},[_c('img',{attrs:{"src":require("@/assets/images/dou/sewq_banner@2x.png"),"alt":""}}),_c('div',{staticClass:"dou-item-1-box"},[_c('section',{staticClass:"dou-item-1-box-top"},[_c('img',{staticClass:"dou-item-1-box-top-gift",attrs:{"src":require("@/assets/images/dou/sewq_banner_gift_img@2x.png"),"alt":""}}),_c('img',{staticClass:"dou-item-1-box-top-desc",attrs:{"src":_vm.courses[_vm.courseType].desc,"alt":""}})]),_c('img',{staticClass:"dou-item-1-box-bottom",attrs:{"src":_vm.courses[_vm.courseType].card,"alt":""}})])]),_vm._m(0),_c('div',{staticClass:"dou-item dou-item-3"},[_c('img',{staticClass:"dou-item-title",attrs:{"src":require("@/assets/images/dou/sewq_teacher_title@2x.png"),"alt":""}}),_c('section',[_c('Swipe',{staticClass:"teacher",attrs:{"autoplay":"3000","loop":true,"show-indicators":true,"touchable":"","stop-propagation":"","indicator-color":"#FFB538","width":"300px"}},_vm._l((_vm.teachers),function(o,i){return _c('SwipeItem',{key:i,staticClass:"teacher-swipe"},[_c('img',{attrs:{"src":o,"alt":""}})])}),1)],1)]),_c('div',{staticClass:"dou-item dou-item-3 dou-item-4"},[_c('img',{staticClass:"dou-item-title",attrs:{"src":require("@/assets/images/dou/sewq_feature_title@2x.png"),"alt":""}}),_c('section',[_c('Swipe',{staticClass:"teacher",attrs:{"autoplay":"2000","loop":true,"show-indicators":true,"touchable":"","stop-propagation":"","indicator-color":"#FFB538","width":"300px"}},_vm._l((_vm.advantages),function(o,i){return _c('SwipeItem',{key:i,staticClass:"teacher-swipe"},[_c('img',{attrs:{"src":o,"alt":""}})])}),1)],1)]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"apply"},[_c('div',{staticClass:"apply-inner"},[_vm._m(5),_c('router-link',{attrs:{"to":"/m-dou-pay"}},[_c('img',{staticClass:"apply-inner-r",attrs:{"src":require("@/assets/images/dou/sewq_start_btn@2x.png")}})])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dou-item dou-item-2"},[_c('img',{staticClass:"dou-item-title",attrs:{"src":require("@/assets/images/dou/sewq_reason_title@2x.png"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/images/dou/sewq_reason_detile@2x.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dou-item dou-item-5"},[_c('br'),_c('img',{attrs:{"src":require("@/assets/images/dou/sewq_learningpage@2x.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dou-item dou-item-6"},[_c('img',{staticClass:"dou-item-title",attrs:{"src":require("@/assets/images/dou/sewq_learningdetile_title@2x.png"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/images/dou/sewq_learningdetile@2x.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dou-item dou-item-7"},[_c('img',{staticClass:"dou-item-title",attrs:{"src":require("@/assets/images/dou/sewq_problem_title@2x.png"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/images/dou/sewq_problem@2x.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyright"},[_vm._v(" 沪ICP备2021009246号-3 | 营业执照 ©2021"),_c('br'),_vm._v("上海玖连星教育科技有限公司 所有权利保留 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"apply-inner-l animated pulse infinite"},[_c('img',{attrs:{"src":require("@/assets/images/dou/¥@2x.png"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/images/dou/6@2x.png"),"alt":""}})])
}]

export { render, staticRenderFns }