<template>
  <div class="blocktitle wow fadeInup">
    <div class="short-line"></div>
    <div class="title-text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "blocktitle",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.blocktitle {
  width: 100%;
  padding: 36px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .short-line {
    width: 22px;
    height: 5px;
    opacity: 1;
    background: #ffcb38;
    border-radius: 3px;
    margin-bottom: 10px;
  }
  .title-text {
    text-align: center;
    font-size: 20px;
    font-family: PingFangTC, PingFangTC-Medium;
    font-weight: 500;
    text-align: center;
    color: #2fccc4;
    line-height: 28px;
  }
}
</style>
