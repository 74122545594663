<template>
    <div class="navigation">
        <ul>
            <li v-for="time in listData" :key="time.index">
                <div class="listBox" @click="gotopage(time.to)">
                    <div class="left">
                        <div class="leftBox">
                            <img :src="require(`@/${time.url}`)" alt="图片">
                        </div>
                        <div class="middleBox">
                            <p>{{ time.title }}</p>
                        </div>
                    </div>
                    <div class="rigthBox">
                        <img src="../assets/images/agent/rightgo.png" alt="片">
                    </div>
                </div>
            </li>
        </ul>
    </div>


</template>

<script>

export default {

    data() {
        return {
            user_id:'',
            listData: [
                {
                    title: '提现操作',
                    to: 'Withdrawal',
                    // url: 'assets/images/agent/tixian.png',
                    url: 'assets/images/agent/tixianB.png',
                    index: 1,
                }, {
                    title: '邀请用户',
                    to: 'invite',
                    // url: 'assets/images/agent/yaoqing.png',
                    url: 'assets/images/agent/yaoqingB.png',
                    index: 2,
                }, 
                {
                    title: '用户管理',
                    to: 'user',
                    // url: 'assets/images/agent/yonghuguali.png',
                    url: 'assets/images/agent/yonghugualiB.png',
                    index: 3,
                }, 
                {
                    title: '订单记录',
                    to: 'order',
                    // url: 'assets/images/agent/dingdan.png',
                    url: 'assets/images/agent/dingdanB.png',
                    index: 4,
                },
                {
                    title: '数据中心',
                    to: 'salesCenter',
                    // url: 'assets/images/agent/fenxiao.png',
                    url: 'assets/images/agent/fenxiaoB.png',
                    index: 5,
                }
            ]
        };
    },
    mounted() {
        document.dispatchEvent(new Event('render-event'));
    },

    methods: {
        goto() {
            console.log('退出登录');
        },
        gotopage(page) {
            if(page == 'salesCenter'){
                this.user_id = JSON.parse(sessionStorage.getItem('userinfo')).user_id;
                 window.location.href = `https://wanwan.go-master.com/agent/login?to=/agent/index?type=1&user_id=${this.user_id}`;
            }else{
                this.$router.push('/m-course/' + page);
            }
        }

    }
};
</script>

<style scoped lang="less">
* {
    margin: 0;
    padding: 0;
}

body {
    background-color: #f3f3f3;
}

.container {
    height: 100vh;
    background-color: #f3f3f3;
    padding-bottom: 10px;
    // box-sizing: border-box;
}

.personal-info {
    padding: 10px 10px 10px 10px;
    height: 120px;
    background-color: #e22d2d;
    display: flex;
    color: #fff;
    border-radius: 10px;
    margin-bottom: 30px;
    margin: 0px 10px 30px 10px;

    .p {
        margin-bottom: 10px;
    }

    .imgBox {
        width: 60px;
        height: 60px;
        background-color: #f8f8f8;
        border-radius: 50%;
        margin: 10px 10px 0 10px;

    }

    .rightBox {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        width: 70%;

        .helloBox {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 30px;


            .hello {
                font-size: 18px;
                font-weight: 600;

            }

            .buttonBox {
                font-size: 18px;
                margin-left: 10px;
                font-weight: 600;
                color: darkorange;
            }
        }

        .infoBox {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-top: 15px;
            width: 100%;
            font-size: 15px;
            color: #d4d4d4;

            .infoleftBox {
                width: 50%;
                text-align: left;
            }

            .inforightBox {
                width: 50%;
                text-align: left;
            }
        }
    }

}


.span {
    color: rgb(253, 200, 135);
}

.navigation {
    height: 70vh;
    li {
        list-style: none;
        margin: 0 10px;

        .listBox {
            box-sizing: border-box;
            padding: 0 20px 0 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 50px;
            background-color: #ffffff;
            margin-top: 10px;
            font-size: 17px;
            // font-weight: 600;
            color: #555;
            border-radius: 10px;

            // box-shadow: 0 0 10px rgb(255, 255, 255);
            .left {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .leftBox {
                padding-right: 5px;
                margin-right: 5px;
                display: flex;
                align-items: center;

                img {
                    width: 28px;
                    height: 28px;
                    // filter: grayscale(0%) brightness(100%) sepia(100%) saturate(10000%) hue-rotate(0deg);
                }
            }

            .middleBox {}

            .rigthBox {
                display: flex;
                align-items: center;

                img {
                    width: 25px;
                    height: 25px;
                    text-align: center;
                    // filter: grayscale(0%) brightness(100%) sepia(100%) saturate(10000%) hue-rotate(0deg);
                }
            }
        }
    }
}

.margintopBox {
    height: 20px;
}
</style>