<template>
  <div class="err">
    <Empty :image="nopage" description="别走丢了，去首页逛逛吧~"  />
    <Button type="primary"  color="#2fccc4" size="small" class="go-home" to="/">
      回首页
    </Button>
  </div>
</template>

<script>
import {Empty,Button} from "vant"
import nopage from '@/assets/images/404.png'
export default {
  components:{
    Empty,Button
  },
  name: 'page404',
  data() {
    return {
      nopage
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.err{
  width: 100%;
  height: 100vh;
  .van-empty{
    width: 100%;
  }
  .go-home{
    display: block;
    margin: 0 auto;
    a{
      display: block;
      width: 100%;
      height: 100%;
      color: #ffffff;
    }
  }
}
</style>
