<template>
  <div class="pay" v-if="!showDetail">
    <div class="pay-body">
      <div class="pay-body-card pay-body-course">
        <div class="pay-body-card-title">Go Master 官方平台</div>
        <div class="pay-body-course-item">
          <div class="pay-body-course-item-l">
            <img :src="currentCourse.coverUrl" alt="" />
          </div>
          <div class="pay-body-course-item-r">
            <span>{{ currentCourse.title }}：Go Master少儿双语围棋课程</span>
            <div>
              <b>¥{{ currentCourse.price }}</b> <i>×1</i>
            </div>
          </div>
        </div>
      </div>
      <div class="pay-body-card pay-body-contact">
        <div class="pay-body-contact-list">
          <CellGroup>
            <Field
              v-model="form.contact"
              required
              clearable
              label="学员姓名"
              placeholder="请输入学员姓名"
            />
            <Field
              v-model="form.mobile"
              clearable
              required
              label="联系方式"
              class="mobile-field"
            >
              <template slot="input">
                <div class="mobile-input">
                  <div class="input-select" @click="showTelList = true">
                    <span>+{{ form.code }}</span>
                    <img src="../assets/images/srwq_btn@2x.png" alt="" />
                  </div>
                  <Field
                    v-model="form.mobile"
                    type="tel"
                    placeholder="请输入联系方式"
                  />
                </div>
              </template>
            </Field>
            <Field
              v-model="form.validCode"
              center
              clearable
              type="digit"
              maxlength="4"
              required
              label="短信验证码"
              placeholder="请输入短信验证码"
            >
              <template #button>
                <Button
                  size="mini"
                  v-show="canSend"
                  type="primary"
                  class="get-code"
                  @click="handleGetCode"
                  >获取验证码</Button
                >
                <CountDown
                  v-show="!canSend"
                  ref="countDown"
                  :auto-start="false"
                  :time="time"
                  format="ss s"
                  @finish="handleResetCountDown"
                ></CountDown>
              </template>
            </Field>
          </CellGroup>
        </div>
      </div>
      <div class="pay-body-card pay-body-detail">
        <div class="pay-body-detail-content">
          <Cell
            title="商品合计"
            value-class="cell-price"
            :value="'¥' + currentCourse.price"
          />
          <Cell title="运费" value="¥0" />
          <Cell title="优惠" value="¥0" />
          <Field
            v-model="form.remark"
            clearable
            label="留言"
            maxlength="100"
            input-align="right"
            rows="3"
            autosize
            show-word-limit
            type="textarea"
            placeholder="建议留言前先与商家沟通确认"
          />
        </div>
      </div>
    </div>
    <SubmitBar
      :decimal-length="2"
      :safe-area-inset-bottom="true"
      button-text="确认下单"
      :price="currentCourse.price * 100"
    >
      <template #button>
        <Button
          class="submit"
          type="info"
          :loading="isSubmit"
          loading-text="下单中..."
          @click="handleSubmit"
        >
          <img
            class="pay-loading"
            slot="loading"
            src="../assets/images/loading.svg"
            alt=""
          />
          确认下单
        </Button>
      </template>
    </SubmitBar>
    <Popup
      class="tellist"
      v-model="showTelList"
      position="bottom"
      round
      lock-scroll
      closeable
      :safe-area-inset-bottom="true"
      :close-on-click-overlay="false"
      :close-icon="require('../assets/images/srwq_pop_deleted_icon@2x.png')"
    >
      <div class="pop-title">选择手机号所属国家/地区</div>
      <Picker :columns="codes" :default-index="0" @change="onChange"></Picker>
      <div class="picker-btn" @click="handleSelectChange">确定</div>
    </Popup>
  </div>
  <PayDetail v-else :orderDetail="orderDetail"></PayDetail>
</template>

<script>
import {
  SubmitBar,
  Button,
  Toast,
  CellGroup,
  Field,
  Popup,
  Picker,
  Cell,
  Dialog,
  CountDown,
} from "vant";
import { code } from "../assets/js/code";
import wx from "../assets/images/wx.svg";
import PayDetail from "@/components/pay-detail";
export default {
  name: "Pay",
  components: {
    SubmitBar,
    Button,
    CellGroup,
    Field,
    Popup,
    Picker,
    Cell,
    CountDown,
    PayDetail,
  },
  data() {
    const openid = JSON.parse(sessionStorage.getItem("openid"));
    return {
      orderDetail: {},
      showDetail: false,
      canSend: true,
      time: 60 * 1000,
      isSubmit: false, //支付loading状态
      isConfirm: true, //确认支付
      wx,
      activePay: 0,
      isWx: false,
      form: {
        appId: process.env.VUE_APP_WX_APPID,
        openid,
        contact: "",
        mobile: "",
        code: code[0].code,
        remark: "",
        validCode: "",
      },
      showTelList: false,
      activeIndex: 0,
      orderNo: "",
      courseArr: [],
    };
  },
  computed: {
    codes() {
      const codes = code;
      return codes.map((v) => {
        return `+${v.code} ${v.name}`;
      });
    },
    currentCourse() {
      const courseArr = this.courseArr;
      console.log(courseArr,this.$route.query);
      return (
        courseArr.filter((e) => {
          return e.id == this.$route.query.id;
        })[0] || {}
      );
    },
  },
  created() {
    this.handleGetAllCourses();
  },
  methods: {
    handleGetAllCourses() {
      this.$http.get("/products").then((res) => {
        let list = [];
        if (res && res.length) {
          list = [...res];
        }
        this.courseArr = [...list];
      });
    },
    handleResetCountDown() {
      this.canSend = true;
      this.$refs.countDown.reset();
    },
    handleCheck() {
      if (!this.form.mobile) {
        Toast({
          message: "请输入手机号",
        });
        return false;
      } else if (isNaN(Number(this.form.mobile))) {
        Toast({
          message: "手机号格式不正确",
        });
        return false;
      }
      return true;
    },
    handleGetCode() {
      if (this.handleCheck()) {
        this.canSend = false;
        const { code, mobile } = this.form;
        this.$http
          .post("/sms/send", {
            code,
            mobile,
          })
          .then((res) => {
            this.$refs.countDown.start();
          })
          .catch((value) => {
            this.canSend = true;
          });
      }
    },
    handleConfirmOrder(res) {
      const _this=this
      Dialog.confirm({
        overlayStyle: { opacity: 0.4 },
        title: "您有一个待支付订单，是否重新下单？",
        showCancelButton: true,
        cancelButtonText: "继续支付",
        confirmButtonText: "重新下单",
        lockScroll: true,
        confirmButtonColor: "#3276FF",
        cancelButtonColor: "#FA326E",
        beforeClose: (action, done) => {
          if (action === "confirm") {
            _this.handleResubmit(done)
          } else {
            setTimeout(() => {
              _this.isSubmit = false;
              _this.$router.push({path:'/m-pay',query:{userId:res.userId,orderNo:res.orderNo,courseId:this.$route.query.id}})
              done();
            }, 500);
          }
          
        },
      });
    },
    handleResubmit(done) {
      this.$http
        .post("/orders/again", {
          ...this.form,
          productNo: this.currentCourse.productNo,
          channelId: localStorage.getItem("channelId")||"5964823168",
        })
        .then((res) => {
          this.isSubmit = false;
          setTimeout(() => {
              done()
              this.$router.push({path:'/m-pay',query:{userId:res.userId,orderNo:res.orderNo,courseId:this.$route.query.id}})
            }, 500);
        })
        .catch((err) => {
          console.log(err);
          this.isSubmit = false;

          done()
        });  
    },
    onChange(picker, value, index) {
      this.activeIndex = index;
    },
    handleSelectChange() {
      const current = code[this.activeIndex];
      this.form = {
        ...this.form,
        code: current.code,
      };
      this.showTelList = false;
    },
    handleCheckParams() {
      const { contact, validCode } = this.form;
      if (!contact) {
        Toast({
          message: "请输入学员姓名",
        });
        return false;
      } else if (!validCode) {
        Toast({
          message: "请输入验证码",
        });
        return false;
      }
      return true;
    },
    handleSubmit() {
      if (this.handleCheckParams() && this.handleCheck()) {
        this.handleGetPayLink();
      }
    },
    handleWxJsBridge(data) {
      //微信浏览器内拉起微信支付 JSAPI
      const params = {
        ...data.unifiedOrder.credential,
      };
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        { ...params },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
        }
      );
    },
    handleGetPayLink() {
      this.isSubmit = true;
      const loading = Toast.loading({
        message: "下单中...",
        forbidClick: true,
        duration: 0,
      });
      this.$http
        .post("/orders/confirm", {
          ...this.form,
          productNo: this.currentCourse.productNo,
          channelId: localStorage.getItem("channelId") || "5964823168",
        })
        .then((res) => {
          if (res.exist) {
            loading.clear();
            this.handleConfirmOrder(res);
          } else {
            setTimeout(() => {
              loading.clear();
              this.$router.push({path:'/m-pay',query:{userId:res.userId,orderNo:res.orderNo,courseId:this.$route.query.id}})
            }, 500);
          }
        })
        .catch((err) => {
          this.isSubmit = false;
          loading.clear();
        });
    }
  },
};
</script>
<style scoped lang="less">
::v-deep.pay {
  width: 100%;
  min-height: calc(~"100vh - 106px");
  padding: 0 12px;
  background: #f3f4f7;
  font-size: 16px;
  position: relative;
  .van-count-down {
    color: #b4b4b4;
  }
  .van-overlay {
    z-index: 9999 !important;
  }
  .van-popup {
    z-index: 9999 !important;
    padding-bottom: 50px;
    .van-icon__image {
      width: 36px;
      height: 36px;
      transform: translateY(-4px);
    }
    .pop-title {
      height: 56px;
      font-size: 22px;
      font-family: PingFangTC, PingFangTC-Medium;
      font-weight: 500;
      text-align: center;
      color: #2fcdc6;
      line-height: 65px;
      margin-bottom: 10px;
    }
    .van-picker {
      margin-bottom: 10px;
      .van-picker-column__item {
        .van-ellipsis {
          opacity: 1;
          font-size: 14px;
          font-family: PingFangTC, PingFangTC-Regular;
          font-weight: 400;
          text-align: left;
          color: #98a4bd;
        }
      }
      .van-picker-column__item--selected {
        .van-ellipsis {
          font-size: 17px;
          font-family: PingFangTC, PingFangTC-Medium;
          font-weight: 500;
          text-align: left;
          color: #2fcdc6;
        }
      }
    }
    .picker-btn {
      width: 324px;
      height: 45px;
      margin: 0 auto;
      line-height: 48px;
      text-align: center;
      opacity: 1;
      background: #ffb243;
      border-radius: 23px;
      font-size: 20px;
      font-family: PingFangTC, PingFangTC-Medium;
      font-weight: 500;
      color: #ffffff;
    }
    &.success-pop {
      background: transparent;
      overflow: visible;
      .van-popup__close-icon--top-right {
        top: 100%;
        left: 50%;
        width: 36px;
        height: 36px;
        transform: translate(-50%, -20px);
      }
      .success {
        position: relative;
        img,
        i,
        b,
        span {
          display: block;
        }
        .success-box {
          width: 270px;
          height: 262px;
          background: #ffffff;
          border-radius: 29px;
          background-image: url("../assets/images/srwq_pop_bg@2x.png");
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: 100% 100%;
          position: relative;
          z-index: 10;
          .gift-box {
            width: 164px;
            height: 90px;
            position: absolute;
            top: -70px;
            left: 50%;
            transform: translateX(-50%);
          }
          .monkey {
            width: 80px;
            position: absolute;
            right: -55px;
            top: 20px;
          }
          .star {
            width: 15px;
            position: absolute;
            right: 30px;
            top: 15px;
          }
          p {
            font-size: 16px;
            font-family: PingFangTC, PingFangTC-Medium;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
            line-height: 20px;
            padding-top: 20px;
            margin-bottom: 23px;
          }
          i {
            font-style: normal;
            font-size: 14px;
            font-family: PingFangTC, PingFangTC-Regular;
            font-weight: 400;
            text-align: center;
            color: #2d2d2d;
          }
          .wx {
            width: 98px;
            margin: 0 auto;
          }
          b {
            font-size: 10px;
            font-family: PingFangSC-Regular, sans-serif;
            font-weight: 400;
            text-align: center;
            color: #514f6b;
            margin-bottom: 10px;
          }
          div {
            font-size: 10px;
            font-family: PingFangSC-Regular, sans-serif;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 15px;
            padding: 0 20px;
          }
        }
        .panda {
          width: 193px;
          height: 221px;
          position: absolute;
          left: -44px;
          top: -96px;
          z-index: 5;
        }
      }
    }
  }
  .van-submit-bar__bar {
    padding: 0;
    padding-left: 12px;
    & > span {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #00000d;
    }
    .van-submit-bar__text {
      & > span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4f4f4f;
      }
      .van-submit-bar__price {
        font-size: 18px;
        font-weight: 600;
        color: #fa326e;
      }
    }
  }
  &-title {
    text-align: center;
    font-size: 19px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    padding: 25px 0;
  }
  &-back {
    display: block;
    width: 20px;
    position: absolute;
    left: 22px;
    top: 22px;
  }
  &-body {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    padding-top: 20px;
    &-card {
      background: #ffffff;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
      &:not(:nth-last-of-type(1)) {
        margin-bottom: 12px;
      }
      &-title {
        width: 100%;
        height: 51px;
        line-height: 51px;
        text-align: left;
        padding: 0 20px;
        border-bottom: 1px solid #eeeeee;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
    }
    &-course {
      &-item {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 14px 20px;
        &:not(:nth-last-of-type(1)) {
          border-bottom: 1px solid #eee;
        }
        &-l {
          width: 124px;
          height: 64px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 13px;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        &-r {
          flex: 1;
          width: 0;
          span {
            display: block;
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #00000d;
            line-height: 21px;
            margin-bottom: 10px;
            white-space: pre-wrap;
          }
          div {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            i {
              display: block;
              font-style: normal;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #80858a;
            }
            b {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: normal;
              color: #00000d;
            }
          }
        }
      }
    }
    &-contact {
      &-list {
        .get-code {
          background-color: #2fccc4;
          border-color: #2fccc4;
        }
        .mobile-field {
          .mobile-input {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            .input-select {
              background: #eeeeee;
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: flex-start;
              padding: 0 8px;
              padding-right: 0;
              margin-right: 5px;
              border-radius: 2px;
              span {
                font-size: 14px;
                color: #9aa4bc;
              }
              img {
                width: 15px;
              }
            }
            .van-field {
              padding: 0;
            }
          }
        }
      }
    }
    &-payType {
      width: 100%;
      .right-icon {
        width: 20px;
      }
      &-list {
        width: 100%;

        &-item {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 17px 28px 17px 21px;
          &-type {
            display: flex;
            flex-wrap: nowrap;
            flex: 1;
            width: 0;
            align-items: center;
            justify-content: flex-start;
            img {
              display: block;
              width: 20px;
              margin-right: 12px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #191919;
            }
          }
          .check-item {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            overflow: hidden;
          }
          .noChecked {
            border: 1px solid #abadb1;
          }
          .isChecked {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
              display: block;
            }
          }
        }
      }
    }
    &-detail {
      &-content {
        width: 100%;
        .cell-price {
          color: #fe4417;
        }
        &-item {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 9px;

          &-label {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #00000d;
            line-height: 19px;
          }
          &-value {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #80858a;
            line-height: 19px;
          }
        }
      }
    }
  }
  @keyframes rotate {
    from {
      transform: rotateZ(-180deg);
    }
    to {
      transform: rotateZ(180deg);
    }
  }
  &-loading {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 6px;
    animation: rotate 1s linear infinite;
  }
  .submit {
    width: 130px;
    height: 50px;
    background: #2fccc4;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    border-radius: 0;
    border-color: #2fccc4;
  }
  .van-dialog {
    .van-button__text {
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
}
</style>
