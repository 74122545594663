<template>
  <div class="pay-detail animated fadeInUp">
    <Icon name="checked" color="#2fccc4" />
    <span>购买成功</span>
    <div class="card">
      <div class="card-title">订单信息</div>
      <div class="card-body">
        <p>商户名称：{{ detail.merchants }}</p>
        <p>商品名称：Go Master少儿围棋课程-{{ detail.productName }}</p>
        <p>
          <span>订单编号：{{ detail.orderNo }}</span
          ><img
            src="@/assets/images/copy.svg"
            class="copy"
            alt=""
            v-clipboard:copy="detail.orderNo"
            v-clipboard:success="handleCopy"
          />
        </p>
        <p>交易时间：{{ detail.paymentTime }}</p>
      </div>
    </div>
    <a
      v-if="isWxBrowser"
      href="http://mp.weixin.qq.com/mp/getmasssendmsg?__biz=MzkyMDIyMzIxMg==#wechat_redirect"
      >联系我们 <Icon name="wechat"></Icon
    ></a>
    <div class="qr-box" v-else>
      <span>请务必添加班主任微信，以便享受后期的课程服务</span>
      <div class="wx" v-clipboard:copy="wx" v-clipboard:success="handleCopy">
        <span>点击按钮复制微信号，添加班主任</span>
      </div>
      <span>扫一扫</span>
      <span>关注公众号</span>
      <img :src="qrcode" alt="" />
      <a href="uniwebview://action?key=value&anotherKey=anotherValue"></a>
    </div>
  </div>
</template>

<script>
import { Icon, Cell, CellGroup, Toast, Button } from "vant";
import { isWxBrowser, getQueryVariable } from "@/assets/js/tool";
export default {
  name: "PayDetail",
  components: { Icon, Cell, CellGroup, Button },
  props: {
    orderNo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isWxBrowser: isWxBrowser(),
      wx: "YuanyuanR0815",
      qrcode: "",
      detail: {},
    };
  },
  created() {
    this.$http.get(`/orders/${this.orderNo}/complete`).then((res) => {
      this.detail = { ...res };
    });
  },
  mounted() {
    this.$http
      .get(
        "/website/qrcode/" +
          (localStorage.getItem("channelId") ||
            getQueryVariable("channelId") ||
            "5964823168")
      )
      .then((res) => {
        this.qrcode = "https://upimgtest.pookcdn.com/" + res;
      });
    const a = document.createElement("a");
    a.href = "uniwebview://action?key=value&anotherKey=anotherValue";
    a.click();
  },
  methods: {
    handleCopy(e) {
      Toast.success({
        message: "复制成功",
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.pay-detail {
  width: 100%;
  min-height: 100vh;
  background: #f5f5f5;
  padding: 30px 20px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .van-button {
    margin-left: 10px;
  }
  .copy {
    display: block;
    width: 20px !important;
    margin-left: 5px;
  }
  & > .van-icon {
    font-size: 56px;
    margin-bottom: 15px;
    & + span {
      display: block;
      margin-bottom: 20px;
    }
  }
  .card {
    width: 100%;
    padding: 20px 15px;
    background: #ffffff;
    border-radius: 6px;
    margin-bottom: 20px;
    &-title {
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    &-body {
      p {
        font-size: 16px;
        color: #444444;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        span {
          white-space: pre-wrap;
          word-break: break-all;
        }
      }
    }
    & + a {
      padding: 10px 20px;
      font-size: 16px;
      color: #ffffff;
      background: #2fccc4;
      display: flex;
      align-items: center;
      .van-icon {
        margin-left: 5px;
      }
    }
  }
  b {
    font-weight: normal;
    padding: 3px 5px;
    background: #2fccc4;
    color: #ffffff;
    font-size: 12px;
    margin-left: 10px;
  }
  .qr-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    img {
      display: block;
      width: 40vw;
      margin-bottom: 10px;
    }
    span {
      font-size: 14px;
    }
    .or {
      margin: 6px;
      display: block;
    }
    .wx {
      font-size: 20px;
      display: flex;
      align-items: center;
      border: #38d1c9;
      background: #38d1c9;
      border-radius: 30px;
      line-height: 20px;
      text-align: center;
      color: #eeeeee;
      margin-top: 20px;
      margin-bottom: 10px;
      padding: 15px 20px;
    }
  }
}
</style>
