<template>
  <div class="dou">
    <img src="@/assets/images/dou/dou01.jpg" alt="">
    <img src="@/assets/images/dou/dou02.jpg" alt="">
    <img src="@/assets/images/dou/dou03.jpg" alt="">
    <img src="@/assets/images/dou/dou04.jpg" alt="">
        <div class="apply">
      <div class="apply-inner">
        <div class="apply-inner-l animated pulse infinite">
          <img src="@/assets/images/dou/¥@2x.png" alt="" />
          <img src="@/assets/images/dou/6@2x.png" alt="" />
        </div>
        <router-link to="/m-dou-pay"
          ><img
            src="@/assets/images/dou/sewq_start_btn@2x.png"
            class="apply-inner-r"
        /></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "Dou2",
  data() {
    return {
    }
  },
  mounted() {
    
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .dou{
    padding-bottom: 72px;
    img{
      display: block;
      width: 100%;
    }
    .apply {
    width: 100%;
    height: 72px;
    background: #ffd609;
    padding-left: 17px;
    position: fixed;
    bottom: 0;
    &-inner {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;
      justify-content: flex-start;
      transform: translateY(-10px);
      &-l {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
        justify-content: flex-start;
        img {
          &:nth-of-type(1) {
            width: 18px;
          }
          &:nth-of-type(2) {
            width: 90px;
          }
        }
      }
      &-r {
        display: block;
        height: 50px;
        width: auto;
        margin-left: 10px;
        transform: translateY(12px);
      }
    }
  }
  }
</style>
