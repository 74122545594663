<template>
  <div class="home-item-5">
    <div class="home-item-5-title">Go Master承诺</div>
    <ul>
      <li v-for="(o, i) in list" :key="i">
        <img :src="o.icon" alt="" />
        <b>{{ o.title }}</b>
        <p>{{ o.text }}</p>
      </li>
    </ul>
    <div class="home-item-5-bar fix-bar" v-show="showClose">
      <img
        src="../assets/images/srwq_deleted_icon@2x.png"
        alt=""
        @click="handleCloseClose"
      />
      <img src="../assets/images/srwq_middle_freeclass_img@2x.png" alt="" />
      <p>登记手机号，送3、4节体验课</p>
      <span @click="handleGoTop">立即领取</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "home-item-5",

  data() {
    return {
      showClose: true,
      list: [
        {
          icon: require("../assets/images/srwq_header_promise_icon@2x.png"),
          title: "教学承诺",
          text: "专业围棋教练，精准教学，智能在线辅导",
        },
        {
          icon: require("../assets/images/srwq_header_audition_icon@2x.png"),
          title: "试听承诺",
          text: "0元领取两节体验课，体验满意再报名",
        },
        {
          icon: require("../assets/images/srwq_header_refound_icon@2x.png"),
          title: "退款承诺",
          text: "课程有效期内支持退款",
        },
      ],
      timer:null
    };
  },
  mounted() {
    window.addEventListener("touchstart", this.handleClearInterval);
    window.addEventListener("touchmove", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("touchstart", this.handleClearInterval);
    window.removeEventListener("touchmove", this.handleScroll);
  },
  methods: {
	
    handleClearInterval() {
      clearInterval(this.timer);
      this.timer = null;
    },
    handleCloseClose() {
      this.showClose=false
      window.removeEventListener("touchstart", this.handleClearInterval);
      window.removeEventListener("touchmove", this.handleScroll);
      this.handleClearInterval();
    },
    handleGoTop() {
		console.log("触发领取");
		this.$emit('openPhone', true);
      // document.querySelector("#app").scrollTop = 200;
      // this.$parent.$parent.handleEmitScroll();
    },
    handleScroll() {
      let top;
      if (!this.timer) {
        this.timer = setInterval(() => {
          top = document.querySelector("#app").scrollTop;
          if (top>=600&&top<=2070) {
            this.showClose=true
            document.querySelector(".home-item-5-bar").className="home-item-5-bar fix-bar"
          } else if(document.querySelector(".home-item-5-bar")){
            document.querySelector(".home-item-5-bar").className="home-item-5-bar"
          }
        }, 100);
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
::v-deep.home-item-5 {
  width: 100%;
  height: 326px;
  padding-bottom: 20px;
  background-image: url("../assets/images/srwq_promis_bg@2x.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  padding-top: 22px;
  position: relative;
  &-title {
    font-size: 20px;
    font-family: PingFangTC-Medium;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }
  ul {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 26px 29px 0 29px;
    li {
      flex: 1;
      width: 0;
      &:not(:last-of-type) {
        margin-right: 30px;
      }
      img {
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
        width: 44px;
      }
      b {
        display: block;
        font-size: 14px;
        font-family: PingFangTC, PingFangTC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        margin-bottom: 6px;
      }
      p {
        font-size: 10px;
        font-family: PingFangTC, PingFangTC-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        line-height: 1.4;
      }
    }
  }
  &-bar {
    width: 100%;
    height: 56px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #ffeab0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    z-index: 10;
    img {
      display: block;
      &:nth-of-type(1) {
        width: 45px;
        // transform: translateY(-6px);
      }
      &:nth-of-type(2) {
        width: 60px;
      }
    }
    p {
      font-size: 15px;
      font-family: PingFangTC, PingFangTC-Semibold;
      font-weight: 600;
      text-align: center;
      color: #ffa22e;
      white-space: nowrap;
      transform: translateX(-6px);
    }
    span {
      display: block;
      width: 79px;
      height: 28px;
      line-height: 30px;
      opacity: 1;
      background: #ffcb38;
      border-radius: 16px;
      font-size: 14px;
      font-family: PingFangTC, PingFangTC-Semibold;
      text-align: center;
      color: #ffffff;
      margin-left: 10px;
    }
  }
  .fix-bar{
    position: absolute;
    bottom: 0;
    left: 0;
  }
}



</style>
