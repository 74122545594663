<template>
  <div class="home-item-4">
    <BlockTitle>教学质量有保障<br />备受家长称赞</BlockTitle>
    <Swipe
      class="parent-swipe"
      :loop="false"
      :show-indicators="false"
      touchable
      stop-propagation
      indicator-color="#FFB538"
      :width="265"
    >
      <SwipeItem v-for="(o, i) in parents" :key="i" class="parent-swipe-item">
        <div class="parent-swipe-item-card">
          {{ o.intro }}
        </div>
        <div class="parent-swipe-item-info">
          <img :src="o.avatar" alt="" />
          <div>
            <b>{{ o.country }}</b>
            <span>{{ o.name }}</span>
          </div>
        </div>
      </SwipeItem>
      <SwipeItem></SwipeItem>
    </Swipe>
  </div>
</template>

<script>
import BlockTitle from "./blocktitle";
import { Swipe, SwipeItem } from "vant";
export default {
  name: "home-item-4",
  components: {
    BlockTitle,
    Swipe,
    SwipeItem,
  },
  data() {
    return {
      parents: [
        {
          name: "Chiyuan",
          avatar: require("../assets/images/srwq_middle_parent_img@2x.png"),
          country: "英国家长",
          intro:
            "Go Master对不同的小朋友们因材施教，课程体系设置的非常科学！我家宝贝在不同的阶段都能学有所成，真是太棒了！",
        },
        {
          intro:
            "这里的围棋课堂是沉浸式教学，互动非常丰富！还好没错过孩子的第一堂围棋课，使小孩子能轻松投入其中，我家小孩对这个课程真是爱到不行！",
          avatar: require("../assets/images/srwq_middle_parent_img2@2x.png"),
          country: "美国家长",
          name: "Teresa Yu",
        },
        {
          intro:
            "非常棒的双语教学！学习围棋的同时还学会了很多中文！非常感谢Go Master平台，感谢秦老师的围棋课！",
          avatar: require("../assets/images/srwq_middle_parent_img3@2x.png"),
          country: "加拿大家长",
          name: "Joy Zhao",
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
::v-deep.home-item-4 {
  width: 100%;
  background: #38d1c9;
  padding-bottom: 20px;
  .blocktitle {
    .title-text {
      color: #ffffff !important;
    }
  }
  .parent-swipe {
    height: 260px;
    padding: 0 14px;
    overflow: visible;
    padding-left: 28px;
    &-item {
      width: 265px !important;
      margin-right: 15px;

      &-card {
        width: 265px;
        height: 161px;
        opacity: 1;
        background: #ffffff;
        border-radius: 13px;
        box-shadow: 0px 4px 8px -2px #00c1b7;
        background-image: url("../assets/images/srwq_parents_icon_img@2x.png");
        background-repeat: no-repeat;
        background-position: 18px 13px;
        background-size: 28px auto;
        font-size: 13px;
        font-family: PingFangSC-Regular, sans-serif;
        font-weight: 400;
        text-align: center;
        color: #2e2e2e;
        line-height: 22px;
        padding: 40px 35px 0 35px;
        margin-bottom: 10px;
      }
      &-info {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        img {
          display: block;
          width: 61px;
          margin-right: 10px;
        }
        div {
          b {
            display: block;
            font-size: 14px;
            font-family: PingFangTC, PingFangTC-Medium;
            font-weight: 500;
            text-align: left;
            color: #ffffff;
          }
          span {
            display: block;
            font-size: 12px;
            font-family: STHeitiTC, STHeitiTC-Medium;
            font-weight: 500;
            text-align: left;
            color: #ffffff;
            opacity: 0.6;
          }
        }
      }
    }
    // .van-swipe-item {
    //   &:last-of-type {
    //     width: 0 !important;
    //   }
    // }
    .van-swipe__indicators {
      bottom: 0;
      .van-swipe__indicator {
        width: 9px;
        height: 9px;
        background-color: #22bbb2;
        opacity: 1;
        // &:last-of-type {
        //   display: none;
        // }
      }
    }
  }
}
</style>
