<template>
  <div class="pay" v-if="!showDetail">
    <div class="pay-body">
      <div class="pay-body-card pay-body-course">
        <div class="pay-body-card-title" style="color: #3E4F66">{{queryi.productTitle}}</div>
        <div class="pay-body-course-item" style="padding-top: 0">
          <div class="pay-body-course-item-r">
            <span style="font-size: 14px;color: #3E4F66;
font-weight: 400;">{{queryi.productDesc}}</span>
<!--            <span>{{ currentCourse.title }}GO Master 48节围棋系统课</span>-->
            <div>
              <b style="color: #FF7071;
              font-weight: 600;
              line-height: 44px;
              font-size: 18px;">¥{{queryi.price}}</b>
<!--              <b>¥{{ currentCourse.price }}</b>-->
            </div>
            <div style="font-size: 14px;color:#7C8EA6;font-weight: 400">购买账号：{{queryi.phoneNum}}</div>
          </div>
        </div>
      </div>
<!--      <div class="pay-body-card pay-body-course">-->
<!--        <Cell title="订单号">-->
<!--          <template #right-icon>-->
<!--            <span>ABCDEF1234</span>-->
<!--&lt;!&ndash;            <img :src="wx" alt="" class="right-icon" />&ndash;&gt;-->
<!--          </template>-->
<!--        </Cell>-->
<!--      </div>-->
      <div class="pay-body-card pay-body-course">
        <div class="pay-body-contact-list">
          <CellGroup>
            <div class="pay-body-card pay-body-payType">
              <Cell title="微信支付" :icon="wx"  @click="paytyp0 =!paytyp0">
                <template #right-icon>
<!--                  <span>微信支付&nbsp;</span>-->
<!--                  <img :src="wx" alt="" class="right-icon" />-->
                  <div v-if="!paytyp0" class="check-item isChecked"  ></div>
                  <div v-else class="check-item noChecked" ></div>
                </template>
              </Cell>

              <div class="pay-body-card pay-body-payType" >
                <Cell title="支付宝支付" :icon="ali" @click="paytyp0 =!paytyp0" >
                  <template #right-icon>
                    <div v-if="paytyp0" class="check-item isChecked" ></div>
                    <div v-else class="check-item noChecked"></div>

                    <!--                    <img :src="wx" alt="" class="right-icon" />-->
                  </template>
                </Cell>
              </div>
            </div>
          </CellGroup>
        </div>
      </div>
    </div>
    <SubmitBar
      :decimal-length="2"
      :safe-area-inset-bottom="true"
      button-text="确认下单"
      :price="queryi.price * 100"
    >
     <a class="backa" href="uniwebview://action?key=value&anotherKey=anotherValue">返回</a>
      <template #button>
        <Button
          class="submit"
          type="info"
          :loading="isSubmit"
          loading-text="下单中..."
          @click="handleSubmit"
        >
          <img
            class="pay-loading"
            slot="loading"
            src="../assets/images/loading.svg"
            alt=""
          />
          确认下单
        </Button>
      </template>
    </SubmitBar>

  </div>
  <PayDetail v-else :orderDetail="orderDetail"></PayDetail>
</template>

<script>
import {
  SubmitBar,
  Button,
  Toast,
  CellGroup,
  Field,
  Popup,
  Picker,
  Cell,
  Radio,
  Dialog,
  CountDown,
} from "vant";
import { code } from "../assets/js/code";
import wx from "../assets/images/dou/sewq_icon_shopping_wechatpay.svg";
import ali from '../assets/images/sewq_icon_shopping_alipay.svg'
import PayDetail from "@/components/pay-detail";
export default {
  name: "Pay1",
  components: {
    SubmitBar,
    Button,
    CellGroup,
    Field,
    Popup,
    Picker,
    Cell,
    CountDown,
    PayDetail,
  },
  data() {
    const openid = JSON.parse(sessionStorage.getItem("openid"));
    return {
      orderDetail: {},
      showDetail: false,
      canSend: true,
      time: 60 * 1000,
      isSubmit: false, //支付loading状态
      isConfirm: true, //确认支付
      wx,
      ali,
      activePay: 0,
      isWx: false,
      form: {
        appId: process.env.VUE_APP_WX_APPID,
        method: "WECHAT_H5_PAY",//ALIPAY_WAP_PAY
        productNo: "",
        userId: "",
        returnUrl:""
      },
      queryi:{},
      showTelList: false,
      activeIndex: 0,
      orderNo: "",
      courseArr: [],
      paytyp0:false, //true 支付宝 false weixin
      radio: '1',
      payto:false
    };
  },
  computed: {
    codes() {
      const codes = code;
      return codes.map((v) => {
        return `+${v.code} ${v.name}`;
      });
    },
    currentCourse() {
      const courseArr = this.courseArr;
      return (
        courseArr.filter((e) => {
          return e.id == this.$route.query.id;
        })[0] || {}
      );
    },
  },
  created() {
    this.handleGetAllCourses();
    console.log(this.$route.query)
    this.queryi = this.$route.query

      let sett= setInterval(()=>{
        if(this.$route.query.orderNo){
        this.$http
            .get(`/orders/${this.$route.query.orderNo}/status`)
            .then((res) => {
              if (res.status == "COMPLETE") {
                clearInterval(sett)
                this.$router.push({path:'/g-pay',query:{orderNo:this.$route.query.orderNo}})
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },1000)
  },
  methods: {
    handleGetAllCourses() {
      this.$http.get("/products").then((res) => {
        let list = [];
        if (res && res.length) {
          list = [...res];
        }
        this.courseArr = [...list];
      });
    },
    handleResetCountDown() {
      this.canSend = true;
      this.$refs.countDown.reset();
    },
    handleCheck() {
      if (!this.form.mobile) {
        Toast({
          message: "请输入手机号",
        });
        return false;
      } else if (isNaN(Number(this.form.mobile))) {
        Toast({
          message: "手机号格式不正确",
        });
        return false;
      }
      return true;
    },
    handleGetCode() {
      if (this.handleCheck()) {
        this.canSend = false;
        const { code, mobile } = this.form;
        this.$http
          .post("/sms/send", {
            code,
            mobile,
          })
          .then((res) => {
            this.$refs.countDown.start();
          })
          .catch((value) => {
            this.canSend = true;
          });
      }
    },

    handleSubmit() {
    if(this.payto) return
      this.payto = true
      setTimeout(()=>{
        this.payto = false
      },1000)
      this.form.productNo = this.queryi.productNo
      this.form.method = this.paytyp0?"ALIPAY_WAP_PAY":"WECHAT_H5_PAY"
      this.form.userId = this.queryi.userId
      let urls = window.location.href
      // this.form.returnUrl = urls.replace("g-order","g-pay")
      this.form.returnUrl = "uniwebview://action?key=value&anotherKey=anotherValue"

      console.log(this.form)
      this.handleGetPayLink()
    },

    handlePayBridge(){

    },

    handleGetPayLink() {
      this.isSubmit = true;
      const loading = Toast.loading({
        message: "下单中...",
        forbidClick: true,
        duration: 0,
      });

      this.$http
        .post("/orders/app", {
            ...this.form
          // productNo: this.currentCourse.productNo,
        })
        .then((res) => {
          if (res.exist) {
          } else {
            setTimeout(() => {
              loading.clear();
              console.log(res)
              if(this.paytyp0){


                const div = document.createElement('div')
                /* 下面的data.content就是后台返回接收到的数据 */
                div.innerHTML = res.unifiedOrder.credential.from;

                document.body.appendChild(div)

                document.forms[0].submit()
              }else {
                const a = document.createElement("a");
                let urls = window.location.href
              //  a.href = "uniwebview://action?key=value&anotherKey=anotherValue";
                a.href =res.unifiedOrder.credential.h5_url
                //  +"&redirect_url=uniwebview://action?key=value&anotherKey=anotherValue";
                   + '&redirect_url='+urls.replace("g-order","g-pay").replace(/&/g,'%26')+'"%26orderNo="'+res.orderNo;
                a.click();
              }
            }, 500);
          }
        })
        .catch((err) => {
          this.isSubmit = false;
          loading.clear();
        });
    }
  },
};
</script>
<style scoped lang="less">
::v-deep.pay {
  width: 100%;
  min-height: calc(~"100vh - 50px");
  padding: 0 12px;
  background: #f3f4f7;
  font-size: 16px;
  position: relative;
  .van-count-down {
    color: #b4b4b4;
  }
  .van-overlay {
    z-index: 9999 !important;
  }
  .van-popup {
    z-index: 9999 !important;
    padding-bottom: 50px;
    .van-icon__image {
      width: 36px;
      height: 36px;
      transform: translateY(-4px);
    }
    .pop-title {
      height: 56px;
      font-size: 22px;
      font-family: PingFangTC, PingFangTC-Medium;
      font-weight: 500;
      text-align: center;
      color: #2fcdc6;
      line-height: 65px;
      margin-bottom: 10px;
    }
    .van-picker {
      margin-bottom: 10px;
      .van-picker-column__item {
        .van-ellipsis {
          opacity: 1;
          font-size: 14px;
          font-family: PingFangTC, PingFangTC-Regular;
          font-weight: 400;
          text-align: left;
          color: #98a4bd;
        }
      }
      .van-picker-column__item--selected {
        .van-ellipsis {
          font-size: 17px;
          font-family: PingFangTC, PingFangTC-Medium;
          font-weight: 500;
          text-align: left;
          color: #2fcdc6;
        }
      }
    }
    .picker-btn {
      width: 324px;
      height: 45px;
      margin: 0 auto;
      line-height: 48px;
      text-align: center;
      opacity: 1;
      background: #ffb243;
      border-radius: 23px;
      font-size: 20px;
      font-family: PingFangTC, PingFangTC-Medium;
      font-weight: 500;
      color: #ffffff;
    }
    &.success-pop {
      background: transparent;
      overflow: visible;
      .van-popup__close-icon--top-right {
        top: 100%;
        left: 50%;
        width: 36px;
        height: 36px;
        transform: translate(-50%, -20px);
      }
      .success {
        position: relative;
        img,
        i,
        b,
        span {
          display: block;
        }
        .success-box {
          width: 270px;
          height: 262px;
          background: #ffffff;
          border-radius: 29px;
          background-image: url("../assets/images/srwq_pop_bg@2x.png");
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: 100% 100%;
          position: relative;
          z-index: 10;
          .gift-box {
            width: 164px;
            height: 90px;
            position: absolute;
            top: -70px;
            left: 50%;
            transform: translateX(-50%);
          }
          .monkey {
            width: 80px;
            position: absolute;
            right: -55px;
            top: 20px;
          }
          .star {
            width: 15px;
            position: absolute;
            right: 30px;
            top: 15px;
          }
          p {
            font-size: 16px;
            font-family: PingFangTC, PingFangTC-Medium;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
            line-height: 20px;
            padding-top: 20px;
            margin-bottom: 23px;
          }
          i {
            font-style: normal;
            font-size: 14px;
            font-family: PingFangTC, PingFangTC-Regular;
            font-weight: 400;
            text-align: center;
            color: #2d2d2d;
          }
          .wx {
            width: 98px;
            margin: 0 auto;
          }
          b {
            font-size: 10px;
            font-family: PingFangSC-Regular, sans-serif;
            font-weight: 400;
            text-align: center;
            color: #514f6b;
            margin-bottom: 10px;
          }
          div {
            font-size: 10px;
            font-family: PingFangSC-Regular, sans-serif;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 15px;
            padding: 0 20px;
          }
        }
        .panda {
          width: 193px;
          height: 221px;
          position: absolute;
          left: -44px;
          top: -96px;
          z-index: 5;
        }
      }
    }
  }
  .van-submit-bar__bar {
    padding: 0;
    padding-left: 12px;
    & > span {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #00000d;
    }
    .van-submit-bar__text {
      & > span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4f4f4f;
      }
      .van-submit-bar__price {
        font-size: 18px;
        font-weight: 600;
        color: #fa326e;
      }
    }
  }
  &-title {
    text-align: center;
    font-size: 19px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #3E4F66;
    padding: 25px 0;
  }
  &-back {
    display: block;
    width: 20px;
    position: absolute;
    left: 22px;
    top: 22px;
  }
  &-body {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    padding-top: 20px;
    &-card {
      background: #ffffff;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
      &:not(:nth-last-of-type(1)) {
        margin-bottom: 12px;
      }
      &-title {
        width: 100%;
        height: 51px;
        line-height: 51px;
        text-align: left;
        padding: 0 20px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: bold;
        color: #000000;
      }
    }
    &-course {
      &-item {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 14px 20px;
        &:not(:nth-last-of-type(1)) {
          border-bottom: 1px solid #eee;
        }
        &-l {
          width: 124px;
          height: 64px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 13px;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        &-r {
          flex: 1;
          width: 0;
          span {
            display: block;
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #00000d;
            line-height: 21px;
            //margin-bottom: 10px;
            white-space: pre-wrap;
          }
          div {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            i {
              display: block;
              font-style: normal;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #80858a;
            }
            b {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: normal;
              color: #00000d;
            }
          }
        }
      }
    }
    &-contact {
      &-list {
        .get-code {
          background-color: #2fccc4;
          border-color: #2fccc4;
        }
        .mobile-field {
          .mobile-input {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            .input-select {
              background: #eeeeee;
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: flex-start;
              padding: 0 8px;
              padding-right: 0;
              margin-right: 5px;
              border-radius: 2px;
              span {
                font-size: 14px;
                color: #9aa4bc;
              }
              img {
                width: 15px;
              }
            }
            .van-field {
              padding: 0;
            }
          }
        }
      }
    }
    &-payType {
      width: 100%;
      .right-icon {
        width: 20px;
      }
      &-list {
        width: 100%;

        &-item {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 17px 28px 17px 21px;
          &-type {
            display: flex;
            flex-wrap: nowrap;
            flex: 1;
            width: 0;
            align-items: center;
            justify-content: flex-start;
            img {
              display: block;
              width: 20px;
              margin-right: 12px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #191919;
            }
          }

        }
      }
    }
    &-detail {
      &-content {
        width: 100%;
        .cell-price {
          color: #fe4417;
        }
        &-item {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 9px;

          &-label {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #00000d;
            line-height: 19px;
          }
          &-value {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #80858a;
            line-height: 19px;
          }
        }
      }
    }
  }
  @keyframes rotate {
    from {
      transform: rotateZ(-180deg);
    }
    to {
      transform: rotateZ(180deg);
    }
  }
  &-loading {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 6px;
    animation: rotate 1s linear infinite;
  }
  .submit {
    width: 130px;
    height: 50px;
    background: #2fccc4;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    border-radius: 0;
    border-color: #2fccc4;
  }
  .van-dialog {
    .van-button__text {
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
}
.check-item {
  width: 15px;
  height: 15px;
  margin-top: 6px;
  border-radius: 15px;
  overflow: hidden;
}
.noChecked {
  border: 1px solid #abadb1;
}
.isChecked {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background: #2fccc4;
  img {
    width: 100%;
    display: block;
  }
}
.backa{
  color: #abadb1;
}
</style>
