import Vue from "vue"
import VueRouter from "vue-router"
import { getBrowser, getQueryVariable } from "@/assets/js/tool"
import Home from './pages/home'

import System from './pages/system'

import Course from './pages/course'
import logon from './pages/logon'
import courseIndex from './pages/courseIndex.vue'
import Withdrawal from './pages/WithdrawalComponent.vue'
import invite from './pages/inviteComponent.vue'
import user from './pages/userComponent.vue'
import order from './pages/orderComponent.vue'


import CourseB from './pages/courseB'
import logonB from './pages/logonB'
import courseIndexB from './pages/courseIndexB.vue'
import WithdrawalB from './pages/WithdrawalComponentB.vue'
import inviteB from './pages/inviteComponentB.vue'
import userB from './pages/userComponentB.vue'
import orderB from './pages/orderComponentB.vue'
import Operation from './pages/WithdrawalOperation.vue'
import OperationB from './pages/WithdrawalOperationB.vue'

import CourseC from './pages/courseC.vue'
import courseIndexC from './pages/courseIndexC.vue'
import WithdrawalC from './pages/WithdrawalComponentC.vue'
import orderC from './pages/orderComponentC.vue'
import userC from './pages/userComponentC.vue'
import OperationC from './pages/WithdrawalOperationC.vue'



import Pay from './pages/pay'
import Empty from './pages/404'

import Order from './pages/order'
import Report from './pages/report'
import Dou from './pages/dou'
import DouPay from './pages/dou-pay'
import DouSuccess from './pages/dou-success'

import Promotion from './pages/promotion'

import GOrder from './pages/g-order'
import GPay from './pages/g-pay'
import MAppPay from './pages/apppay'
import newPay from './pages/neworder'
import BuySuccess from './pages/buysuccess'
import AppBuySuccess from './pages/app-buysuccess'
import AppOrder from './pages/app-order'

import Download from './pages/download'
import DownloadB from './pages/downloadB'
import agreement from './pages/agreement'
import agreementB from './pages/agreementB'


Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        meta: {
            title: "首页",
            menu: true,
            hideTopAndBottom: false,

        },
        component: Home
    },
    {
        path: '/m-introduce',
        meta: {
            title: "首页",
            menu: true,
            hideTopAndBottom: false,

            hideHeader: true,   //去除导航栏

        },
        component: Home
    },
    {
        path: '/ground-push', //地推落地页
        meta: {
            title: "首页",
            menu: true,
            hideTopAndBottom: false
        },
        component: Promotion
    },
    //---------
    {
        path: '/m-dou/:id',//抖音投放页
        meta: {
            title: "抖音广告",
            menu: false,
            hideTopAndBottom: true,
            hideHeader: true
        },
        component: Dou

    },
    {
        path: '/m-dou-pay',//抖音投放页
        meta: {
            title: "抖音订单支付",
            menu: false,
            hideTopAndBottom: true,
            hideHeader: true
        },
        component: DouPay

    },
    {
        path: '/m-dou-success',//抖音投放页
        meta: {
            title: "抖音订单支付成功",
            menu: false,
            hideTopAndBottom: true,
            hideHeader: true
        },
        component: DouSuccess

    },
    {
        path: '/m-system',
        meta: {
            title: "课程体系",
            menu: true,
            hideTopAndBottom: false
        },
        component: System

    },
    {
        path: '/m-systemB',
        meta: {
            title: "课程体系",
            menu: true,
            hideTopAndBottom: false,
            hideHeader: true,   //去除导航栏
        },
        component: System
    },
    //============
    {
        path: '/m-course',
        meta: {
            title: "教学中心",
            menu: true,
            hideTopAndBottom: false
        },
        component: Course,
        children: [
            {
                path: '',
                redirect: 'courseIndex'
            },
            {
                path: 'courseIndex',
                meta: {
                    // title: "默认首页",
                    hideHeader: false
                },
                component: courseIndex
            },
            {
                path: 'Withdrawal',
                meta: {
                    title: "提现操作",
                    hideHeader: true,// 子路由中隐藏页面头部
                    showHeader: true,
                    showHeadera: true,
                    showHeaderb: true,
                    showHeaderc: true,

                },
                component: Withdrawal
            },
            {
                path: 'invite',
                meta: {
                    title: "邀请用户",
                    hideHeader: true,
                    showHeader: true,
                    hideTopAndBottom:true,
                    showHeadera: true,   // 子路由中隐藏页面个人信息卡
                    // showHeaderb: true,
                },
                component: invite
            },

            {
                path: 'user',
                meta: {
                    title: "用户管理",
                    hideHeader: true,
                    showHeader: true
                },
                component: user
            },
            {
                path: 'order',
                meta: {
                    title: "订单管理",
                    hideHeader: true,
                    showHeader: true
                },
                component: order
            },
            {
                path: 'Operation',
                meta: {
                    title: "填写提现信息",
                    hideHeader: true,// 子路由中隐藏页面头部
                    showHeader: true,
                    showHeadera: true,
                    showHeaderb: true,
                    showHeaderc: true,
                },
                component: Operation
            },
        ]
    },

    {
        path: '/m-courseB',
        meta: {
            title: "教学中心",
            menu: true,
            hideTopAndBottom: false,
            hideHeader: true,// 子路由中隐藏页面头部
        },
        component: CourseB,
        children: [
            {
                path: '',
                redirect: 'courseIndexB'
            },
            {
                path: 'courseIndexB',
                meta: {
                    // title: "默认首页",
                    hideHeader: false,
                    hideHeader: true,// 子路由中隐藏页面头部
                    hideTopAndBottom: true,
                },
                component: courseIndexB
            },
            {
                path: 'WithdrawalB',
                meta: {
                    title: "提现操作",
                    hideHeader: true,// 子路由中隐藏页面头部
                    showHeader: true,
                    showHeadera: true,
                    showHeaderb: true,
                    showHeaderc: true,
                    hideTopAndBottom: true,

                },
                component: WithdrawalB
            },
            {
                path: 'inviteB',
                meta: {
                    title: "邀请用户",
                    hideHeader: true,
                    showHeader: true,
                    hideTopAndBottom: true,
                    showHeadera: true,   // 子路由中隐藏页面个人信息卡
                    // showHeaderb: true,
                },
                component: inviteB
            },
            {
                path: 'userB',
                meta: {
                    title: "用户管理",
                    hideHeader: true,
                    showHeader: true,
                    hideTopAndBottom: true,
                },
                component: userB
            },
            {
                path: 'orderB',
                meta: {
                    title: "订单管理",
                    hideHeader: true,
                    showHeader: true,
                    hideTopAndBottom: true,
                },
                component: orderB
            },

            {
                path: 'OperationB',
                meta: {
                    title: "填写提现信息",
                    hideHeader: true,// 子路由中隐藏页面头部
                    showHeader: true,
                    showHeadera: true,
                    showHeaderb: true,
                    showHeaderc: true,
                    hideTopAndBottom: true,
                },
                component: OperationB
            },
        
        ]
    },
    {
        path: '/m-courseC',
        meta: {
            title: "教学中心",
            menu: true,
            hideTopAndBottom: false,
            hideHeader: true,// 子路由中隐藏页面头部
        },
        component: CourseC,
        children: [
            {
                path: '',
                redirect: 'courseIndexC'
            },
            {
                path: 'courseIndexC',
                meta: {
                    // title: "默认首页",
                    hideHeader: false,
                    hideHeader: true,// 子路由中隐藏页面头部
                    hideTopAndBottom: true,
                },
                component: courseIndexC
            },
            {
                path: 'WithdrawalC',
                meta: {
                    title: "提现操作",
                    hideHeader: true,// 子路由中隐藏页面头部
                    showHeader: true,
                    showHeadera: true,
                    showHeaderb: true,
                    showHeaderc: true,
                    hideTopAndBottom: true,

                },
                component: WithdrawalC
            },
            {
                path: 'orderC',
                meta: {
                    title: "订单管理",
                    hideHeader: true,
                    showHeader: true,
                    hideTopAndBottom: true,
                },
                component: orderC
            },

            {
                path: 'OperationC',
                meta: {
                    title: "填写提现信息",
                    hideHeader: true,// 子路由中隐藏页面头部
                    showHeader: true,
                    showHeadera: true,
                    showHeaderb: true,
                    showHeaderc: true,
                    hideTopAndBottom: true,
                },
                component: OperationC
            },
            {
                path: 'userC',
                meta: {
                    title: "用户管理",
                    hideHeader: true,
                    showHeader: true,
                    hideTopAndBottom: true,
                },
                component: userC
            },
        
        ]
    },
    {
        path: '/m-logonB2',
        meta: {
            title: "登录",
            menu: true,
            hideTopAndBottom: true,
            hideHeader: true,// 子路由中隐藏页面头部

        },
        component: logonB
    },
    {
        path: '/downloadB',
        meta: {
            title: "客户端下载",
            hideTopAndBottom: true,
            hideHeader: true,
        },

        component: DownloadB
    },
    {
        path: '/agreement',
        meta: {
            title: "推广协议",
            hideTopAndBottom: true,
            hideHeader: true,
            showHeader: true,
        },
        component: agreement
    },
    {
        path: '/agreementB',
        meta: {
            title: "推广协议",
            hideTopAndBottom: true,
            hideHeader: true,
            showHeader: true,
        },
        component: agreementB
    },
    //---------------
    {
        path: '/m-pay',
        meta: {
            title: "确认支付",
            hideTopAndBottom: true
        },
        component: Pay
    },
    //=================
    {
        path: '/m-logon',
        meta: {
            title: "登录",
            menu: true,
            hideTopAndBottom: false
        },
        component: logon
    },
    //---------------
    {
        path: '/m-order',
        meta: {
            title: "确认订单",
            hideTopAndBottom: true
        },
        component: AppOrder
    },
    {
        path: '/g-order',
        meta: {
            title: "订单信息",
            hideTopAndBottom: true,
            hideHeader: true
        },
        component: GOrder
    },
    {
        path: '/g-pay',
        meta: {
            title: "订单信息",
            hideTopAndBottom: true,
            hideHeader: true
        },
        component: GPay
    },
    {
        path: '/m-report',
        meta: {
            title: "学习报告",
            hideTopAndBottom: true,
            hideHeader: true
        },
        component: Report
    },
    {
        path: '/m-apppay',
        meta: {
            title: "Go Master 围棋",
            hideTopAndBottom: true,
            hideHeader: true
        },
        component: MAppPay
    },
    {
        path: '/new-order',
        meta: {
            title: "确认订单",
            hideTopAndBottom: true,
            hideHeader: true
        },
        component: newPay
    },
    {
        path: '/app-order',
        meta: {
            title: "确认订单",
            hideTopAndBottom: true,
            hideHeader: true
        },
        component: AppOrder
    },
    {
        path: '/buy-success',
        meta: {
            title: "购买成功",
            hideTopAndBottom: true,
            hideHeader: true
        },
        component: BuySuccess
    },
    {
        path: '/app-buysuccess',
        meta: {
            title: "购买成功",
            hideTopAndBottom: true,
            hideHeader: true
        },
        component: AppBuySuccess
    },
    //================
    {
        path: '/404',
        name: "empty",
        meta: {
            title: "找不到页面",
            hideTopAndBottom: false
        },
        component: Empty
    },
    {
        path: '/download',
        meta: {
            title: "客户端下载",
            hideTopAndBottom: true,
            hideHeader: false,
        },
        component: Download
    },
    {
        path: '*',
        redirect: '/404'

    }
]
const router = new VueRouter({
    mode: "history",
    base: process.env.VUE_APP_BASE_ROUTER,
    scrollBehavior(to, from, savedPosition) {
        history.pushState(null, null, document.URL)
        return { x: 0, y: 0 }
    },
    routes
})
// router.beforeEach((to, from, next) => {
//     console.log('去哪里',to.path)
//     const userinfo = sessionStorage.getItem('userinfo');
//     if (to.path == '/m-course') {
//         if (userinfo) {
//             next();
//         } else {
//             next('/m-logon');
//         }
//     } else {
//         next();
//     }
// })
export default router
