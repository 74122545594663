<template>
  <div class="system">
    <div class="system-banner">
      <img src="@/assets/images/srwq_banner_img@2x.png" alt="" />
    </div>
    <div class="system-item1">
      <BlockTitle>Go Master课程简介</BlockTitle>
      <p>
        Go
        Master是玖连星针对青少儿推出的专业AI数智人围棋教育项目。课程通过通过AI互动的形式，还原线下课堂真实场景，结合配套APP的游戏化练习、专业赛事的组织举办等板块，形成专业的系统性的围棋学习闭环。
      </p>
    </div>
    <div class="system-item2">
      <BlockTitle>个体智力开发<br />帮助孩子提升五大思维能力</BlockTitle>
      <div class="temp_div">
        <div class="circle-item" v-for="(item, index) in circles" :key="index">
          <img :src="item.icon" alt="" />
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
    <div class="system-item3">
      <BlockTitle>六大课程等级<br />从入门到精通一路领航</BlockTitle>
      <img src="@/assets/images/srwq_class_middle_level_img3.png" alt="" />
    </div>
    <div class="system-item4">
      <BlockTitle>孩子的以下问题<br />让围棋来成为解决方案</BlockTitle>
      <div class="card-list">
        <ul>
          <li class="card-item" v-for="(item, index) in cards" :key="index">
            <span>{{ item.title }}</span>
            <p>{{ item.text }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="system-item5">
      <BlockTitle>教学质量有保障<br />备受家长称赞</BlockTitle>
      <ul>
        <li v-for="(o, i) in slogan" :key="i">
          <img
            src="@/assets/images/srwq_class_middle_greatlearing_icon@2x.png"
            alt=""
          />
          <span>{{ o }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BlockTitle from "@/components/blocktitle";
export default {
  components: { BlockTitle },
  name: "system",
  data() {
    return {
      cards: [
        {
          title: "小孩静不下来，老是坐不住，做事情三心二意的，注意力不集中",
          text: "孩子性格活泼是天生的，他们需要的不是“被管教”，而是要学会心无旁骛的专注下来完成学习任务。围棋讲究逻辑缜密、冷静思考，在培养孩子的注意力、局后复盘的记忆力方面有着独特的训练功效。",
        },
        {
          title: "孩子从小出生在国外，一句中文也不会。希望孩子能多接触中国文化",
          text: "围棋是中国传承了四千年的文汇精粹，围棋里的礼仪讲究、诗歌作品等都蕴涵了古人的智慧与哲思。Go Master采用双语教学，穿插围棋典故和成语分享。学围棋，但不止于围棋。",
        },
        {
          title: "小孩沉迷游戏，放学回来就抱着pad玩，作业做完也不学习其他的",
          text: "孩子好玩，引导玩乐学习是教育重点。Go Master课程配套APP，学习课前课后孩子都可以自行阶梯式AI闯关练棋。全球在线真人对弈，亲子赛事等功能也即将上线，真正做到兴趣培养，寓教于乐。",
        },
      ],
      circles: [
        {
          text: "逻辑思维能力",
          icon: require("../assets/images/srwq_top_introduce_online_img@2x.png"),
        },
        {
          text: "专注能力",
          icon: require("../assets/images/srwq_calss_middle_consitrant_icon@2x.png"),
        },
        {
          text: "问题解决能力",
          icon: require("../assets/images/srwq_calss_middle_qa_icon@2x.png"),
        },
        {
          text: "长效记忆能力",
          icon: require("../assets/images/srwq_calss_middle_brain_icon@2x.png"),
        },
        {
          text: "数理计算能力",
          icon: require("../assets/images/srwq_calss_middle_math_icon@2x.png"),
        },
      ],
      // isCN:sessionStorage.getItem("isCN"),
      slogan: [
        "传承文明：中华文化瑰宝，走向世界",
        "启迪智慧：培养思维能力，全面发展",
        "勤学善思：养成优良习惯，铸造品格",
        "永不言败：传递围棋精神，永攀高峰",
      ],
    };
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
::v-deep .system {
  &-banner {
    width: 100%;
    img {
      display: block;
      width: 100%;
    }
  }
  &-item1 {
    background: #f7f9fc;
    p {
      width: 245px;
      font-size: 13px;
      font-family: PingFangTC, PingFangTC-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
      line-height: 21px;
      text-align: left;
      margin: 0 auto;
      padding-bottom: 93px;
    }
  }
  &-item2 {
    padding-bottom: 66px;
    .temp_div {
      width: 100%;
      height: 280px;
      position: relative;
      background-image: url("../assets/images/srwq_middle_freeclass_img@2x.png");
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: 120px 135px;
      .circle-item {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50%;
        left: 50%;
        &:nth-of-type(1) {
          align-items: center;
          top: -0px;
          transform: translateX(-50%);
          span {
            &::after {
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
        &:nth-of-type(2) {
          top: 46px;
          left: 55px;
          align-items: flex-end;
          span {
            &::after {
              right: 0;
            }
          }
        }
        &:nth-of-type(3) {
          left: auto;
          top: 46px;
          right: 25px;
          align-items: flex-start;
          span {
            &::after {
              left: 0;
            }
          }
        }
        &:nth-of-type(4) {
          top: 180px;
          left: 10px;
          align-items: flex-end;
          span {
            &::after {
              right: 0;
            }
          }
        }
        &:nth-of-type(5) {
          left: auto;
          top: 180px;
          right: 10px;
          align-items: flex-start;
          span {
            &::after {
              left: 0;
            }
          }
        }
        img {
          display: block;
          width: 46px;
          margin-bottom: 23px;
        }
        span {
          display: block;
          font-size: 13px;
          font-family: PingFangTC, PingFangTC-Medium;
          font-weight: 500;
          text-align: center;
          color: #2fccc4;
          position: relative;
          &::after {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            opacity: 1;
            background: #00d1c7;
            border-radius: 50%;
            position: absolute;
            top: -14px;
          }
        }
      }
    }
  }
  &-item3 {
    padding: 0 12px;
    padding-bottom: 30px;
    background: #f7f9fc;
    img {
      display: block;
      width: 100%;
    }
  }
  &-item4 {
    background: #38d1c9;
    overflow: hidden;
    padding-bottom: 73px;
    .blocktitle .title-text {
      color: #ffffff !important;
    }
    .card-list {
      width: 100%;
      overflow-x: auto;
      ul {
        display: flex;
        width: 880px;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 20px;
        .card-item {
          flex: none;
          width: 265px !important;
          height: 265px;
          background: #ffffff;
          border-radius: 13px;
          box-shadow: 0px 4px 8px -2px #00c1b7;
          background-image: url("../assets/images/srwq_problem_middle_said_img@2x.png");
          background-repeat: no-repeat;
          background-position: 10px 10px;
          background-size: 22px 26px;
          padding: 28px 24px;
          margin-right: 20px;
          span {
            display: block;
            font-size: 15px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            text-align: center;
            color: #3ccfc7;
            line-height: 21px;
            padding: 0 10px;
            margin-bottom: 18px;
          }
          p {
            font-size: 13px;
            font-family: PingFangTC, PingFangTC-Regular;
            font-weight: 400;
            text-align: center;
            color: #2e2e2e;
            line-height: 22px;
          }
        }
      }
    }
  }
  &-item5 {
    width: 100%;
    padding-bottom: 53px;
    ul {
      width: 100%;
      li {
        width: 350px;
        margin: 0 auto;
        height: 38px;
        background: rgba(56, 209, 201, 0.33);
        border-radius: 19px;
        font-size: 16px;
        font-family: PingFangTC, PingFangTC-Semibold;
        text-align: center;
        color: #2fccc4;
        padding-left: 15px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;
        img{
          display: block;
          width: 23px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
