<template>
	<div class="maxBox">
		<div class="navBox" v-if="$route.meta.showHeader">
			<div class="navLeft" @click="goH()">
				<img src="../assets/images/agent/leftto.png" alt="图片">
			</div>
			<div class="navRight">
				<p>{{ $route.meta.title }}</p>
			</div>
		</div>
		<div class="nier">
			<div class="baioti">
				<p>玖连星商务推广合作协议</p>
			</div>
			<div class="duanluo">
				<p> 欢迎您注册/登录使用《玖连星》的商务推广系统（下称“本系统”）。请您在注册/登录本系统前仔细阅读《玖连星推广合作协议》（下称“本协议”）的所有内容，特别是有关商务推广业务的条款，以及免除、限制我方责任的条款。本协议是玖连星（下称我方）与玖连星推广人员（下称“推广员”或“你方”）所约定的规范双方权利、义务关系的电子合同（买卖合同）。
				</p>
				 <br>
				<p> 如您对本协议任何内容有异议，请不要注册及登录/使用本系统。当您点击确认键注册或者登录或使用本系统相关服务，则视为您已仔细阅读本协议所有内容，同意接受本协议的所有规定及我方相关政策的约束，成为推广员，有权对外推荐并转售《玖连星》（下称本产品）的产品或服务。
				</p>
			</div>

			<div class="hezuoneirong">
				<div class="h1">
					<p>一、合作内容</p>
				</div>
				<div>
					<p>1、协助我方提升本产品的销售，提升本产品的玩家用户规模。</p>
					<br>
					<p>2、为实现前述目的，你方可通过线下场所或线上渠道（包括不限于：直播间、微信、微博、QQ、贴吧、论坛、空间等）、自身人际关系、非官方网络资源等各种合法方式和渠道，推广本产品，我方将提供必要的支持和协助。
					</p>
					<br>
					<p>3、为实现前述目的，双方共同将为本产品做正面宣传，扩大本产品的知名度和美誉度。</p>
					<br>
					<p>4、推广业务流程：</p>
					<br>
					<div class="tuiguang">
						<p class="suojin">1)用户购买产品， 推广员和上级代理能看到实时的返利金额</p>
						<br>
						<p class="suojin">2)购买7天后允许提现， 后台可以给代理设置保证金，超出保证金的部分，才可以提现</p>
						<br>
						<p class="suojin">3)如您在推广期间想要终止此推广业务，可随时在系统内发起保证金提现功能</p>
					</div>
				</div>
			</div>

			<div class="tuiguangyuan">
				<div class="h1">
					<p>二、推广员的权利和义务</p>
				</div>
				<div>
					<p>1、推广员有意愿成为本产品相关付费服务的采购商，享有优先采购权有权从我方以低于市场统一定价的价格、大批量购买本产品的付费服务，并以市场统一定价对外转售。</p>
					<br>
					<p>2、推广员有权参与我方组织的市场推广活动，并从我方获得相应的奖励。</p>
					<br>
					<p>3、推广员不享有本产品在任何区域的独家合作资格。</p>
					<br>
					<p>4、推广员不得从我方之外的其他途径购买本产品的相关服务，不得私自降价销售，不得扰乱本产品相关服务的市场价格体系。</p>
					<br>
					<p>5、推广员不得对用户歧视性销售，不得捆绑销售、搭便车销售。</p>
					<br>
					<p>6、在合作推广过程中，推广员的任何行为均不得包含有虚假、诱骗、违反国家法律法规的内容，也不得做出有悖于玖连星对外公布的相关公告、协议内容的承诺或行为，不得通过组织、参与违法活动来追求销售业绩。如有发现，你方独自承担一切法律后果，且我方有权向国家有关机关做举报。
					</p>
					<br>
					<p>7、推广员有义务积极维护和宣传我方的品牌、业务和政策，不煽动、不参与任何形式的不利于我方品牌、业务、政策的活动。你方不可假冒和改变我方的商标、标志、商业信息等，不得故意造成与我方产品或服务的混淆，不得以我方公司或员工名义对外从事任何活动。
					</p>
					<br>
					<p>8、推广员须保证在进行本产品推广之前已经获得线上或者线下场所的许可，并遵守相应的线上线下法律规章制度，否则由此产生的纠纷和可能导致的一切责任由推广员本人承担；推广员同意我方对此免责。
					</p>
					<br>
					<p>9、推广员理解并认可，推广员在注册和使用本系统过程中所提供的个人资料及相关信息是我方判断你方身份的重要依据，你方应根据要求尽可能提供详尽的信息，并确保信息的完整性、合法性和真实性。如推广员提交的资料有任何变动，必须在本系统中及时更新,必要时以电话或邮件形式告知我方管理人员，以保证其资料准确性。如果推广员不能提供准确完整的个人信息、未及时更新相关信息或者提供相关的资料，将导致我方无法准确判断推广员的身份，从而影响到推广收益发放等操作。因此给推广员造成的损失，我方不承担任何责任。
					</p>
					<br>
					<p>10、如因推广员提交的信息资料不真实、不准确、不完整、不合法从而导致我方作出错误判断、遭受直接或间接经济损失的，我方有权立即取消该推广员的资格并追究推广员的法律责任。</p>
					<br>
					<P>11、推广员仅能依据本协议约定的内容，从事玖连星的推广活动，不得将本协议约定的权利、义务转让给任何第三方，不得擅自委托第三方从事本产品的推广行为。</P>
					<br>
					<P>12、推广员拥有自主的权利，可单方面随时决定终止与我方的合作、并停止使用本系统相关服务。如我方对本协议内容或相关服务等作出任何变更，而推广员不同意有关变更内容的，推广员应立即终止与我方的合作、并停止使用本系统相关服务。
					</P>
				</div>
			</div>

			<div class="jiulianxing">
				<div class="h1">
					<p>三、玖连星的权利和义务</p>
				</div>
				<div>
					<p>1、玖连星会对推广员提交的注册信息和资料进行及时审核，并有权根据公司业务发展需要，做出通过或不通过的决定。</p>
					<br>
					<P>2、玖连星有权了解推广员的推广情况，获得与推广员信息和推广业绩相关的必要的资料、数据和有关证据。</P>
					<br>
					<P>3、玖连星会根据推广员的销售业绩和公司政策，将推广员应得的收益及时支付到位。</P>
					<br>
					<P>4、玖连星将通过本系统的消息通知、电子邮件等方式，将最新的公司政策（包括但不限于优惠政策、对推广员的销售业绩要求等）下发给推广员。推广员有义务遵守、配合公司的各项政策。</P>
					<br>
					<P>5、玖连星有权根据实际需要随时修改本协议，并通过公告、系统消息或邮件等形式公布，修改自公告公布之日、或消息送达推广员邮箱之日起生效。推广员如有异议，可立即停止本协议，并与我方协商结算事宜。
					</P>
					<br>
					<P>6、玖连星有权单方随时变更、中断或终止本系统及相关服务，且不需对玩家或任何第三方负责。除本协议另有约定外，玖连星将以电话、短信、系统消息/页面或者电子邮件方式通知推广员。终止合作通知自发出之时即视为送达。如因推广员资料中登记的联络方式不正确、不完整或者不真实而导致联络失败的，玖连星无需对因此导致的任何损失或损害承担法律责任。
					</P>
					<br>
					<P>7、玖连星有权根据公司业务发展需要，终止本协议涉及的合作。在此情况下，玖连星应通过发布公告等形式通知推广员，协议的终止自公告发布之日起生效。</P>
					<br>
					<P>8、玖连星将竭力保证本产品相关系统和数据的稳定，但因技术问题或第三方原因导致产品宕机、数据丢失等问题，我方不向你方或其他第三方承担赔偿、补偿的法律义务。</P>
					<br>
					<P>9、玖连星有权要求推广员配合我方的相关推广工作，以保证推广本产品的效果和效率。</P>
					<br>
					<P>10、尊重个人隐私是玖连星的重要政策。在未经授权的情况下，玖连星不会公开或向第三方透露推广员在申请时提交的个人资料。但如根据法律规定或政府有权部门的有效命令，或在以下三种情况下，玖连星可不经授权而向相关部门或第三方公开或透露推广员的个人信息：
					</P>
					<br>
					<P class="suojin">(1)在紧急情况下维护推广员个人、第三人或社会大众的财产或人身安全；</P>
					<br>
					<P class="suojin">(2)保持、维护玖连星的知识产权或其它合法权益；</P>
					<br>
					<P class="suojin">(3)根据玖连星产品服务的相关规则、制度、条款，应当公开或披露的。</P>
				</div>
			</div>

			<div class="tuiguangshouyi">
				<div class="h1">
					<p>四、推广收益</p>
				</div>
				<div>
					<p class="suojin">推广员按照玖连星相关规定申领推广收益。玖连星将在后台确认推广业绩后七日内将推广收益发放至推广员微信账户，推广员可通过微信账号提现。</p>
				</div>
			</div>

			<div class="shengming">
				<div class="h1">
					<p>五、声明</p>
				</div>
				<div>
					<p class="suojin">玖连星与推广员在此声明：双方不会因根据本协议产生的推广合作，构成任何形式的劳动合同关系或劳务关系。推广员不隶属于玖连星，不受玖连星内部规章制度的约束。玖连星除向推广员支付本协议约定的推广收益外，不承担推广员的任何其他费用，包括但不限于因推广员因推广活动所产生的成本、社会保险、福利和医疗保险费用等。
					</p>
					<p class="suojin">
						玖连星与推广员共同在此声明：本协议不包含任何可能理解为双方之间设立一种推广员关系的内容。推广员无权代表玖连星对外缔结合同。推广员不得以玖连星的名义开展任何与本协议约定推广活动无关的活动，或者从事违法犯罪活动，否则一切后果由推广员自行承担，玖连星并保留追究其法律责任的权利。
					</p>
				</div>
			</div>

			<div class="weiyuezeren">
				<div class="h1">
					<p>六、违约责任</p>
				</div>
				<div>
					<p class="suojin">如推广员有违反本协议第二条第4、5、6、7、8、9、10、11款之规定的行为，玖连星有权根据推广员的违约事实和严重程度，对其采取扣发推广收益、停止发放推广收益、暂停推广合作资格、停止推广合作资格等措施，并有权向推广员追讨其通过不当行为所获得的利益。
						推广员对此充分理解并认可。
					</p>
				</div>
			</div>

			<div class="bukekangli">
				<div class="h1">
					<p>七、不可抗力</p>
				</div>
				<div>
					<p>1、一方因不可抗力不能履行本协议的，根据不可抗力的影响，可以部分或者全部免除违约责任。但一方迟延履行后发生不可抗力的，不能免除责任。</p>
					<br>
					<p>2、一方因不可抗力不能履行本协议的，应当及时通知对方，以减轻可能给对方造成的损失，并在合理期限内提供证明。未及时通知的，对因迟延通知而造成的扩大损失依然承担赔偿责任。</p>
					<br>
					<p>3、一方因不可抗力不能履行合同，但尚未造成对本协议根本违约的，另一方应该在履约时间上给予对方适当的宽限；如果因不可抗力导致不能实现本协议目的的，双方均有权解除本协议。</p>
				</div>
			</div>

			<div class="xieyideshengxiao">
				<div class="h1">
					<p>八、协议的生效和终止</p>
				</div>
				<div>
					<p>1、协议的生效：本协议自您登录推广系统并点击“我已同意《玖连星推广员合作协议》”时生效。</p>
					<br>
					<p>2. 下列情形之一出现时，本协议终止：</p>
					<br>
					<div>
						<p class="suojin">（1）本协议约定的合作期限届满；</p>
						<br>
						<p class="suojin">（2）本产品终止运营；</p>
						<br>
						<p class="suojin">（3）在本协议有效期内，推广员被依法追究刑事责任的，玖连星有权单方面终止本协议；</p>
						<br>
						<p class="suojin">（4）其他按照法律规定以及本协议约定的终止情况</p>
					</div>
				</div>
			</div>

			<div class="qitayeding">
				<div class="h1">
					<p>九、其他约定</p>
				</div>
				<div>
					<p class="suojin">本协议未尽事宜，可由双方另行签订补充协议予以明确。补充协议的内容与本协议的内容有冲突的，优先适用补充协议的规定。
						推广员与玖连星在合作中产生的争议，应当友好协商解决；协商不成的，任何一方均有权向被告所在地有管辖权的法院提起诉讼。
					</p>
				</div>
			</div>
			<br>
			<br>
			<br>
			<br>
		</div>
		<div>
		</div>
	</div>
</template>



<script>
export default {
	name: 'app',
	data() {
		return {

		}
	},
	mounted() {

	},
	methods: {
		goH() {
			this.$router.push('/m-logonB2/');
			console.log('出去');
		}
	}

}
</script>


<style scoped lang="less">
* {
	padding: 0;
	margin: 0;
}

.maxBox {
	width: 100vw;
	height: 100vh;
	background-color: #f7f9fc;

	.navBox {
		width: 100vw;
		height: 46px;
		display: flex;
		justify-content: space-between;
		background-color: #ffffff;
		align-items: center;
		padding: 0 10px;
		color: #000000;

		.navLeft {
			height: 46px;
			width: 46px;
			line-height: 46px;
			img {
				width: 23px;
				height: 23px;
				text-align: center;
			}
		}

		.navRight {
			width: 100vw;
			text-align: center;
			transform: translateX(-4%);
			font-size: 18px;
		}
	}

}

.nier {
	font-size: 18px;
	margin: 30px 15px 50px 15px;
	text-align: justify;

	.baioti {
		font-size: 26px;
		font-weight: 600;
		margin: 30px 10px 20px 10px;
		text-align: center;
	}

	.duanluo {
		text-indent: 4ch;
	}
}

.h1 {
	font-size: 20px;
	font-weight: 600;
	margin: 20px 0;
}
.suojin{
	text-indent: 4ch;
}
.qitayeding{
	margin-bottom: 50px;
}
</style>