<template>
  <div class="canvas-container" ref="container">

    <img :src="imageUrl" alt="">
    <!-- <div class="pBox">
      <p>长按图片分享邀请</p>
    </div> -->
    <!-- <div>
      <p style="font-size: 20px;font-weight: 600;text-align: center;color:red;">Tips:请长按图片进行转发或保存邀请页面</p>
    </div> -->
  </div>
</template>

<script>
import QRCode from 'qrcode';
import axios from 'axios';
export default {
  name: 'index_page',

  data() {
    return {
      invitationCode: '',
      imageUrl: '',
      QRurl: 'https://m.go-master.com/sharepurchaseA/#/',
      nickname:'',
      displayText:'',
    }
  },
  created() {
		var token = sessionStorage.getItem('userinfo');
		if (!token) {
			this.$router.push('/m-logon');
		}

    this.user_id = JSON.parse(sessionStorage.getItem('userinfo')).user_id;
    this.nickname = JSON.parse(sessionStorage.getItem('userinfo')).nickname;
    // this.displayText = this.nickname.length > 12 ? this.nickname.slice(0, 12) + '...' : this.nickname;
    this.displayText = this.nickname;

    console.log('长度',this.nickname)
    console.log('长度',this.nickname.length)
    if(JSON.parse(sessionStorage.getItem('userinfo')).invitation_code){
      this.invitationCode = JSON.parse(sessionStorage.getItem('userinfo')).invitation_code;
    }else{
      this.getData();
    }
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
    this.draw();
    window.addEventListener('resize', this.draw);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.draw);
  },
  methods: {
    getData() {
      var thisc = this;
      axios.post('https://teach-api.go-master.com/v4/Gomaster/getuserifoData', {
        user_id: this.user_id,
      })
        .then(function (response) {
          thisc.invitationCode = response.data;
          console.log('个人数据', thisc.userInfo);
        })
        .catch(function (error) {
          console.error('Error fetching user list:', error);
        });
    },
    async draw() {
      const container = this.$refs.container;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

  // 设置画布大小
  canvas.width = container.offsetWidth;
  // canvas.height = 750;
  canvas.height = 750;


      // 绘制背景图
      const bgImage = new Image();


      bgImage.src = require('../assets/images/agent/fenxiangD.png');

      bgImage.onload = async () => {
        ctx.drawImage(bgImage, 0, 0, canvas.width, canvas.height);

    // 绘制文本
    ctx.textAlign = 'left';
    ctx.textBaseline = 'middle';
    ctx.font = 'bold 17px Arial';
    ctx.fillStyle = '#D42E13';
    ctx.fillText(`${this.displayText}`, canvas.width / 1.5222, 565);

        // 生成二维码
        try {
          const qrDataURL = await QRCode.toDataURL(`${this.QRurl}?code=${this.invitationCode}`);
          const qrImage = new Image();
          qrImage.src = qrDataURL;
          qrImage.onload = () => {
            const qrWidth = 160; // 调整二维码图片宽度
            const qrHeight = 160; // 调整二维码图片高度
            const qrX = (canvas.width - qrWidth) / 1.15;
            const qrY = 350; // 调整二维码图片Y轴位置
            ctx.drawImage(qrImage, qrX, qrY, qrWidth, qrHeight);

        // 绘制图片
        const image = new Image();
        image.src = require('@/assets/images/app.png'); // 替换为您的图片路径
        image.onload = () => {
          const imageWidth = 35; // 图片宽度
          const imageHeight = 35; // 图片高度
          const imageX = (canvas.width - imageWidth) / 1.35; // 图片X轴位置
          const imageY = 415; // 图片Y轴位置
          ctx.drawImage(image, imageX, imageY, imageWidth, imageHeight);

          // 将绘制的内容转换为 data URL
          this.imageUrl = canvas.toDataURL();

          // 移除临时 canvas 元素
          canvas.remove();
        };
      };
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  };
},

    getData() {
      var thisc = this;
      axios.post('https://teach-api.go-master.com/v4/Gomaster/getCarteDataB', {
        phone: this.tel,
        invitation_code: this.invitationCode
      })
        .then(function (response) {
          console.log('个人数据123123', response.data);
          thisc.WithdrawalInProgress = response.data.Operation;  //提现中
          thisc.Withdrawn = response.data.Withdrawn;   //已提现
          thisc.Withdrawable = response.data.totalTransaction;    //可提现
          thisc.user_id = response.data.user_id;   //用户id
          thisc.payment_code = response.data.payment_code;   //用户id
          console.log('个人数据', thisc.userInfo);
        })
        .catch(function (error) {
          console.error('Error fetching user list:', error);
        });
    },
    tixian() {
      var thisc = this;
      if (!this.payment_code) {
        this.payment_code = sessionStorage.getItem('payment_code');
      }

      if (this.Withdrawable == 0) {
        alert("您暂无可提现余额！")
        return;
      }

      if (this.payment_code) {

        axios.post('https://teach-api.go-master.com/v4/Gomaster/Withdrawal', {
          user_id: this.user_id,
          invitation_code: this.invitationCode,
          amount: this.Withdrawable
        })
          .then(function (response) {
            console.log('提现成功！', response.data);
            thisc.getData()
            alert('提现成功，需等待2至3个工作日后到账！')
          })
          .catch(function (error) {
            console.error('Error fetching user list:', error);
          });
      } else {
        thisc.shangcVal = true
        console.log('没有收款码')

      }
    },
    PushData() {
      var thisc = this;
      if (!this.base64Image) {
        alert('请选择收款码！')
        return;
      }
      thisc.shaodengVal = true;
      axios.post('https://teach-api.go-master.com/v4/Gomaster/changeImg', {
        user_id: this.user_id,
        img: this.base64Image,
        invitation_code: this.invitationCode,
      })
        .then(function () {
          sessionStorage.setItem('payment_code', 'true');
          thisc.shangcVal = false
          thisc.shaodengVal = false;
          alert('收款码上传成功,请重新发起提现！');
        })
        .catch(function (error) {
          thisc.shaodengVal = false;
          alert('收款码上传失败,请重新上传！');
          console.error('Error fetching user list:', error);
        });
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.selectedImage = file;
          this.base64Image = reader.result;
          console.log('图片信息', this.base64Image)
        };
      }
    },
    gotockan(){
      if(!this.tel){
        alert('暂无获取到您个人信息，请取消重试!')
        return;
      }
      window.location.href =
								`https://m.go-master.com/m-courseC/courseIndexC/?phone=${this.tel}`;
                // 'https://m.go-master.com/m-courseC/courseIndexC/?phone=17807036656';
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.canvas-container {
  /* position: absolute; */
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100vh;
  top: 48px;
  overflow-y: auto; /* 当内容超出最大高度时启用垂直滚动条 */
  background-color: #FBFFD7;
  img{
    width: 100vw;
    height: auto;
    object-fit: contain;
  }
}

.classBox {
  height: 44px;
  line-height: 44px;
  font-size: 18px;
  font-weight: 600;
}

.pBox {
  position: relative;
  top: -225px;
  left: 90px;
  /* font-size: 20px; */
  width: 250px;
  margin:auto;
  color: black;
  text-align: center;
  background-color: #F6FFE9;
  border-radius: 5px;
  white-space: nowrap;
  
  p{
    font-size: 18px;
    color:#D42F14;
    font-weight: 600;
    line-height: 30px;
  }
}
</style>
