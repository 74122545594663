<template>
  <div class="pay " v-if="!showDetail">
    <div class="pay-body">
      <div class="pay-body-card pay-body-detail">
        <div class="pay-body-detail-content">
          <Cell
            title="商品名称："
            :value="'Go Master少儿围棋课程-' + orderDetail.productName"
          />
          <Cell title="订单号：" :value="orderDetail.orderNo || orderNo" />
          <Cell
            title="应付金额："
            value-class="cell-price"
            :value="'¥' + (orderDetail.amount || 0)"
          />
          <Cell
            title="订单状态："
            :value="status[orderDetail.status].value"
            :value-class="status[orderDetail.status].class"
          />
          <Cell title="剩余支付时间：">
            <CountDown
              :time="orderDetail.timeLeft * 1000"
              format="HH:mm:ss"
            ></CountDown>
          </Cell>
        </div>
      </div>
      <div class="pay-body-card pay-body-payType">
        <Cell title="支付方式">
          <template #right-icon>
            <span>微信支付&nbsp;</span>
            <img :src="wx" alt="" class="right-icon" />
          </template>
        </Cell>
      </div>
    </div>
    <SubmitBar
      :decimal-length="2"
      :safe-area-inset-bottom="true"
      button-text="立即支付"
      :price="orderDetail.amount * 100 || 0"
    >
      <template #button>
        <Button
          class="submit"
          type="info"
          :loading="isSubmit"
          loading-text="支付中..."
          @click="handleSubmit"
        >
          <img
            class="pay-loading"
            slot="loading"
            src="../assets/images/loading.svg"
            alt=""
          />
          立即支付
        </Button>
      </template>
    </SubmitBar>
  </div>
  <div class="success" v-else>
    <div class="success-box animated zoomIn">
      <img
        src="@/assets/images/dou/sewq_pop_succeed_bg@2x.png"
        alt=""
        class="success-box-bg"
      />
      <div class="success-box-wx">
        <img src="@/assets/images/dou/sewq_qrcode_img@2x.png" alt="" />
        <p>截图至微信扫一扫</p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SubmitBar,
  Button,
  Toast,
  CellGroup,
  Field,
  Popup,
  Picker,
  Cell,
  CountDown,
  Dialog,
} from "vant";
import wx from "../assets/images/wx.svg";
import PayDetail from "@/components/pay-detail";
export default {
  name: "Pay",
  components: {
    SubmitBar,
    Button,
    CellGroup,
    Field,
    Popup,
    Picker,
    Cell,
    CountDown,
    PayDetail,
  },
  data() {
    const openid = JSON.parse(sessionStorage.getItem("openid"));
    return {
      orderDetail: {
        status: "NEW",
      },
      showDetail: false,
      isSubmit: false, //支付loading状态
      isConfirm: true, //确认支付
      wx,
      activePay: 0,
      isWx: false,
      form: {
        appId: process.env.VUE_APP_WX_APPID,
        openid,
      },
      orderNo: this.$route.query.outTradeNo,
      userId: this.$route.query.userId,
      status: {
        NEW: {
          value: "新建订单",
          class: "gb",
        },
        PENDING: {
          value: "待支付",
          class: "rd",
        },
        COMPLETE: {
          value: "支付完成",
          class: "gn",
        },
        CANCELED: {
          value: "已取消",
          class: "ga",
        },
      },
    };
  },
  created() {
    this.handleQueryOrderStatus();
  },
  methods: {
    handleConfirmOrder() {
      const _this = this;
      Dialog.confirm({
        overlayStyle: { opacity: 0.4 },
        title: "订单已失效，请重新下单",
        showCancelButton: false,
        confirmButtonText: "重新下单",
        lockScroll: true,
        confirmButtonColor: "#ffdd00",
        beforeClose: (action, done) => {
          _this.$router.push({ path: "/m-dou-pay" });
          done();
        },
      });
    },
    handleQueryOrderStatus() {
      this.$http
        .get(`/orders/${this.$route.query.outTradeNo}/status`)
        .then((res) => {
          this.orderDetail = { ...res };
          if (res.status == "COMPLETE") {
            this.showDetail = true;
            this.isSubmit = false;
          } else if (res.timeLeft < 1) {
            this.handleConfirmOrder();
          } else {
            setTimeout(() => {
              this.handleQueryOrderStatus();
            }, 3000);
          }
        })
        .catch((err) => {
          this.isSubmit = false;
          console.log(err);
        });
    },
    handleResetCountDown() {
      this.$refs.countDown.reset();
    },
    handleSubmit() {
      if (this.orderDetail.timeLeft < 1) {
        this.handleConfirmOrder();
      } else {
        this.handleGetPayLink();
      }
    },
    handleGetPayLink() {
      this.isSubmit = true;
      const loading = Toast.loading({
        message: "支付中...",
        forbidClick: true,
        duration: 0,
      });
      let method = "";
      method = "WECHAT_H5_PAY";
      this.$http
        .post("/orders/pay", {
          ...this.form,
          method,
          outTradeNo: this.orderNo,
          userId: this.userId,
          sceneType: "",
        })
        .then((res) => {
          const a = document.createElement("a");
          a.href =
            res.credential.h5_url +
            `&redirect_url=${encodeURIComponent(window.location.href)}`;
          a.click();
          loading.clear();
          setTimeout(() => {
            this.isSubmit = false;
          }, 3500);
        })
        .catch((err) => {
          this.isSubmit = false;
          loading.clear();
        });
    },
  },
};
</script>
<style scoped lang="less">
::v-deep.pay {
  width: 100%;
  min-height: 100vh;
  padding: 0 12px;
  background: #f3f4f7;
  font-size: 16px;
  position: relative;
  padding-bottom: 70px;
  
  .van-count-down {
    color: #333333;
  }
  .van-overlay {
    z-index: 9999 !important;
  }
  .van-cell__value {
    flex: 1.8;
    color: #333333;
  }
  .gb {
    color: #ffdd00;
  }
  .rd {
    color: #f35353;
  }
  .gn {
    color: #00d26e;
  }
  .ga {
    color: #333333;
  }
  .van-popup {
    z-index: 9999 !important;
    padding-bottom: 50px;
    .van-icon__image {
      width: 36px;
      height: 36px;
      transform: translateY(-4px);
    }
    .pop-title {
      height: 56px;
      font-size: 22px;
      font-family: PingFangTC, PingFangTC-Medium;
      font-weight: 500;
      text-align: center;
      color: #2fcdc6;
      line-height: 65px;
      margin-bottom: 10px;
    }
    .van-picker {
      margin-bottom: 10px;
      .van-picker-column__item {
        .van-ellipsis {
          opacity: 1;
          font-size: 14px;
          font-family: PingFangTC, PingFangTC-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
        }
      }
      .van-picker-column__item--selected {
        .van-ellipsis {
          font-size: 17px;
          font-family: PingFangTC, PingFangTC-Medium;
          font-weight: 500;
          text-align: left;
          color: #2fcdc6;
        }
      }
    }
    .picker-btn {
      width: 324px;
      height: 45px;
      margin: 0 auto;
      line-height: 48px;
      text-align: center;
      opacity: 1;
      background: #ffb243;
      border-radius: 23px;
      font-size: 20px;
      font-family: PingFangTC, PingFangTC-Medium;
      font-weight: 500;
      color: #ffffff;
    }
    &.success-pop {
      background: transparent;
      overflow: visible;
      .van-popup__close-icon--top-right {
        top: 100%;
        left: 50%;
        width: 36px;
        height: 36px;
        transform: translate(-50%, -20px);
      }
      .success {
        position: relative;
        img,
        i,
        b,
        span {
          display: block;
        }
        .success-box {
          width: 270px;
          height: 262px;
          background: #ffffff;
          border-radius: 29px;
          background-image: url("../assets/images/srwq_pop_bg@2x.png");
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: 100% 100%;
          position: relative;
          z-index: 10;
          .gift-box {
            width: 164px;
            height: 90px;
            position: absolute;
            top: -70px;
            left: 50%;
            transform: translateX(-50%);
          }
          .monkey {
            width: 80px;
            position: absolute;
            right: -55px;
            top: 20px;
          }
          .star {
            width: 15px;
            position: absolute;
            right: 30px;
            top: 15px;
          }
          p {
            font-size: 16px;
            font-family: PingFangTC, PingFangTC-Medium;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
            line-height: 20px;
            padding-top: 20px;
            margin-bottom: 23px;
          }
          i {
            font-style: normal;
            font-size: 14px;
            font-family: PingFangTC, PingFangTC-Regular;
            font-weight: 400;
            text-align: center;
            color: #2d2d2d;
          }
          .wx {
            width: 98px;
            margin: 0 auto;
          }
          b {
            font-size: 10px;
            font-family: PingFangSC-Regular, sans-serif;
            font-weight: 400;
            text-align: center;
            color: #514f6b;
            margin-bottom: 10px;
          }
          div {
            font-size: 10px;
            font-family: PingFangSC-Regular, sans-serif;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 15px;
            padding: 0 20px;
          }
        }
        .panda {
          width: 193px;
          height: 221px;
          position: absolute;
          left: -44px;
          top: -96px;
          z-index: 5;
        }
      }
    }
  }
  .van-submit-bar__bar {
    padding: 0;
    padding-left: 12px;
    & > span {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #00000d;
    }
    .van-submit-bar__text {
      & > span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4f4f4f;
      }
      .van-submit-bar__price {
        font-size: 18px;
        font-weight: 600;
        color: #fa326e;
      }
    }
  }
  &-title {
    text-align: center;
    font-size: 19px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    padding: 25px 0;
  }
  &-back {
    display: block;
    width: 20px;
    position: absolute;
    left: 22px;
    top: 22px;
  }
  &-body {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    padding-top: 20px;
    &-card {
      background: #ffffff;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
      &:not(:nth-last-of-type(1)) {
        margin-bottom: 12px;
      }
      &-title {
        width: 100%;
        height: 51px;
        line-height: 51px;
        text-align: left;
        padding: 0 20px;
        border-bottom: 1px solid #eeeeee;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
    }
    &-course {
      &-item {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 14px 20px;
        &:not(:nth-last-of-type(1)) {
          border-bottom: 1px solid #eee;
        }
        &-l {
          width: 124px;
          height: 64px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 13px;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        &-r {
          flex: 1;
          width: 0;
          span {
            display: block;
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #00000d;
            line-height: 21px;
            margin-bottom: 10px;
            white-space: pre-wrap;
          }
          div {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            i {
              display: block;
              font-style: normal;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #80858a;
            }
            b {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: normal;
              color: #00000d;
            }
          }
        }
      }
    }
    &-contact {
      &-list {
        .get-code {
          background-color: #ffdd00;
          border-color: #ffdd00;
        }
        .mobile-field {
          .mobile-input {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            .input-select {
              background: #eeeeee;
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: flex-start;
              padding: 0 8px;
              padding-right: 0;
              margin-right: 5px;
              border-radius: 2px;
              span {
                font-size: 14px;
                color: #9aa4bc;
              }
              img {
                width: 15px;
              }
            }
            .van-field {
              padding: 0;
            }
          }
        }
      }
    }
    &-payType {
      width: 100%;
      .right-icon {
        width: 20px;
      }
      &-list {
        width: 100%;

        &-item {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 17px 28px 17px 21px;
          &-type {
            display: flex;
            flex-wrap: nowrap;
            flex: 1;
            width: 0;
            align-items: center;
            justify-content: flex-start;
            img {
              display: block;
              width: 20px;
              margin-right: 12px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #191919;
            }
          }
          .check-item {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            overflow: hidden;
          }
          .noChecked {
            border: 1px solid #abadb1;
          }
          .isChecked {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
              display: block;
            }
          }
        }
      }
    }
    &-detail {
      &-content {
        width: 100%;
        .cell-price {
          color: #fe4417;
        }
        &-item {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 9px;

          &-label {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #00000d;
            line-height: 19px;
          }
          &-value {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #80858a;
            line-height: 19px;
          }
        }
      }
    }
  }
  @keyframes rotate {
    from {
      transform: rotateZ(-180deg);
    }
    to {
      transform: rotateZ(180deg);
    }
  }
  &-loading {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 6px;
    animation: rotate 1s linear infinite;
  }
  .submit {
    width: 130px;
    height: 50px;
    background: #ffdd00;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    border-radius: 0;
    border-color: #ffdd00;
  }
  .van-dialog {
    .van-button__text {
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
}
.success {
  background:#fef8f1 ;
  width: 100%;
  height: 100vh;
  padding-top: 10vh;
    &-box {
      width: 277px;
      margin: 0 auto;
      position: relative;
      img {
        display: block;
      }
      &-bg {
        width: 100%;
      }
      &-wx {
        width: 138px;
        position: absolute;
        top: 188px;
        left: 50%;
        transform: translateX(-74px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        img {
          width: 100%;
          &:nth-of-type(1) {
            width: 120px;
            margin-bottom: 10px;
          }
        }
        p {
          font-size: 14px;
          font-weight: 600;
          color: #333333;
        }
      }
    }
  }
</style>
