<template>
  <div class="page">
    <div class="card">
      <div class="title">
        <div class="titlebefore"></div>
        <span> 报名手机号</span>
      </div>
      <div style="text-align: center">
        <input
          v-model="form.mobile"
          class="inpou"
          style="padding-left: 1.6rem"
          placeholder="请输入手机号"
        />
      </div>
      <div class="contry">+86</div>
      <div>
        <div style="text-align: center">
          <input
            v-model="form.validCode"
            placeholder="请输入验证码"
            class="inpou"
            type="number"
          />
          <div class="line"></div>
        </div>
        <div v-show="canSend" class="get-code" @click="handleGetCode">
          获取验证码
        </div>
        <CountDown
          class="get-code"
          style="color: #aeb8c7"
          v-show="!canSend"
          ref="countDown"
          :auto-start="false"
          :time="time"
          format="重新发送(ss)"
          @finish="handleResetCountDown"
        ></CountDown>
      </div>
      <div class="title">
        <div class="titlebefore"></div>
        <span>推荐码（选填）</span>
      </div>
      <div style="text-align: center; padding-bottom: 20px">
        <input v-model="c0de" class="inpou" placeholder="请输入推荐码" />
      </div>
    </div>
    <div class="card center">
      <div class="title">
        <div class="titlebefore"></div>
        订单信息
      </div>
      <div class="info">
        <div class="ke">棋妙围棋【体验课】</div>
        <div class="money">￥9.9</div>
      </div>

      <img
        class="bannerimg"
        src="../assets/images/sewq_img_shopping_banner.png"
      />
    </div>
    <div class="card">
      <!-- <div class="col" @click="paytyp0 = !paytyp0">
        <div class="left">
          <img :src="ali" class="icon" />
          支付宝支付
        </div>
        <div v-if="paytyp0" class="check-item isChecked"></div>
        <div v-else class="check-item noChecked"></div>
      </div> -->
      <div class="col bot">
        <div class="left">
          <img :src="wx" class="icon" />
          微信支付
        </div>
        <div class="check-item isChecked"></div>
        <!-- <div v-if="!paytyp0" class="check-item isChecked"></div>
        <div v-else class="check-item noChecked"></div> -->
      </div>
      
    </div>
    <div class="userage" @click="argee = !argee">
      <div v-if="argee" class="check-item isChecked pay"></div>
      <div v-else class="check-item noChecked"></div>
      &nbsp; &nbsp;已阅读并同意<a
        style="color: #28a2ff"
        href="https://www.go-master.com/UserAgreement.html"
        >《用户协议》</a
      >
    </div>
    <div class="sumbit">
      <div class="heji">合计：<span class="price">￥9.9</span></div>
      <a class="btnpay" @click="handleSubmit">立即支付</a>
    </div>
  </div>
</template>

<script>
import {
  SubmitBar,
  Button,
  Toast,
  CellGroup,
  Field,
  Popup,
  Picker,
  Cell,
  Dialog,
  CountDown,
  Checkbox,
} from "vant";
import { code } from "../assets/js/code";
import wx from "../assets/images/dou/sewq_icon_shopping_wechatpay.svg";
import ali from "../assets/images/sewq_icon_shopping_alipay.svg";
import { isWxBrowser } from "@/assets/js/tool";
import PayDetail from "@/components/pay-detail";
export default {
  name: "newPay",
  components: {
    SubmitBar,
    Button,
    CellGroup,
    Field,
    Popup,
    Picker,
    Cell,
    CountDown,
    Checkbox,
    PayDetail,
  },
  data() {
    return {
      orderDetail: {},
      showDetail: false,
      canSend: true,
      time: 60 * 1000,
      isSubmit: false, //支付loading状态
      isConfirm: true, //确认支付
      wx,
      ali,
      activePay: 0,
      isWx: false,
      form: {
        appId: process.env.VUE_APP_WX_APPID,
        mobile: "",
        code: code[0].code,
        remark: "",
        validCode: "",
      },
      showTelList: false,
      activeIndex: 0,
      orderNo: "",
      courseArr: [],
      paytyp0: false, //true 支付宝 false weixin
      radio: "1",
      payto: false,
      argee: true,
      c0de: "",
      form1: {
        appId: process.env.VUE_APP_WX_APPID,
      },
      intervalCount: 0,
    };
  },
  computed: {
    codes() {
      const codes = code;
      return codes.map((v) => {
        return `+${v.code} ${v.name}`;
      });
    },
    currentCourse() {
      const courseArr = this.courseArr;
      return (
        courseArr.filter((e) => {
          return e.id == this.$route.query.id;
        })[0] || {}
      );
    },
  },
  created() {
    this.handleGetAllCourses();
  },
  methods: {
    CurentTime() {
      var now = new Date();

      var year = now.getFullYear(); //年
      var month = now.getMonth() + 1; //月
      var day = now.getDate(); //日

      var hh = now.getHours(); //时
      var mm = now.getMinutes(); //分

      var ss = now.getSeconds();

      var clock = year + "-";

      if (month < 10) clock += "0";

      clock += month + "-";

      if (day < 10) clock += "0";

      clock += day + " ";

      if (hh < 10) clock += "0";

      clock += hh + ":";
      if (mm < 10) clock += "0";
      clock += mm + ":";
      if (ss < 10) clock += "0";
      clock += ss;
      return clock;
    },
    handleGetAllCourses() {
      if (this.$router.query && this.$router.query.mobile) {
        this.$router.push({
          path: "/buy-success",
          query: {
            mobile: this.from.mobile,
            time: this.CurentTime(),
          },
        });
      }
      this.$http.get("/products").then((res) => {
        let list = [];
        if (res && res.length) {
          list = [...res];
        }
        this.courseArr = [...list];
      });
    },
    handleResetCountDown() {
      this.canSend = true;
      this.$refs.countDown.reset();
    },
    handleCheck() {
      if (!this.form.mobile) {
        Toast({
          message: "请输入手机号",
        });
        return false;
      } else if (isNaN(Number(this.form.mobile))) {
        Toast({
          message: "手机号格式不正确",
        });
        return false;
      } else if (this.form.mobile.length != 11) {
        Toast({
          message: "手机号格式不正确",
        });
        return false;
      } else if (!this.argee) {
        Toast({
          message: "请先勾选用户协议",
        });
        return false;
      }
      return true;
    },
    handleGetCode() {
      if (this.handleCheck()) {
        this.canSend = false;
        const { code, mobile } = this.form;
        this.$http
          .post("/sms/send", {
            code,
            mobile,
          })
          .then((res) => {
            Toast({
              message: "验证码已发送",
            });
            this.$refs.countDown.start();
          })
          .catch((value) => {
            this.canSend = true;
          });
      }
    },
    handleConfirmOrder(res) {
      const _this = this;
      Dialog.confirm({
        overlayStyle: { opacity: 0.4 },
        title: "您有一个待支付订单，是否重新下单？",
        showCancelButton: true,
        cancelButtonText: "继续支付",
        confirmButtonText: "重新下单",
        lockScroll: true,
        confirmButtonColor: "#3276FF",
        cancelButtonColor: "#FA326E",
        beforeClose: (action, done) => {
          if (action === "confirm") {
            _this.handleResubmit(done);
          } else {
            setTimeout(() => {
              _this.isSubmit = false;
              _this.$router.push({
                path: "/m-pay",
                query: {
                  userId: res.userId,
                  orderNo: res.orderNo,
                  courseId: this.$route.query.id,
                },
              });
              done();
            }, 500);
          }
        },
      });
    },
    handleResubmit(done) {
      this.$http
        .post("/orders/again", {
          ...this.form,
          productNo: this.currentCourse.productNo,
          channelId: this.c0de || "5964823168",
        })
        .then((res) => {
          this.isSubmit = false;
          setTimeout(() => {
            done();
            this.$router.push({
              path: "/m-pay",
              query: {
                userId: res.userId,
                orderNo: res.orderNo,
                courseId: this.$route.query.id,
              },
            });
          }, 500);
        })
        .catch((err) => {
          console.log(err);
          this.isSubmit = false;

          done();
        });
    },
    onChange(picker, value, index) {
      this.activeIndex = index;
    },
    handleSelectChange() {
      const current = code[this.activeIndex];
      this.form = {
        ...this.form,
        code: current.code,
      };
      this.showTelList = false;
    },
    handleCheckParams() {
      const { validCode } = this.form;
      if (!validCode) {
        Toast({
          message: "请输入验证码",
        });
        return false;
      }
      return true;
    },
    handleSubmit() {
      if (this.handleCheck() && this.handleCheckParams()) {
        this.handleGetPayLink();
      }
    },
    // wxpay(rest) {
    //   console.log(123223);
    //   this.$http
    //     .post("/orders/pay", {
    //       ...this.form1,
    //       ...this.form,
    //       method,
    //       outTradeNo: rest.orderNo,
    //       userId: rest.userId,
    //       sceneType: "",
    //     })
    //     .then((res) => {
    //       if (isWxBrowser()) {
    //         this.handleWxJsBridge(res);
    //       } else {
    //         const a = document.createElement("a");
    //         a.href =
    //           res.credential.h5_url +
    //           `&redirect_url=${encodeURIComponent("https://test-api.go-master.com/m/buy-success/?mobile=" + this.form.mobile +"&time="+this.CurentTime())}`;
    //         a.click();
    //       }
    //       loading.clear();
    //       setTimeout(() => {
    //         this.isSubmit = false;
    //       }, 500);
    //     })
    //     .catch((err) => {
    //       this.isSubmit = false;
    //       loading.clear();
    //     });
    // },
    handleWxJsBridge(data) {
      //微信浏览器内拉起微信支付 JSAPI
      const self = this;
      const params = {
        ...data.unifiedOrder.credential,
      };
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        { ...params },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            self.$router.push({
              path: "/buy-success",
              query: {
                mobile: this.query.mobile,
                time: this.CurentTime(),
              },
            });
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
        }
      );
    },
    handleQueryOrderStatus(loading) {
      let self = this;
      if (!this.orderNo) {
        return;
      }
      this.$http
        .get(`/orders/${this.orderNo}/status`)
        .then((res) => {
          self.orderDetail = { ...res };
          if (res.status == "COMPLETE") {
            self.$router.push({
              path: "/buy-success",
              query: {
                mobile: self.form.mobile,
                time: self.CurentTime(),
              },
            });
            loading.clear();
            return;
          }
          setTimeout(() => {
            self.handleQueryOrderStatus(loading);
          }, 2000);
        })
        .catch((err) => {
          Toast({
            message: "支付失败，请重新支付！",
          });
          this.isSubmit = false;
          console.log(err);
        });
    },
    handleGetPayLink() {
      this.isSubmit = true;
      const self = this;
      const openid = JSON.parse(sessionStorage.getItem("openid"));
      const loading = Toast.loading({
        message: "下单中...",
        forbidClick: true,
        duration: 0,
      });
      let method = "";

      if (isWxBrowser()) {
        method = "WECHAT_JSAPI_PAY";
      } else {
        method = "WECHAT_H5_PAY";
      }
      this.$http
        .post("/orders/submit", {
          ...this.form1,
          ...this.form,
          openid,
          method,
          productNo: "local_1",
          channelId: this.c0de || "5964823168",
          // outTradeNo: this.orderNo,
          // userId: this.userId,
          // sceneType: "",
        })
        .then((res) => {
          this.orderNo = res.orderNo;

          if (isWxBrowser()) {
            this.handleWxJsBridge(res);
          } else {
            if (res.unifiedOrder) {
              const a = document.createElement("a");
              let ui = window.location.href.replace("new-order", "buy-success");
              a.href =
                res.unifiedOrder.credential.h5_url +
                `&redirect_url=${encodeURIComponent(
                  ui + "?mobile=" + this.form.mobile
                )}`;
              a.click();
            }
          }
          self.handleResetCountDown();
          self.handleQueryOrderStatus(loading);
          setTimeout(() => {
            this.isSubmit = false;
          }, 500);
        })
        .catch((err) => {
          this.form.validCode = "";
          this.isSubmit = false;
          loading.clear();
        });

      //     this.$http
      //       .post("/orders/confirm", {
      //         ...this.form,
      //         productNo: "local_1",
      //         channelId: this.c0de || "5964823168",
      //       })
      //       .then((rest) => {
      //         console.log(rest);
      //         const method = "";
      //         if (isWxBrowser()) {
      //           method = "WECHAT_JSAPI_PAY";
      //         } else {
      //           method = "WECHAT_H5_PAY";
      //         }
      //         setTimeout(() => {
      //           self.wxpay(rest);
      //         }, 500);
      //         // if (res.exist) {
      //         //   loading.clear();
      //         //   this.handleConfirmOrder(res);
      //         // } else {
      //         //  handleWxJsBridge();
      //         // setTimeout(() => {
      //         //   loading.clear();
      //         //   this.$router.push({
      //         //     path: "/m-pay",
      //         //     query: {
      //         //       userId: res.userId,
      //         //       orderNo: res.orderNo,
      //         //       courseId: this.$route.query.id,
      //         //     },
      //         //   });
      //         // }, 500);
      //         // }
      //       })
      //       .catch((err) => {
      //         this.isSubmit = false;
      //         loading.clear();
      //       });
    },
  },
};
</script>
<style scoped lang="less">
@font-face {
  font-family: 'PingFangSC-Regular, sans-serif';
  src: url("../assets/fonts/苹方黑体-准-简.ttf");
}
@font-face {
  font-family: 'PingFangSChei';
  src: url("../assets/fonts/苹方黑体-中黑-简.ttf");
}
.line {
  background: #d4d8df;
  width: 1.5px;
  height: 25px;
  position: relative;
  left: 200px;
  margin-top: -40px;
}
.sumbit {
  background: #ffffff;
  height: 62.5px;
  box-shadow: inset 0px 1px 0px 0px rgba(229, 232, 239, 1);
  width: 100vw;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
}
.phone{
  padding-left: 32px;
}
.btnpay {
  text-align: center;
  width: 125px;
  height: 45px;
  background: #fa9404;
  border-radius: 25px;
  color: #ffffff;
  font-size: 16px;
  line-height: 45px;
  font-weight: 500;
}
.heji {
  color: #7c8ea6;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.price {
  font-size: 30px;
  color: #ff7071;
}
.center {
  text-align: center;
}
.get-code {
  font-size: 14px;
  color: #ffb509;
  position: relative;
  display: block;
  right: -220px;
  top:-22px
  // margin-top: -68px;
}
.contry{
  font-size: 14px;
  width: 18px;
color:#A3AFC0;
position: relative;
left: 32px;
bottom: 38px;
height:0;
font-family:PingFangSChei ;
}
.card {
  
font-family: PingFangSC-Regular, PingFang SC;
  font-size: 14px;
  width: 335px;
  margin: 12px auto;
  background: #ffffff;
  box-shadow: 0px 8px 24px 2px rgba(84, 102, 143, 0.05);
  border-radius: 16px;
  margin-top: 0px;
}
.bannerimg {
  width: 300px;
  height: 100px;
  margin: 10px auto 10px;
}
.inpou {
  font-size: 14px;
  border-radius: 6.5px;
  border: 1px solid #d8dbe2;
  width: 300px;
  height: 40px;
  margin: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 16.5px;
}
.title {
  padding-top: 20px;
  margin-left: 17px;
  display: flex;
  font-size: 16px;
  color: #3e4f66;
  line-height: 1;
  font-weight: 600;
}
.titlebefore {
  width: 11px;
  height: 16px;
  margin-right: 12px;
  background: url(../assets/images/sewq_img_shopping_tab@2x.png);
  background-size: 100% 100%;
}
.page {
  background: #f7f8fb;
  font-size: 14px;
  padding-top: 20px;

  min-height: max(660px,100vh);
}
.info {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 0;
  font-size: 15px;
}
.money {
  color: #ff3a3b;
}
.ke {
  color: rgba(62, 79, 102, 1);
}
.col {
  padding: 15px 20px;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  color: rgba(62, 79, 102, 1);
  align-items: center;
}
.left {
  display: flex;
  line-height: 28px;
}
.icon {
  width: 28px;
  height: 28px;
  margin-right: 4px;
}
.bot {
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.userage {
  text-align: center;
  display: flex;
  font-size: 12px;
  text-align: center;
  justify-content: center;
}
.check-item {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid rgba(212, 216, 223, 1);
}
.isChecked {
  border: none;
  background: url(../assets/images/sewq_icon_shopping_selected@2x.png);
  background-size: 100% 100%;
}
</style>
