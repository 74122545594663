<template>
  <div class="home">
    <!-- 手机号弹出领取课程 -->
    <div class="home-banner" v-show="receive">
      <div class="mask">
        <div class="upsForm">

          <div class="home-banner-box">
            <img src="@/assets/images/srwq_web_gift_img@2x.png" alt="" class="home-banner-box-gift" />
            <img src="@/assets/images/srwq_pop_deleted_icon@2x.png" alt="" class="home-banner-box-off"
              @click="offReceive" />
            <div class="home-banner-box-title">
              免费领取新用户<br />专享围棋体验课
            </div>
            <div class="home-banner-box-form">
              <div class="home-banner-box-form-item">
                <div class="home-banner-box-form-item-select" @click="showTelList = true">
                  <span>+{{ form.code }}</span>
                  <img src="../assets/images/srwq_btn@2x.png" alt="" />
                </div>
                <input type="tel" placeholder="请输入手机号" v-model="phone" style="text-align: left"
                  @focus="handleInputFocus" />
                <span class="error">{{ error }}</span>
              </div>
              <div class="home-banner-box-form-item" v-if="ifShowCode">
                <input type="text" placeholder="请输入邀请码" v-model="InstitutionCode" style="border-radius: 46px"
                  @focus="handleInputFocus" />
              </div>
              <div class="home-banner-box-form-item">
                <div class="home-banner-box-form-item-btn" :class="!ifShowCode ? 'show' : 'off'" @click="getGift">
                  限时免费领取
                </div>
              </div>
              <div class="home-banner-box-form-item">
                <Checkbox v-model="form.agree">
                  <span class="home-banner-box-form-item-agree">点击即表示同意 《用户注册隐私协议》</span>
                  <template #icon="props">
                    <img class="img-icon" :src="rulerType ? check : nocheck"  @click="rulerType = !rulerType"/>
                  </template>
                </Checkbox>
              </div>
              <div class="tipsBox">
                <span class="home-banner-box-form-item-agree">注意：为能够确保领取成功，请确保提供准确的手机号码！</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <HomeItem1></HomeItem1>
    <HomeItem2></HomeItem2>
    <HomeItem3 style="display: none;"></HomeItem3>
    <HomeItem4 style="display: none;"></HomeItem4>
    <HomeItem5 @openPhone="getOpen"></HomeItem5>
    <Popup class="tellist" v-model="showTelList" position="bottom" round lock-scroll closeable
      :safe-area-inset-bottom="true" :close-on-click-overlay="false"
      :close-icon="require('../assets/images/srwq_pop_deleted_icon@2x.png')">
      <div class="pop-title">选择手机号所属国家/地区</div>
      <Picker :columns="codes" :default-index="0" @change="onChange"></Picker>
      <div class="picker-btn" @click="handleSelectChange">确定</div>
    </Popup>
    <Popup class="success-pop" v-model="showSuccess" lock-scroll closeable :safe-area-inset-bottom="true"
      :close-on-click-overlay="false" :close-icon="require('../assets/images/srwq_pop_deleted_icon@2x.png')">
      <div class="success">
        <div class="success-box">
          <img src="../assets/images/srwq_web_gift_img@2x.png" alt="" class="gift-box" />
          <img src="../assets/images/srwq_pop_monkey_img@2x.png" alt="" class="monkey" />
          <img src="../assets/images/srwq_star_pop_img@2x.png" alt="" class="star" />
          <p>恭喜你成功领取两次<br />Go Master双语围棋课</p>
          <i>－关注我们的公众号－</i>
          <img :src="qrcode" alt="" class="wx" />
          <b>3s快速约课</b>
          <div>
            请务必关注“GOMaster少儿围棋”公众号 添加班主任，获取课程信息
          </div>
        </div>
        <img src="../assets/images/srwq_pop_gogo_img@2x.png" alt="" class="panda" />
      </div>
    </Popup>
  </div>
</template>

<script>
import { code } from "../assets/js/code";
import { getQueryVariable } from "../assets/js/tool";
import { Checkbox, Toast, Popup, Picker } from "vant";
import check from "../assets/images/srwq_top_promise_selected_icon@2x.png";
import nocheck from "../assets/images/srwq_top_promise_normal_icon@2x.png";
import HomeItem1 from "@/components/homeitem1";
import HomeItem2 from "@/components/homeitem2";
import HomeItem3 from "@/components/homeitem3";
import HomeItem4 from "@/components/homeitem4";
import HomeItem5 from "@/components/homeitem5";
import http from 'axios'
export default {
  components: {
    Checkbox,
    Popup,
    Picker,
    HomeItem1,
    HomeItem2,
    HomeItem3,
    HomeItem4,
    HomeItem5,
  },
  name: "home",
  data() {
    return {
      //手机号参数
      receive: false,//领取弹出窗开关
      ifShowCode: true,//邀请码输入框
      rulerType: false,//是否同意用户协议
      phoneType: false,//手机号正则是否满足
      phone: '',//手机号
      InstitutionCode: '',//邀请码
      codeType: false,//是否成功领取
      InstitutionName: '快乐学习1',//机构名称
      receiveNumber: 100,//已领取人数
      receiveAllNumber: 200,//可领取人数

      // isCN:sessionStorage.getItem("isCN"),
      error: "",
      code,
      check,
      nocheck,
      qrcode: "",
      showTelList: false,
      showSuccess: false,

      icons: [
        {
          icon: require("../assets/images/srwq_top_introduce_online_img@2x.png"),
          num: "2节",
          txt: "AI数智人</br>围棋课",
        },
        {
          icon: require("../assets/images/srwq_top_teacher_img@2x.png"),
          num: "1次",
          txt: "专属课后</br>辅导",
        },
        {
          icon: require("../assets/images/srwq_top_plane_img@2x.png"),
          num: "1份",
          txt: "专属学习</br>计划",
        },
        {
          icon: require("../assets/images/srwq_top_training_img@2x.png"),
          num: "2000+",
          txt: "围棋闯关</br>训练",
        },
      ],
      form: {
        phonenum: "",
        age: "",
        info: "",
        agree: true,
        country: code[0].country,
        code: code[0].code,
      },
      activeIndex: 0,
    };
  },
  computed: {
    codes() {
      const codes = code;
      return codes.map((v) => {
        return `+${v.code} ${v.name}`;
      });
    },
  },
  onload(option) {
    // console.log("触发onload");
  },
  created() {
    var link = this.$route.query
    if (link) {
      this.InstitutionCode = this.$route.query.code;
      if (this.InstitutionCode) {
        this.receive = true
        this.ifShowCode = false;
      };
    }
  },
  mounted() {
    this.$http
      .get("/website/qrcode/" + (localStorage.getItem("channelId") || getQueryVariable("channelId") || "5964823168"))
      .then((res) => {
        this.qrcode = 'https://upimgtest.pookcdn.com/' + res;
      });
  },
  methods: {
    offReceive() {
      this.receive = false;
    },
    getOpen(data) {
      this.receive = data
    },
    getGift() {
      var that = this

      if (this.rulerType) {
        const ifPhone = this.isValidPhoneNumber(this.phone)

        if (ifPhone) {
          const ifCode = this.validatemechanismCode(this.InstitutionCode)

          if (ifCode) {
            // this.$http.post(`http://api.go-master.com/api/orders/verify?userPhone=${this.phone}&code=${this.InstitutionCode}`)
            this.$http.post(`http://api.go-master.com/api/orders/verify?userPhone=${this.form.code}/${this.phone}&code=${this.InstitutionCode}`)

              .then(response => {

                if (response.type == 'true') {
                  alert(response.tips);
                  that.receive = false;
                  that.phone = '';
                } else {
                  alert(response.tips);
                }

              })
              .catch(error => {
                alert("抱歉！领取失败请重试。")
              });
          } else {
            alert("请输入正确的邀请码！")
          }

        }
        else {
          alert("请输入正确的手机号！")
        }

      } else {
        alert("请勾选同意隐私协议！")
      }

    },
    isValidPhoneNumber(phoneNumber) {
      const pattern = /^1[3-9]\d{9}$/;
      if (!pattern.test(phoneNumber)) {
        return false;
      } else {
        return true;
      }
    },
    validatemechanismCode(value) {
      const regex = /^[a-zA-Z0-9]{15}$/;

      if (!regex.test(value)) {
        return false;
      } else {
        return true;
      }

    },
    handleInputFocus() {
      // document.querySelector("#app").scrollTop=400
    },
    onChange(picker, value, index) {
      this.activeIndex = index;
      this.error = "";
    },
    handleSelectChange() {
      const current = code[this.activeIndex];
      this.form = {
        ...this.form,
        country: current.country,
        code: current.code,
      };
      this.showTelList = false;
    },
    handleSubmit() {
      if (this.handleCheck()) {
        if (this.form.agree) {
          let tempForm = {
            phone: this.form.phonenum,
            code: this.form.code,
            country: this.form.country,
            wechatNumber: this.form.info,
            behaviour: 2,
            channel: localStorage.getItem("channelId") || "5964823168"
          };
          this.$http
            .post("/signIn", tempForm)
            .then((res) => {
              this.showSuccess = true;
            });
        } else {
          Toast("请先勾选按钮下方的协议");
        }
      }
    },
    handleCheck() {
      if (!this.form.phonenum) {
        this.error = "请输入手机号！";
        return false;
      } else if (isNaN(Number(this.form.phonenum))) {
        this.error = "手机号格式不正确";
        return false;
      } else {
        this.error = "";
        return true;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@keyframes mySwing {

  0%,
  10%,
  20%,
  30%,
  40%,
  50%,
  60%,
  70%,
  80%,
  90%,
  95% {
    transform: rotateZ(0);
  }

  95% {
    transform: rotateZ(15deg);
  }

  96% {
    transform: rotateZ(0deg);
  }

  97% {
    transform: rotateZ(-15deg);
  }

  98% {
    transform: rotateZ(0deg);
  }

  99% {
    transform: rotateZ(15deg);
  }

  to {
    transform: rotateZ(0);
  }
}

::v-deep.home {
  width: 100%;

  .van-overlay {
    z-index: 9999 !important;
  }

  .van-popup {
    z-index: 9999 !important;
    padding-bottom: 50px;

    .van-icon__image {
      width: 36px;
      height: 36px;
      transform: translateY(-4px);
    }

    .pop-title {
      height: 56px;
      font-size: 22px;
      font-family: PingFangTC, PingFangTC-Medium;
      font-weight: 500;
      text-align: center;
      color: #2fcdc6;
      line-height: 65px;
      margin-bottom: 10px;
    }

    .van-picker {
      margin-bottom: 10px;

      .van-picker-column__item {
        .van-ellipsis {
          opacity: 1;
          font-size: 14px;
          font-family: PingFangTC, PingFangTC-Regular;
          font-weight: 400;
          text-align: left;
          color: #98a4bd;
        }
      }

      .van-picker-column__item--selected {
        .van-ellipsis {
          font-size: 17px;
          font-family: PingFangTC, PingFangTC-Medium;
          font-weight: 500;
          text-align: left;
          color: #2fcdc6;
        }
      }
    }

    .picker-btn {
      width: 324px;
      height: 45px;
      margin: 0 auto;
      line-height: 48px;
      text-align: center;
      opacity: 1;
      background: #ffb243;
      border-radius: 23px;
      font-size: 20px;
      font-family: PingFangTC, PingFangTC-Medium;
      font-weight: 500;
      color: #ffffff;
    }

    &.success-pop {
      background: transparent;
      overflow: visible;

      .van-popup__close-icon--top-right {
        top: 100%;
        left: 50%;
        width: 36px;
        height: 36px;
        transform: translate(-50%, -20px);
      }

      .success {
        position: relative;

        img,
        i,
        b,
        span {
          display: block;
        }

        .success-box {
          width: 270px;
          height: 262px;
          background: #ffffff;
          border-radius: 29px;
          background-image: url("../assets/images/srwq_pop_bg@2x.png");
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: 100% 100%;
          position: relative;
          z-index: 10;

          .gift-box {
            width: 164px;
            height: 90px;
            position: absolute;
            top: -70px;
            left: 50%;
            transform: translateX(-50%);
          }

          .monkey {
            width: 80px;
            position: absolute;
            right: -55px;
            top: 20px;
          }

          .star {
            width: 15px;
            position: absolute;
            right: 30px;
            top: 15px;
          }

          p {
            font-size: 16px;
            font-family: PingFangTC, PingFangTC-Medium;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
            line-height: 20px;
            padding-top: 20px;
            margin-bottom: 23px;
          }

          i {
            font-style: normal;
            font-size: 14px;
            font-family: PingFangTC, PingFangTC-Regular;
            font-weight: 400;
            text-align: center;
            color: #2d2d2d;
          }

          .wx {
            width: 98px;
            margin: 0 auto;
          }

          b {
            font-size: 10px;
            font-family: PingFangSC-Regular, sans-serif;
            font-weight: 400;
            text-align: center;
            color: #514f6b;
            margin-bottom: 10px;
          }

          div {
            font-size: 10px;
            font-family: PingFangSC-Regular, sans-serif;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 15px;
            padding: 0 20px;
          }
        }

        .panda {
          width: 193px;
          height: 221px;
          position: absolute;
          left: -44px;
          top: -96px;
          z-index: 5;
        }
      }
    }
  }

  &-banner {
    width: 100%;
    min-height: 10.89rem;
    background: url("../assets/images/newBg.png") no-repeat;
    background-size: 100%;
    background-repeat: no-repeat;
    // background-position: 0 0;
    // background-size: cover;
    padding: 23px 17px 20px 17px;
    position: relative;

    &-top {
      width: 100%;
      padding-left: 13px;

      &-btn {
        width: 99px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        opacity: 1;
        background: #ffcb38;
        border-radius: 13px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, sans-serif;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 10px;
      }

      &-txt {
        font-size: 28px;
        font-family: CloudYuanCuGBK, CloudYuanCuGBK-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        line-height: 33px;
        letter-spacing: 1px;
        text-shadow: 0px 1px 4px #2f8480;
      }
    }

    &-box {
      width: 341px;
      height: 415px;
      opacity: 1;
      background-color: #ffffff;
      border-radius: 30px;
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09);
      position: absolute;
      bottom: 20px;
      left: 17px;
      padding: 0 25px;

      &-gift {
        display: block;
        width: 146px;
        position: absolute;
        top: -60px;
        left: 50%;
        margin-left: -73px;
        animation: mySwing 5s ease-in-out infinite;
      }

      &-title {
        font-size: 20px;
        font-family: PingFangTC, PingFangTC-Medium;
        font-weight: 500;
        text-align: center;
        color: #38d1c9;
        line-height: 24px;
        text-align: center;
        padding-top: 31px;
        margin-bottom: 12px;
      }

      &-icons {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 10px;

        li {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;

          img {
            display: block;
            width: 43px;
            margin-bottom: 4px;
          }

          span {
            display: block;
            text-align: center;
            font-size: 13px;
            font-family: PingFangTC, PingFangTC-Medium;
            font-weight: 500;
            text-align: center;
            color: #2fcdc6;
            margin-bottom: 8px;
          }

          p {
            font-size: 12px;
            font-family: PingFangTC, PingFangTC-Medium;
            font-weight: 500;
            text-align: center;
            color: #f5960c;
            line-height: 17px;
            position: relative;

            &::after {
              content: "";
              width: 1px;
              height: 28px;
              display: block;
              position: absolute;
              right: -16px;
              top: 3px;
              background: #e5e8f0;
            }
          }

          &:nth-last-of-type(1) {
            p {
              &::after {
                display: none;
              }
            }
          }
        }
      }

      &-form {
        width: 100%;

        &-item {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;
          position: relative;

          &-select {
            width: 77px;
            height: 45px;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-end;
            background: rgba(154, 164, 188, 0.2);
            border-radius: 45px 0 0 45px;

            span {
              display: block;
              text-align: center;
              line-height: 45px;
              font-size: 16px;
              font-family: PingFangSC-Regular, sans-serif;
              color: #9aa4bc;
            }

            img {
              display: block;
              width: 28px;
              margin-right: 3px;
              margin-top: -2px;
            }
          }

          .error {
            display: block;
            width: 100%;
            position: absolute;
            font-size: 10px;
            font-family: PingFangTC, PingFangTC-Regular;
            font-weight: 400;
            text-align: left;
            color: #eb3414;
            bottom: -16px;
            left: 20px;
          }

          input {
            flex: 1;
            width: 0;
            height: 45px;
            border: none;
            font-size: 14px;
            font-family: PingFangTC, PingFangTC-Medium;
            font-weight: 500;
            text-align: left;
            color: #0d2a66;
            border-radius: 0 45px 45px 0;
            background: #f4f4f7;
            padding-left: 10px;
            text-align: center;

            &::placeholder {
              color: #9aa4bc;
            }
          }

          &-btn {
            width: 100%;
            height: 40px;
            background: #ffb243;
            border-radius: 40px;
            text-align: center;
            line-height: 45px;
            font-size: 20px;
            font-family: PingFangTC, PingFangTC-Medium;
            font-weight: 500;
            color: #ffffff;
          }

          &-agree {
            font-size: 9px;
            font-family: PingFangSC-Regular, sans-serif;
            font-weight: 400;
            text-align: left;
            color: #9aa4bb;
          }

          .van-checkbox {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;

            .van-checkbox__label {
              vertical-align: middle;
            }
          }

          .img-icon {
            width: 18px;
            transform: translateY(3px);
          }

          &:not(:nth-last-of-type(2)) {
            margin-bottom: 18px;
          }
        }
      }
    }
  }
}




//这是手机号弹出框
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.upsForm {
  margin: 0 auto;
  position: relative;
  top: 600px;
}

.home-banner-box-form {
  // margin-top: 70px;
  margin-top: 30px;

}

.show {
  margin-top: 60px;
}
.off {
  margin-top: 10px;
}

.home-banner-box-off {
  width: 40px;
  height: 40px;
  position: relative;
  top: 10px;
  right: -265px;
}

.tipsBox {
  position: absolute;
  bottom: 2vh;
  left: 15vw;
}

.whitelogin {
  width: 80%;
  height: 11rem;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
}

.receiveBox {
  width: 80%;
  margin: auto;
}

.receiveTitle {
  width: 90%;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 22px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #666666;
  font-weight: 900;
}

.phoneInput {
  width: 99%;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* padding: 20px; */
  background-color: #F4F4F7;
  border-radius: 30px;
  // margin-top: 50px;
}

.areaCode {
  width: 30%;
  height: 100%;
  background-color: #E3E5EC;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9AA4BC;
  font-size: 17px;
  font-weight: 900;
}

.areaPhone {
  width: 70%;
  height: 100%;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
}

.areaPhone>input {
  width: 80%;
  height: 60%;
  margin: auto;
  outline: none;
  background-color: transparent;
  display: flex;
  border: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 17px;

}

.areaPhone>input::placeholder {
  color: #9AA4BC;
}

.receiveBtn {
  width: 99%;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 45px;
  background-color: #FFB243;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  font-weight: 900;
  margin-top: 40px;
}

.receiveRuler {
  width: 99%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}

.choosePics {
  width: 17px;
  height: 20px;
  position: relative;
}

.choosePics>img {
  width: 100%;
  height: 100%;
  position: absolute;
}

.rulerText {
  /* width: 70%; */
  font-size: 12px;
  color: #9AA4BC;
  font-weight: 900;
}

.close {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.close>img {
  width: 100%;
  height: 100%;
}

.receiveMsg {
  width: 99%;
  margin: auto;
  font-size: 20px;
  margin-bottom: 30px;
}

.receiveNum {
  width: 99%;
  margin: auto;
  font-size: 20px;
}

.hasCode {
  margin-bottom: 30px;

}

.noCode {
  margin-top: 60px;

}
</style>
