<template>
  <div class="dou">
    <Dou1 v-if="$route.params.id == 1"></Dou1>
    <Dou2 v-if="$route.params.id == 2"></Dou2>
    <Popup v-model="showDialog" lock-scroll :close-on-click-overlay="false">
      <div class="gift-box">
        <img src="@/assets/images/dou/sewq_pop_img@2x.png" alt="" class="gift-box-bg">
        <img src="@/assets/images/dou/sewq_pop_gift_img@2x.png" alt="" class="gift-box-b">
        <img src="@/assets/images/dou/sewq_pop_deleted_btn@2x.png" alt="" class="gift-box-close" @click="showDialog=false">
      </div>
    </Popup>
  </div>
</template>

<script>
import { getQueryVariable } from "../assets/js/tool";
import Dou1 from "@/components/douyin/dou-1";
import Dou2 from "@/components/douyin/dou-2";
import { Popup } from "vant"; 
export default {
  components: { Dou1, Dou2, Popup },
  name: "Dou",
  data() {
    return {
      showDialog: false,
    };
  },
  mounted() {
    if (getQueryVariable("adid")||getQueryVariable("clickid")) {
      const link = encodeURIComponent(window.location.href);
      sessionStorage.setItem("link", JSON.stringify(link));
    }
    const _this = this;
    history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function () {
      _this.showDialog = true;
    });
  },
  methods: {},
};
</script>
<style lang="less" scoped>
::v-deep.van-popup--center{
    background: transparent;
    top: 40%;
    overflow: visible;
.gift-box{
    width: 272px;
    background: transparent;
    position: relative;
    img{
      display: block;

    }
    &-bg{
      width: 100%;
    }
    &-b{
      width: 169px;
      position: absolute;
      left: -30px;
      bottom: 50px;
      animation: mySwing 4s linear infinite;
    }
    &-close{
      width: 44px;
      position: absolute;
      bottom: -60px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
  
</style>
