<template>
  <div class="home">
    <div class="home-banner">
      <div class="home-banner-top">
        <!-- <div class="home-banner-top-btn">{{isCN?'7-12':'4-12'}}岁</div> -->
        <div class="home-banner-top-txt">在线双语少儿<br/>围棋教育平台</div>
      </div>
      <div class="home-banner-box" style="display: none;">
        <img
            src="@/assets/images/srwq_web_gift_img@2x.png"
            alt=""
            class="home-banner-box-gift"
        />
        <div class="home-banner-box-title">
          扫码免费领<br/>198元数智人围棋课<br/>完课后还可获98元高级围棋礼盒
        </div>
        <ul class="home-banner-box-icons">
          <li v-for="(item, index) in icons" :key="index">
            <img :src="item.icon" alt=""/>
            <span>{{ item.num }}</span>
            <p v-html="item.txt"></p>
          </li>
        </ul>
        <div class="home-banner-box-form">
          <div class="home-banner-box-form-item">
            <div
                class="home-banner-box-form-item-select"
            >
              <span>+{{ form.code }}</span>
              <img src="../assets/images/srwq_btn@2x.png" alt=""/>
            </div>
            <input
                type="tel"
                placeholder="请输入手机号"
                v-model="form.phone"
                style="text-align: left"
                @focus="handleInputFocus"
            />
            <span class="error">{{ error }}</span>
          </div>
          <div class="home-banner-box-form-item smsCode">
            <Field
                v-model="form.verifyCode"
                center
                clearable

                placeholder="请输入短信验证码"
            >
              <template #button>
                <Button size="small" type="primary" @click="sendSms">{{ smsBtn.can ? '发送验证码' : `${smsBtn.time}秒` }}
                </Button>
              </template>
            </Field>
          </div>
          <div class="home-banner-box-form-item">
            <input
                type="text"
                placeholder="请输入孩子姓名"
                v-model="form.name"
                style="border-radius: 46px"
                @focus="handleInputFocus"
            />
            <span class="error">{{ error2 }}</span>
          </div>
          <div class="home-banner-box-form-item">
            <div
                class="home-banner-box-form-item-select age"
                @click="showTelList = true"
            >
              <span>{{ form.age }}岁</span>
              <img src="../assets/images/srwq_btn@2x.png" alt=""/>
            </div>
          </div>
          <div class="home-banner-box-form-item">
            <div class="home-banner-box-form-item-btn" @click="handleSubmit">
              免费报名
            </div>
          </div>
          <div class="home-banner-box-form-item">
            <Checkbox v-model="form.agree">
              <span class="home-banner-box-form-item-agree"
              >Go Master保障每位用户个人信息不泄露</span
              >
              <template #icon="props">
                <img class="img-icon" :src="props.checked ? check : nocheck"/>
              </template>
            </Checkbox>
          </div>
        </div>
      </div>
    </div>
    <HomeItem1></HomeItem1>
    <HomeItem2></HomeItem2>
    <!-- <HomeItem3></HomeItem3>
    <HomeItem4></HomeItem4> -->
    <HomeItem5></HomeItem5>
    <Popup
        class="tellist"
        v-model="showTelList"
        position="bottom"
        round
        lock-scroll
        closeable
        :safe-area-inset-bottom="true"
        :close-on-click-overlay="false"
        :close-icon="require('../assets/images/srwq_pop_deleted_icon@2x.png')"
    >
      <div class="pop-title">选择孩子的年龄</div>
      <Picker :columns="codes" :default-index="3" @change="onChange"></Picker>
      <div class="picker-btn" @click="handleSelectChange">确定</div>
    </Popup>
    <Popup
        class="success-pop"
        v-model="showSuccess"
        lock-scroll
        closeable
        :safe-area-inset-bottom="true"
        :close-on-click-overlay="false"
        :close-icon="require('../assets/images/srwq_pop_deleted_icon@2x.png')"
    >
      <div class="success">
        <div class="success-box">
          <img
              src="../assets/images/srwq_web_gift_img@2x.png"
              alt=""
              class="gift-box"
          />
          <img
              src="../assets/images/srwq_pop_monkey_img@2x.png"
              alt=""
              class="monkey"
          />
          <img
              src="../assets/images/srwq_star_pop_img@2x.png"
              alt=""
              class="star"
          />
          <p>恭喜你免费领取成功<br/>Go Master双语围棋直播课</p>
          <i>－领取成功－</i>
          <i>近期会有来自上海的玖连星课程顾问电话联系您安排课程，请注意接听！</i>
<!--          <img-->
<!--              :src="qrcode"-->
<!--              alt=""-->
<!--              class="wx"-->
<!--          />-->
<!--          <b>3s快速约课</b>-->
<!--          <div>-->
<!--            请务必关注“GOMaster双语少儿围棋”公众号 添加班主任，获取课程信息-->
<!--          </div>-->
        </div>
        <img
            src="../assets/images/srwq_pop_gogo_img@2x.png"
            alt=""
            class="panda"
        />
      </div>
    </Popup>
  </div>
</template>

<script>
import {code} from "../assets/js/code";
import {getQueryVariable} from "../assets/js/tool";
import {Checkbox, Toast, Popup, Picker, Field, Button} from "vant";
import check from "../assets/images/srwq_top_promise_selected_icon@2x.png";
import nocheck from "../assets/images/srwq_top_promise_normal_icon@2x.png";
import HomeItem1 from "@/components/homeitem1";
import HomeItem2 from "@/components/homeitem2";
// import HomeItem3 from "@/components/homeitem3";
// import HomeItem4 from "@/components/homeitem4";
// import HomeItem5 from "@/components/homeitem5";

export default {
  components: {
    Checkbox,
    Popup,
    Picker,
    HomeItem1,
    HomeItem2,
    // HomeItem3,
    // HomeItem4,
    // HomeItem5,
    Field,
    Button
  },
  name: "promotion",
  data() {
    return {
      // isCN:sessionStorage.getItem("isCN"),
      error: "",
      error2: "",
      code,
      check,
      nocheck,
      qrcode: require('@/assets/images/ground_push_code.png'),
      showTelList: false,
      showSuccess: false,
      icons: [
        {
          icon: require("../assets/images/srwq_top_introduce_online_img@2x.png"),
          num: "2节",
          txt: "在线</br>围棋课",
        },
        {
          icon: require("../assets/images/srwq_top_teacher_img@2x.png"),
          num: "1次",
          txt: "专属课后</br>辅导",
        },
        {
          icon: require("../assets/images/srwq_top_plane_img@2x.png"),
          num: "1份",
          txt: "专属学习</br>计划",
        },
        {
          icon: require("../assets/images/srwq_top_training_img@2x.png"),
          num: "2000+",
          txt: "围棋闯关</br>训练",
        },
      ],
      form: {
        phone: "",
        age: "7",
        info: "",
        agree: true,
        // country: code[0].country,
        code: code[0].code,
        verifyCode: ''
      },
      activeIndex: 0,
      ages: [4, 5, 6, 7, 8, 9, 10],
      smsBtn: {
        can: true,
        time: 60
      },
      timer: '',
      phoneREG: /^1\d{10}$/
    };
  },
  computed: {
    codes() {
      const codes = this.ages;
      return codes.map((v) => {
        return `${v}岁`;
      });
    },
  },
  mounted() {
  },
  methods: {
    sendSms() {
      if (!this.form.phone) {
        this.error = "请输入手机号！";
        return false;
      } else if (!(this.phoneREG.test(this.form.phone))) {
        this.error = "手机号格式不正确";
        return false;
      }
      const tempForm = {
        code: this.form.code,
        mobile: this.form.phone
      }
      if (this.smsBtn.can) {
        this.$http
            .post("/sms/send", tempForm)
            .then((res) => {
              console.log(res)
              this.smsBtn.can = false
              this.timer = setInterval(() => {
                if (this.smsBtn.time > 0) {
                  this.smsBtn.time--
                } else {
                  clearInterval(this.timer)
                  this.smsBtn.can = true
                  this.smsBtn.time = 60
                }

              }, 1000)
            });
      }
    },
    handleInputFocus() {
      document.querySelector("#app").scrollTop = 300
    },
    onChange(picker, value, index) {
      this.activeIndex = index;
      this.error = "";
    },
    handleSelectChange() {
      const current = this.ages[this.activeIndex];
      this.form = {
        ...this.form,
        age: current,
      };
      this.showTelList = false;
    },
    handleSubmit() {
      if (this.handleCheck()) {
        if (this.form.agree) {
          let tempForm = {
            ...this.form,
            channel: localStorage.getItem("channelId") || "5964823168"
          };
          console.log(tempForm)
          this.$http
              .post("/signIn/child", tempForm)
              .then((res) => {
                this.showSuccess = true;
              });
        } else {
          Toast("请先勾选按钮下方的协议");
        }
      }
    },
    handleCheck() {
      if (!this.form.phone) {
        this.error = "请输入手机号！";
        return false;
      } else if (!(this.phoneREG.test(this.form.phone))) {
        this.error = "手机号格式不正确";
        return false;
      } else if (!this.form.name) {
        this.error = "";
        this.error2 = "请输入孩子姓名";
        return false;
      } else {
        this.error = "";
        this.error2 = "";
        return true;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@keyframes mySwing {
  0%,
  10%,
  20%,
  30%,
  40%,
  50%,
  60%,
  70%,
  80%,
  90%,
  95% {
    transform: rotateZ(0);
  }
  95% {
    transform: rotateZ(15deg);
  }
  96% {
    transform: rotateZ(0deg);
  }
  97% {
    transform: rotateZ(-15deg);
  }
  98% {
    transform: rotateZ(0deg);
  }
  99% {
    transform: rotateZ(15deg);
  }
  to {
    transform: rotateZ(0);
  }
}

::v-deep.home {
  width: 100%;

  .van-overlay {
    z-index: 9999 !important;
  }

  .van-popup {
    z-index: 9999 !important;
    padding-bottom: 50px;

    .van-icon__image {
      width: 36px;
      height: 36px;
      transform: translateY(-4px);
    }

    .pop-title {
      height: 56px;
      font-size: 22px;
      font-family: PingFangTC, PingFangTC-Medium;
      font-weight: 500;
      text-align: center;
      color: #2fcdc6;
      line-height: 65px;
      margin-bottom: 10px;
    }

    .van-picker {
      margin-bottom: 10px;

      .van-picker-column__item {
        .van-ellipsis {
          opacity: 1;
          font-size: 14px;
          font-family: PingFangTC, PingFangTC-Regular;
          font-weight: 400;
          text-align: left;
          color: #98a4bd;
        }
      }

      .van-picker-column__item--selected {
        .van-ellipsis {
          font-size: 17px;
          font-family: PingFangTC, PingFangTC-Medium;
          font-weight: 500;
          text-align: left;
          color: #2fcdc6;
        }
      }
    }

    .picker-btn {
      width: 324px;
      height: 45px;
      margin: 0 auto;
      line-height: 48px;
      text-align: center;
      opacity: 1;
      background: #ffb243;
      border-radius: 23px;
      font-size: 20px;
      font-family: PingFangTC, PingFangTC-Medium;
      font-weight: 500;
      color: #ffffff;
    }

    &.success-pop {
      background: transparent;
      overflow: visible;

      .van-popup__close-icon--top-right {
        top: 100%;
        left: 50%;
        width: 36px;
        height: 36px;
        transform: translate(-50%, -20px);
      }

      .success {
        position: relative;

        img,
        i,
        b,
        span {
          display: block;
        }

        .success-box {
          width: 270px;
          height: 262px;
          background: #ffffff;
          border-radius: 29px;
          background-image: url("../assets/images/srwq_pop_bg@2x.png");
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: 100% 100%;
          position: relative;
          z-index: 10;

          .gift-box {
            width: 164px;
            height: 90px;
            position: absolute;
            top: -70px;
            left: 50%;
            transform: translateX(-50%);
          }

          .monkey {
            width: 80px;
            position: absolute;
            right: -55px;
            top: 20px;
          }

          .star {
            width: 15px;
            position: absolute;
            right: 30px;
            top: 15px;
          }

          p {
            font-size: 16px;
            font-family: PingFangTC, PingFangTC-Medium;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
            line-height: 20px;
            padding-top: 20px;
            margin-bottom: 23px;
          }

          i {
            display: block;
            margin: 20px auto;
            line-height: 28px;
            width: 90%;
            font-style: normal;
            font-size: 14px;
            font-family: PingFangTC, PingFangTC-Regular;
            font-weight: 400;
            text-align: center;
            color: #2d2d2d;
          }

          .wx {
            width: 98px;
            margin: 0 auto;
          }

          b {
            font-size: 10px;
            font-family: PingFangSC-Regular, sans-serif;
            font-weight: 400;
            text-align: center;
            color: #514f6b;
            margin-bottom: 10px;
          }

          div {
            font-size: 10px;
            font-family: PingFangSC-Regular, sans-serif;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 15px;
            padding: 0 20px;
          }
        }

        .panda {
          width: 193px;
          height: 221px;
          position: absolute;
          left: -44px;
          top: -96px;
          z-index: 5;
        }
      }
    }
  }

  &-banner {
    width: 100%;
    min-height: 633px;
    background: url("../assets/images/srwq_top_bg_img@2x.png") no-repeat;
    background-position: 0 0;
    background-size: cover;
    padding: 23px 17px 20px 17px;
    position: relative;

    &-top {
      width: 100%;
      padding-left: 13px;

      &-btn {
        width: 99px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        opacity: 1;
        background: #ffcb38;
        border-radius: 13px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, sans-serif;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 10px;
      }

      &-txt {
        font-size: 28px;
        font-family: CloudYuanCuGBK, CloudYuanCuGBK-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        line-height: 33px;
        letter-spacing: 1px;
        text-shadow: 0px 1px 4px #2f8480;
      }
    }

    &-box {
      width: 341px;
      //height: 415px;
      opacity: 1;
      background-color: #ffffff;
      border-radius: 30px;
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09);
      position: absolute;
      bottom: 20px;
      left: 17px;
      padding: 0 25px;

      &-gift {
        display: block;
        width: 146px;
        position: absolute;
        top: -60px;
        left: 50%;
        margin-left: -73px;
        animation: mySwing 5s ease-in-out infinite;
      }

      &-title {
        font-size: 20px;
        font-family: PingFangTC, PingFangTC-Medium;
        font-weight: 500;
        text-align: center;
        color: #38d1c9;
        line-height: 24px;
        text-align: center;
        padding-top: 31px;
        margin-bottom: 12px;
      }

      &-icons {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 10px;

        li {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;

          img {
            display: block;
            width: 43px;
            margin-bottom: 4px;
          }

          span {
            display: block;
            text-align: center;
            font-size: 13px;
            font-family: PingFangTC, PingFangTC-Medium;
            font-weight: 500;
            text-align: center;
            color: #2fcdc6;
            margin-bottom: 8px;
          }

          p {
            font-size: 12px;
            font-family: PingFangTC, PingFangTC-Medium;
            font-weight: 500;
            text-align: center;
            color: #f5960c;
            line-height: 17px;
            position: relative;

            &::after {
              content: "";
              width: 1px;
              height: 28px;
              display: block;
              position: absolute;
              right: -16px;
              top: 3px;
              background: #e5e8f0;
            }
          }

          &:nth-last-of-type(1) {
            p {
              &::after {
                display: none;
              }
            }
          }
        }
      }

      &-form {
        width: 100%;

        &-item {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;
          position: relative;

          .van-cell {
            padding: 0;
          }

          &.smsCode {
            input {
              text-align: center;
              border-radius: 45px 0 0 45px;
            }

            .van-field__button {
              padding: 0;

              .van-button--small {
                height: 45px;
                width: 77px;
                border-radius: 0 45px 45px 0;
                background: #9aa4bc33;
                color: #9aa4bc;
                border: none;
              }
            }
          }

          &-select {
            width: 77px;
            height: 45px;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            background: rgba(154, 164, 188, 0.2);
            border-radius: 45px 0 0 45px;

            &.age {
              width: 100%;
              border-radius: 45px;
              display: inline-block;

              span {
                display: inline-block;
                width: 100%;
                position: relative;
              }

              img {
                display: inline-block;
                position: absolute;
                width: 38px;
                right: 0;
                top: 5px;
              }

            }

            span {
              display: block;
              text-align: center;
              line-height: 45px;
              font-size: 16px;
              font-family: PingFangSC-Regular, sans-serif;
              color: #9aa4bc;
            }

            img {
              display: block;
              width: 0;
              margin-right: 3px;
              margin-top: -2px;
            }
          }

          .error {
            display: block;
            width: 100%;
            position: absolute;
            font-size: 10px;
            font-family: PingFangTC, PingFangTC-Regular;
            font-weight: 400;
            text-align: left;
            color: #eb3414;
            bottom: -16px;
            left: 20px;
          }

          input {
            flex: 1;
            width: 0;
            height: 45px;
            border: none;
            font-size: 14px;
            font-family: PingFangTC, PingFangTC-Medium;
            font-weight: 500;
            text-align: left;
            color: #0d2a66;
            border-radius: 0 45px 45px 0;
            background: #f4f4f7;
            padding-left: 10px;
            text-align: center;

            &::placeholder {
              color: #9aa4bc;
            }
          }

          &-btn {
            width: 100%;
            height: 40px;
            background: #ffb243;
            border-radius: 40px;
            text-align: center;
            line-height: 45px;
            font-size: 20px;
            font-family: PingFangTC, PingFangTC-Medium;
            font-weight: 500;
            color: #ffffff;
          }

          &-agree {
            font-size: 9px;
            font-family: PingFangSC-Regular, sans-serif;
            font-weight: 400;
            text-align: left;
            color: #9aa4bb;
          }

          .van-checkbox {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;

            .van-checkbox__label {
              vertical-align: middle;
            }
          }

          .img-icon {
            width: 18px;
            transform: translateY(3px);
          }

          &:not(:nth-last-of-type(2)) {
            margin-bottom: 18px;
          }
        }
      }
    }
  }
}
</style>
