import { render, staticRenderFns } from "./courseIndexB.vue?vue&type=template&id=10eb5767&scoped=true"
import script from "./courseIndexB.vue?vue&type=script&lang=js"
export * from "./courseIndexB.vue?vue&type=script&lang=js"
import style0 from "./courseIndexB.vue?vue&type=style&index=0&id=10eb5767&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10eb5767",
  null
  
)

export default component.exports