<template>
	<div class="download">
		<div class="WX_guideBg" v-if="show">
			<div class="wx_to">
				<img src="../assets/images/douyin/jt.png" alt="" />
			</div>
			<div class="wx_tishi">
				<img src="../assets/images/douyin/note.png" alt="" />
			</div>
		</div>

		<div class="download_top">
			<img src="../assets/images/douyin/title.png" alt="" />
		</div>

		<div class="download_bottom">

		</div>

		<div class="download_btn">
			<div style="display: flex;margin-left: 80rpx; align-items: center;">
				<a :showUnderLine='false' :href="routerUrl" target="_blank">
					<div style="height: 0rpx; width: 600rpx; display: flex;  align-items: center;">
						<img style="width: 70vw;height: 150rpx; margin-left: 25px;"
							src="../assets/images/douyin/xiazai.png" mode="aspectFit" alt="" />
					</div>
				</a>
			</div>
		</div>

		<div style="margin-top: -190px;">
			<div class="APPintroduce">
				<div class="titliBox">
					<p>软件介绍</p>
				</div>
				<div class="introduceA">
					<p>
						《GoMaster棋妙围棋》是专为围棋启蒙而设计的教育软件，融合了尖端的围棋人工智能技术，以家长与孩子共同成长为核心理念，提供高效而专业的围棋课程体验。
					</p>
					<p>
						在AI数字人围棋导师引导下，在可爱的卡通角色陪伴下，孩子们自主探索奇妙的围棋，随着围棋技能提升不断获得成就感，每一位孩子都将成为自己小小世界里的赢家。
					</p>
					<p>
						对于初识围棋的爸爸妈妈们，在AI围棋导师的引领下，可以陪伴孩子一起体验围棋的奇妙，享受亲子共学的乐趣。
					</p>
				</div>
			</div>

			<div class="featureintroduce">
				<div class="titliBoxB">
					<p>特色介绍</p>
				</div>
				<div class="introduceB">
					<div class="contentBox">
						<p>1. 精美课程</p>
						<p>人工智能和专业教研团队，精心打造的启蒙课程；</p>
					</div>
					<div class="contentBox">
						<p>2. 趣味学习</p>
						<p>AI对弈、AI棋力评测、闯关模式……游戏化互动激励探索式学习；</p>
					</div>
					<div class="contentBox">
						<p>3. 素质教育</p>
						<p>训练孩子提升专注力、计算力、记忆力、决策力，全方位提升综合素质；</p>
					</div>
					<div class="contentBox">
						<p>4. 亲子模式</p>
						<p>创新的亲子围棋模式，鼓励家长和孩子共同学习，增强亲子间的互动和情感联结；</p>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>



<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
	name: 'app',
	components: {
		// HelloWorld
	},
	data() {
		return {
			routerUrl: 'https://api.go-master.com/api/download/android',
			isAndroid: true,
			topNavIndex: 0,
			topNavArr: [''],
			pageScrollTop: 0, // 页面滚动距离
			download: 1700,
			score: "4.5",
			show: false,
			activeIndex: 0, // 当前激活的导航索引
			showhead: true,
			navItems: [{
				name: '首页',
				route: 0
			},
			{
				name: '下载',
				route: 1
			},
				// 添加更多的导航项
			]
		}
	},
	onLoad(option) {
		// if(option.isdownload != undefined) this.showhead = false
	},
	mounted() {
		this.isAndroidC();
	},
	methods: {
		downloads() {

			console.log("现在下载");
		},
		isAndroidC() {
			console.log("触发平台判断");
			var u = navigator.userAgent;
			var isAndroid1 = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //安卓
			var isIOS1 = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
			var ua = u.toLowerCase();
			if (ua.match(/MicroMessenger/i) == "micromessenger") {
				this.show = true;
			}
			if (isAndroid1) {
				//这个是安卓系统
				console.log(isAndroid1)
				this.isAndroid = true
				this.routerUrl = 'https://api.go-master.com/api/download/android'
			}
			if (isIOS1) {
				//这个是ios系统
				this.isAndroid = false
				this.routerUrl = 'https://apps.apple.com/cn/app/go-master-%E5%9C%A8%E7%BA%BF%E5%8F%8C%E8%AF%AD%E5%9B%B4%E6%A3%8B/id1566542127'
			}

		},
	}
}
</script>


<style scoped lang="less">
* {
	padding: 0;
	margin: 0;
}

.download {
	width: 100vw;
	height: 93vh;
	/* height: 100%; */
	position: relative;
	display: flex;
	flex-wrap: wrap;
	/* background-color: #7244ff; */
}

/* .download{
		width: 100vw;
		height: 100vh;
		position: relative;
		display: flex;  
		flex-wrap: wrap;  
		background-color: #7244ff;
	} */
.download_top {
	flex: 1 0 100%;
	height: 35%;
	margin: auto;
	position: relative;
	background-color: #ffffff;
}

.download_top>img {
	width: 50vw;
	height: 50vw;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.download_bottom {
	flex: 1 0 100%;
	// height: 50%;
	height: 400px;
	margin: auto;
	background-image: url(../assets/images/douyin/newBg.png);
	background-color: #7244ff;
	background-repeat: no-repeat;
	background-size: 100%;
	position: relative;
}

.download_btn {
	width: 100vw;
	background-color: #7244ff;
	position: relative;
	top:-100px;

}

.download_btn img {
	width: 100%;
	height: 100%;
}

.WX_guideBg {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;
}

.wx_to {
	width: 25vw;
	height: 25vw;
	position: absolute;
	right: 5vw;
	top: 5vw;
}

.wx_to>img {
	width: 100%;
	height: 100%;
}

.wx_tishi {
	width: 80vw;
	height: 40vh;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.wx_tishi>img {
	width: 100%;
	height: 100%;
}

.titliBox {
	font-size: 24px;
	/* color: #212121; */
	color: #ffffff;

	line-height: 24px;
	padding-left: 10px;
	border-left: 4px solid #19d4c4;
	margin: 41px 0px 30px 25px;
	text-align: left;
}

.introduceA {
	margin: 0px 25px;

	P {
		font-size: 18px;
		/* color: rgba(0, 0, 0, 0.65); */
		color: #ffffff;

		line-height: 24px;
		text-align: left;
	}

	& p:first-child {
		margin-bottom: 24px;
	}

	& p:nth-child(2) {
		margin-bottom: 24px;
	}
}

.introduceB {
	margin: 0px 25px;

	P {
		font-size: 18px;
		color: #ffffff;
		line-height: 24px;
		text-align: left;
	}


	.contentBox :nth-child(1) {
		margin-bottom: 10px;
	}

	.contentBox :nth-child(2) {
		margin-bottom: 20px;
	}

}

.titliBoxB {
	font-size: 24px;
	color: #ffffff;
	line-height: 24px;
	padding-left: 10px;
	border-left: 4px solid #19d4c4;
	margin: 60px 0px 30px 25px;
	text-align: left;
}

.featureintroduce {
	margin-top: -65px;
	background-color: #7244ff;
	padding-bottom: 30px;

}

.APPintroduce {
	padding-top: 120px;
	padding-bottom: 50px;
	background-color: #7244ff;

}
</style>