<template>
  <div class="container" ref="container">
    <div class="bao">
      <div class="inputBox">
        <input class="shangchuang" type="file" @change="handleFileUpload">
      </div>
    </div>

    <div class="anniuBox">
      <img class="anniu" src="../assets/images/agent/anniutu.png" alt="上传按钮" @click="PushData()">
    </div>
    <div class="imgBox" v-show="base64Image">
      <img class="img" :src="base64Image">
    </div>

    <div class="shaodeng" v-show="shaodengVal">
      <div class="imgBoxaa">
        <img src="../assets/images/agent/jiazai.gif" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'index_page',
  data() {
    return {
      selectedImage: null,
      base64Image: null,
      user_id: null,
      invitation_code: null,
      shaodengVal:false
    }
  },
  created() {

  },
  mounted() {
    this.user_id = JSON.parse(sessionStorage.getItem('userinfo')).user_id;
    this.invitation_code = JSON.parse(sessionStorage.getItem('userinfo')).invitation_code;
  },
  beforeDestroy() {

  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.selectedImage = file;
          this.base64Image = reader.result;
          // console.log('图片信息', this.base64Image)
        };
      }
    },
    PushData() {
      var thisc = this;
      if (!this.base64Image) {
        alert('请选择收款码！')
        return;
      }
      thisc.shaodengVal = true;
      axios.post('https://teach-api.go-master.com/v4/Gomaster/changeImg', {
        user_id: this.user_id,
        img: this.base64Image,
        invitation_code: this.invitation_code,
      })
        .then(function (response) {
          thisc.shaodengVal = false;
          sessionStorage.setItem('payment_code', 'true');
          alert('收款码上传成功,请重新发起提现！');
          thisc.$router.push('/m-course/Withdrawal');
        })
        .catch(function (error) {
          thisc.shaodengVal = false;
          alert('收款码上传失败,请重新上传！');
          console.error('Error fetching user list:', error);
        });
    }
  }
}
</script>

<style scoped>
.shangchuang {
  width: 200vw;
  height: 40vh;
  /* border: 1px solid red; */
  font-size: 0;
  position: relative;
  left: -200px;
}

.container {
  overflow: hidden;
  width: 100vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 0;
}
.bao{
  width: 370px;
  /* height: 600px; */
}
.inputBox {
  width: 350px;
  height: 420px;
  background-image: url(../assets/images/agent/shangchuagn.png);
  background-size: 100% 100%;
  margin: 0 10px;
}
.anniuBox{
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.anniu {
  width: 290px;
  height: 60px;
  margin:auto;
  position: absolute;
  top: 350px;
}

.imgBox {
  position: absolute;
  top: 100px;
  width: 170px;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  width: 100%;
  height: 100%;
}
.shaodeng{
  background-color: rgba(0, 0, 0, 0.5);
		z-index: 99999;
    width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		display: flex;
		flex-direction: column;
		align-items: center;
}
.imgBoxaa{
  width: 150px;
  height: 150px;
  position: absolute;
  top: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>