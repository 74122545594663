<template>
  <div class="m-main" >
    <MHeader ref="mheader" v-if="!$route.meta.hideHeader"></MHeader>
    <div  id="main-content"  v-if="!$route.meta.hideHeader" :class="$route.meta.hideTopAndBottom?'content-nopadding':'content'">
      <slot></slot>
    </div>
    <div  id="main-content" v-else>
      <slot></slot>
    </div>
    <MFooter></MFooter>
  </div>
</template>

<script>
import MFooter from "./footer.vue";
import MHeader from "./header.vue";
import MHeaderB from "./headerB.vue";

export default {
  name: "m-main",
  components:{
    MFooter,
    MHeader,
    MHeaderB
  },
  created(){
    const currentUrl = window.location.href;
    console.log('11111111112222222',currentUrl);
  },
  mounted() {
    sessionStorage.getItem('closeTop')?document.querySelector("#main-content").style.paddingTop="56px":""
  },
  methods: {
    handleEmitScroll(){
      this.$refs.mheader.handleScroll()
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.m-main {
  width: 100%;
  .content{
    width: 100%;
    padding-top: 1.28rem;
  }
  .content-nopadding{
     width: 100%;
     padding-top: 56px;
  }
  .no-header{
    width: 100%;
  }
}
</style>
