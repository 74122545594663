<template>
	<div class="container">


		<div class="navBox" v-if="$route.meta.showHeader">
			<div class="navLeft" @click="goH()">
				<img src="../assets/images/agent/leftto.png" alt="图片">
			</div>
			<div class="navRight">
				<p>{{ $route.meta.title }}</p>
			</div>
			<div>
			</div>
		</div>
		<div class="margintopBox">

		</div>
		<!-- <div class="personal-info" v-if="!($route.meta.showHeader && $route.meta.showHeadera && $route.meta.showHeaderb)"> -->
		<div class="personal-info" v-if="!($route.meta.showHeader && $route.meta.showHeadera)">

			<div class="imgBox">
				<img src="../assets/images/agent/toxB.png" alt="">
			</div>
			<div class="rightBox">
				<div class="helloBox">
					<div class="hello">
						<p @click="ReviseNickname">{{ nicknameshow }}<span v-if="nickname.length > 6">...</span>
						</p>
					</div>
					<!-- <div class="buttonBox" @click="goto">退出</div> -->
					<!-- <div>
						<p style="font-size: 18px; font-weight: 600; color: #ffffff; margin-left: 0px;">
							{{ userInfo.hierarchy == 1 ? '一级' : '二级'}}
						</p>
					</div> -->


				</div>
				<div class="infoBox">
					<div class="infoleftBox">
						<!-- <p class="p">身份类型：<span class="span">{{ userInfo.agent_level}}</span></p> -->
						<p class="p">分佣比例：<span class="span" @click="details()">{{ Math.floor(userInfo.rebate_ratio * 100)
								}}%</span>
						</p>

						<p class="p">总订单数：<span class="span">{{ userInfo.numberOfOrders }}</span></p>
						<p style="width: 200%;" class="p">身份类型：<span class="span">
								{{ userInfo.hierarchy == 1 ? '一级代理' : '二级代理' }}
							</span>
						</p>

					</div>
					<div class="inforightBox">
						<p class="p">已提现：<span class="span">{{ Math.floor(userInfo.Withdrawn) }}</span></p>
						<p class="p">可提现：<span class="span">{{ Math.floor(userInfo.totalTransaction) }}</span></p>
					</div>

				</div>
			</div>
		</div>
		<div class="personal-infoB" v-if="$route.meta.showHeader && $route.meta.showHeaderc">

			<div class="infoBMaxBox">
				<div class="infoBChil">
					<div class="topBox">
						<p>已提现(元)</p>
					</div>
					<div class="butBox">
						{{ userInfo.Withdrawn }}
					</div>
				</div>
				<div class="infoBChil">
					<div class="topBox">
						<p>可提现(元)</p>
					</div>
					<div class="butBox">
						{{ userInfo.totalTransaction }}
					</div>
				</div>
				<div class="infoBChil">
					<div class="topBox">
						<p>提现中(元)</p>
					</div>
					<div class="butBox">
						{{ userInfo.Operation }}
					</div>
				</div>
			</div>
			<div class="tixianBox" @click="tixian">
				立即提现
			</div>

		</div>

		<div class="tips" v-show="Detailsshow">
			<div class="ticent">
				<div class="Detailstitle">
					<p>佣金分发规则</p>
				</div>
				<div class="Detailsp">
					<p>1，二级代理分佣比例为：<span style="color: red;">{{ Math.floor(userInfo.second_level_ratio * 100 ) }}%。</span></p>
					<p>2，一级代理佣金计算规则为：<span style="color: red;"><br>一级订单佣金 = 订单金额 * 一级分佣比例。</span></p>
					<p>3，二级代理佣金计算规则为：<span style="color: red;"><br>二级订单佣金 = 订单金额 * 一级分佣比例 * 二级分佣比例。<br>一级订单佣金 = 订单金额 *
							一级分佣比例 - 二级订单佣金</span></p>
				</div>
				<div class="Detailsbut">
					<p @click="closeDetails">
						已知晓
					</p>
				</div>

			</div>
		</div>
		<div class="ReviseBox" v-show="ReviseNicknameShow">
			<div class="ReviseBoxTicent">
				<div class="Detailstitle">
					<p>修改昵称</p>
				</div>
				<div class="ReviseBoxInput">
					<input type="text" v-model="ReviseNicknameCop">
					<img class="ReviseBoximg" src="../assets/images/agent/Revise.png" alt="X" @click="ClearNikeName">
				</div>
				<div class="Detailsbut">
					<p @click="ReviseNickname()">
						取消
					</p>
					<p @click="SureRevise()">
						完成
					</p>
				</div>

			</div>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
import courseIndex from './courseIndex.vue';
import axios from 'axios';
export default {
	components: {
		courseIndex
	},
	data() {
		return {
			activeTab: 'tab1',
			user_id: '',
			nickname: '',
			userInfo: '',
			payment_code: '',
			invitation_code: '',
			listData: [
				{
					title: '提现',
					to: 'Withdrawal',
					url: 'assets/images/agent/tixian.png',
					index: 1,
				}, {
					title: '邀请用户',
					to: 'invite',
					url: 'assets/images/agent/yaoqing.png',
					index: 2,
				}, {
					title: '用户管理',
					to: 'user',
					url: 'assets/images/agent/yonghuguali.png',
					index: 3,
				}, {
					title: '订单记录',
					to: 'order',
					url: 'assets/images/agent/dingdan.png',
					index: 4,
				}
			],
			Detailsshow: false,
			ReviseNicknameShow: false,
			ReviseNicknameCop: '',
			token: ''
		};
	},
	created() {
		this.token = sessionStorage.getItem('userinfo');
		if (!this.token) {
			this.$router.push('/m-logon');
		}

		this.user_id = JSON.parse(sessionStorage.getItem('userinfo')).user_id;
		this.nickname = JSON.parse(sessionStorage.getItem('userinfo')).nickname;
		this.invitation_code = JSON.parse(sessionStorage.getItem('userinfo')).invitation_code;
		this.getData();
	},
	mounted() {
		document.dispatchEvent(new Event('render-event'));
		this.getData();
	},
	watch: {
		ReviseNicknameCop(newValue) {
			if (!this.isChinese && newValue.length > 12) {
				this.ReviseNicknameCop = newValue.slice(0, 12);
			}
			if (this.isChinese && newValue.length > 6) {
				this.ReviseNicknameCop = newValue.slice(0, 6);
			}
		}
	},
	computed: {
		nicknameshow() {
			if (this.nickname.length > 6) {
				return this.nickname.slice(0, 6);
			} else {
				return this.nickname;
			}
		},
		isChinese() {
			const reg = /[\u4e00-\u9fa5]/;
			return reg.test(this.ReviseNicknameCop);
		}
	},
	methods: {
		details() {
			this.Detailsshow = true
		},
		closeDetails() {
			this.Detailsshow = false
		},
		goto() {
			sessionStorage.removeItem('userinfo');
			this.$router.push('/m-logon');
		},
		goH() {
			this.$router.push('/m-course');
		},
		getData() {
			var thisc = this;
			axios.post('https://teach-api.go-master.com/v4/Gomaster/getCarteData', {
				user_id: this.user_id
			})
				.then(function (response) {
					console.log('个人数据');

					thisc.userInfo = response.data;
					console.log('个人数据', thisc.userInfo);
				})
				.catch(function (error) {
					console.error('Error fetching user list:', error);
				});
		},
		tixian() {
			this.invitation_code = JSON.parse(sessionStorage.getItem('userinfo')).invitation_code;

			this.payment_code = JSON.parse(sessionStorage.getItem('userinfo')).payment_code;

			if (!this.payment_code) {
				this.payment_code = sessionStorage.getItem('payment_code');
			}

			if (this.userInfo.totalTransaction == 0) {
				alert("您暂无可提现余额！")
				return;
			}

			if (this.payment_code) {
				var thisc = this;
				axios.post('https://teach-api.go-master.com/v4/Gomaster/Withdrawal', {
					user_id: this.user_id,
					invitation_code: this.invitation_code,
					amount: this.userInfo.totalTransaction
				})
					.then(function (response) {
						thisc.getData()
						alert('提现成功，需等待2至3个工作日后到账！')
					})
					.catch(function (error) {
						console.error('Error fetching user list:', error);
					});
			} else {
				this.$router.push('/m-course/Operation');
			}
		},
		ReviseNickname() {
			this.ReviseNicknameShow = !this.ReviseNicknameShow;
			this.ReviseNicknameCop = this.nickname;
		},
		ClearNikeName() {
			this.ReviseNicknameCop = '';
		},
		SureRevise() {
			var thisc = this;
			if (thisc.ReviseNicknameCop == '') {
				alert('昵称不能为空！')
				return;
			}
			if (thisc.ReviseNicknameCop == thisc.nickname) {
				thisc.ReviseNicknameShow = false;
				return;
			}

			axios.post('https://teach-api.go-master.com/v4/Gomaster/ReviseNick', {
				user_id: this.user_id,
				invitation_code: this.invitation_code,
				ReviseNicknameCop: this.ReviseNicknameCop
			})
				.then(function (response) {
					thisc.nickname = response.data.nice_name;
					let token = JSON.parse(thisc.token);
					token.nickname = response.data.nice_name;
					sessionStorage.setItem('userinfo', JSON.stringify(token));
					thisc.ReviseNicknameShow = false;
				})
				.catch(function (error) {
					console.error('Error fetching user list:', error);
				});
		},

	}
}
</script>

<style scoped lang="less">
* {
	margin: 0;
	padding: 0;
}

body {
	background-color: #f3f3f3;
}

.container {
	// height: 100vh;
	background-color: #f3f3f3;
	padding-bottom: 10px;
	// box-sizing: border-box;
}

.personal-infoB {
	padding: 10px 10px 10px 10px;
	height: 150px;
	// background: linear-gradient(to right, #FB3266, #FF0B13);
	background: linear-gradient(to right, #7AD830, #4CCD2D);

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: #fff;
	border-radius: 10px;
	margin: 0px 10px 30px 10px;

	.infoBMaxBox {
		display: flex;
	}

	.infoBChil {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 10px 15px;
	}

	.topBox {
		font-size: 14px;
		letter-spacing: 1px;
	}

	.butBox {
		font-size: 18px;
		font-weight: 600;
		margin: 10px 0;
	}

	.tixianBox {
		font-size: 15px;
		width: 90px;
		height: 30px;
		font-weight: 600;
		// color: #FF0B13;
		color: #000;
		border: 1px white solid;
		border-radius: 13px;
		background-color: white;
		text-align: center;
		line-height: 30px;
	}
}

.personal-info {
	padding: 20px 10px 10px 10px;
	height: 150px;

	// background: linear-gradient(to right, #FB3266, #FF0B13);
	background: linear-gradient(to right, #7AD830, #4CCD2D);
	display: flex;
	color: #fff;
	border-radius: 10px;
	margin-bottom: 30px;
	margin: 0px 10px 30px 10px;

	.p {
		margin-bottom: 10px;
	}

	.imgBox {
		width: 60px;
		height: 60px;
		background-color: #f8f8f8;
		border-radius: 50%;
		margin: 10px 10px 0 10px;

		img {
			width: 60px;
			height: 60px;
		}
	}

	.rightBox {
		display: flex;
		flex-direction: column;
		margin-left: 10px;
		width: 80%;

		.helloBox {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 30px;


			.hello {
				// width: 100px;
				font-size: 18px;
				font-weight: 600;
				color: #ffffff;

			}

			.buttonBox {
				font-size: 18px;
				margin-left: 25px;
				font-weight: 600;
				// color: darkorange;
				color: #000000;
			}
		}

		.infoBox {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			margin-top: 10px;
			width: 100%;
			font-size: 15px;
			// color: #d4d4d4;
			// color: #ffffff;
			color: #000;

			.infoleftBox {
				width: 50%;
				text-align: left;
			}

			.inforightBox {
				width: 50%;
				text-align: left;
			}
		}
	}

}


.span {
	// color: rgb(253, 200, 135);
	color: #FFF;
	font-weight: 600;
}

.navigation {
	li {
		list-style: none;
		margin: 0 10px;

		.listBox {
			box-sizing: border-box;
			padding: 0 20px 0 20px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			height: 50px;
			background-color: #ffffff;
			margin-top: 10px;
			font-size: 17px;
			// font-weight: 600;
			color: #555;
			border-radius: 10px;

			// box-shadow: 0 0 10px rgb(255, 255, 255);
			.left {
				display: flex;
				flex-direction: row;
				align-items: center;
			}

			.leftBox {
				padding-right: 5px;
				margin-right: 5px;
				display: flex;
				align-items: center;

				img {
					width: 25px;
					height: 25px;
					// filter: grayscale(0%) brightness(100%) sepia(100%) saturate(10000%) hue-rotate(0deg);
				}
			}

			.middleBox {}

			.rigthBox {
				display: flex;
				align-items: center;

				img {
					width: 25px;
					height: 25px;
					text-align: center;
					// filter: grayscale(0%) brightness(100%) sepia(100%) saturate(10000%) hue-rotate(0deg);
				}
			}
		}
	}
}

.margintopBox {
	height: 20px;
}

.navBox {
	width: 100vw;
	height: 46px;
	display: flex;
	justify-content: space-between;
	background-color: #ffffff;
	align-items: center;
	padding: 0 10px;
	color: #000000;

	.navLeft {
		img {
			width: 23px;
			height: 23px;
			text-align: center;
		}
	}

	.navRight {
		transform: translateX(-20%);
		font-size: 18px;
	}
}

.tips {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0px;
	left: 0px;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000000;

	.ticent {
		width: 320px;
		height: 450px;
		border-radius: 10px;
		background-color: #FFF;
		margin-top: -90px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px;

		.Detailstitle {
			p {
				margin-top: 10px;
				font-size: 20px;
				font-weight: 600;
			}
		}

		.Detailsp {
			margin-top: 10px;

			p {
				font-size: 15px;
				margin-top: 10px;
				line-height: 25px;
				// text-indent: 1em;
			}
		}

		.Detailsbut {
			width: 320px;

			margin-top: 60px;

			border-top: 1px solid rgba(0, 0, 0, 0.2);

			p {
				width: 100%;
				// margin: 0 auto;
				text-align: center;
				margin-top: 15px;
				color: red;
				font-size: 25px;

			}
		}
	}
}

.ReviseBox {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0px;
	left: 0px;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000000;

	.ReviseBoxTicent {
		width: 320px;
		// height: 450px;
		border-radius: 10px;
		background-color: #FFF;
		margin-top: -90px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px;

		.Detailstitle {
			p {
				font-size: 24px;
			}
		}
	}

	.ReviseBoxInput {
		margin-top: 50px;
		height: 50px;
		display: flex;
		border: 2px solid #f5f5f5;
		align-items: center;
		padding: 8px;
		border-radius: 8px;
	}

	.ReviseBoxInput input {
		width: 100%;
		font-size: 24px;
		border: none;
	}

	.ReviseBoximg {
		height: 25px;
		width: 25px;
	}

	.Detailsbut {
		width: 320px;
		margin-top: 60px;
		border-top: 1px solid rgba(0, 0, 0, 0.2);
		display: flex;
		flex-direction: row;

		p {
			width: 100%;
			text-align: center;
			margin-top: 15px;
			color: rgb(0, 0, 0);
			font-size: 25px;

		}
	}
}
</style>