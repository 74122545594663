<template>
  <div class="page">
    <div class="card">
      <div class="title">
        <div class="titlebefore"></div>
        订单信息
      </div>
      <div class="info">
        <div class="col">
          <div class="drak">报名账号：</div>
          <div class="valu">{{ this.query.mobile }}</div>
        </div>
        <div class="col">
          <div class="drak">订单信息：</div>
          <div class="valu">棋妙围棋【体验课】</div>
        </div>
        <div class="col space">
          <div class="drak">下单时间：{{ this.query.time }}</div>
          <div class="drak">已付款</div>
        </div>
        <div class="col">
          <div class="drak">支付金额：</div>
          <div class="valu money">￥9.9</div>
        </div>
        <div style="text-aline: center">
          <img
            class="bannerimg"
            src="../assets/images/sewq_img_shopping_banner.png"
          />
        </div>
      </div>
    </div>
    <div class="card info">
      <div class="title">
        <div class="titlebefore"></div>
        班主任信息
      </div>
      <div class="valu qrtitle">棋妙围棋 小付老师</div>
      <img class="qr" src="../assets/images/fuweuqr.png" />
      <div class="valu add">长按识别二维码，添加老师微信号</div>
    </div>
    <div class="btndown" @click="downapp">下载APP</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      query: {
        mobile: "15623006401",
        time: "2022-03-12 13:33:12",
      },
    };
  },

  created() {
    if (this.$route.query) {
      this.query = this.$route.query;
    }
  },
  methods: {
    downapp() {
      console.log(2222);
      window.open(
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.GoMaster.Bilingual"
      );
    },
  },
};
</script>

<style>
@font-face {
  font-family: "PingFangSChei";
  src: url("../assets/fonts/苹方黑体-中黑-简.ttf");
}
@font-face {
  font-family: "PingFangSC-Regular, sans-serif";
  src: url("../assets/fonts/苹方黑体-准-简.ttf");
}
.qrtitle {
  margin-top: 9px;
}
.money {
  font-family: PingFangSChei;
  font-size: 16px;
  line-height: 1;
}
.add {
  margin-top: 16px;
  padding-bottom: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
}
.valu {
  color: #3e4f66;
}
.info {
  text-align: center;
}
.space {
  justify-content: space-between;
}
.drak {
  color: #7c8ea6;
}
.col {
  padding: 5px 15px;
  display: flex;
}
.qr {
  width: 125px;
  height: 125px;
}
.btndown {
  width: 285px;
  height: 46px;
  background: #ffd609;
  border-radius: 22.5px;
  color: #333333;
  text-align: center;
  font-size: 15px;
  line-height: 46px;
  margin: 20px auto;
}
.card {
  font-size: 14px;
  width: 335px;
  margin: 10px auto;
  background: #ffffff;
  box-shadow: 0px 8px 24px 2px rgba(84, 102, 143, 0.05);
  border-radius: 16px;
  margin-top: 0px;
}
.bannerimg {
  width: 300px;
  height: 100px;
  margin: 10px auto 10px;
}
.title {
  padding-top: 20px;
  margin-left: 17px;
  display: flex;
  font-size: 16px;
  color: #3e4f66;
  line-height: 1;
  font-weight: 600;
}
.titlebefore {
  width: 11px;
  height: 16px;
  margin-right: 12px;
  background: url(../assets/images/sewq_img_shopping_tab@2x.png);
  background-size: 100% 100%;
}
.page {
  background: #f7f8fb;
  font-size: 14px;
  padding-top: 20px;
  min-height: 100vh;
}
</style>