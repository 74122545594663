<template>
  <div class="m-footer" v-if="!$route.meta.hideTopAndBottom">
    <div class="m-footer-l">
      <span>关注我们的公众号</span>
      <img
        src="https://go-master.oss-cn-shanghai.aliyuncs.com/gomaster_douyin/pic/gzh.png"
        alt=""
        v-if="qrcode"
      />
    </div>
    <div class="m-footer-r">
      <span>关于我们</span>
      <!-- <a href="/">Go Master介绍</a>
      <a href="/">商务合作</a> -->
      <p>地址: 上海市普陀区金沙江路980号华大科技园E3-18F</p>
      <p>邮箱: qimiaoweiqi@go-master.com<br />邮编：200000</p>
    </div>
    <p>
      沪ICP备2021009246号-3|营业执照<br />
      沪公网安备 31010702007187号
      <br />
      &#169;上海玖连星教育科技有限公司 所有权利保留
    </p>
  </div>
</template>

<script>
import { getQueryVariable } from "../assets/js/tool";

export default {
  name: "m-footer",
  data() {
    return {
      qrcode: "",
    };
  },
  mounted() {
    this.$http
      .get("/website/qrcode/" + (localStorage.getItem("channelId")||getQueryVariable("channelId") || "5964823168"))
      .then((res) => {
        this.qrcode = res;
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.m-footer {
  width: 100%;
  opacity: 1;
  background: #323333;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
  padding: 64px 27px;
  position: relative;
  & > p {
    width: 100%;
    position: absolute;
    bottom: 10px;
    left: 0;
    font-size: 8px;
    font-family: PingFangSC-Regular, sans-serif;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 1.4;
    margin-bottom: 15px;
    text-align: center;
  }
  span,
  b,
  a {
    display: block;
    white-space: nowrap;
  }
  &-l {
    padding-right: 26px;
    span {
      font-size: 15px;
      font-family: PingFangTC, PingFangTC-Medium;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      margin-bottom: 15px;
    }
    img {
      width: 112px;
    }
  }
  &-r {
    padding-left: 26px;
    border-left: 1px solid #ffffff;
    span {
      font-size: 15px;
      font-family: PingFangTC, PingFangTC-Medium;
      font-weight: 500;
      text-align: left;

      color: #ffffff;
    }
    a {
      font-size: 11px;
      font-family: PingFangSC-Regular, sans-serif;
      font-weight: 400;
      text-align: left;

      color: #ffffff;
      &:last-of-type {
        margin-bottom: 16px;
      }
    }
    p {
      font-size: 8px;
      font-family: PingFangSC-Regular, sans-serif;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      line-height: 1.4;
      margin-bottom: 15px;
    }
  }
}
</style>
