<template>
  <div class="canvas-container" ref="container">

    <img :src="imageUrl" alt="">
    <!-- <div class="pBox">
      <p>长按图片分享邀请</p>
    </div> -->
  </div>
</template>

<script>
import QRCode from 'qrcode';
import axios from 'axios';
export default {
  name: 'index_page',

  data() {
    return {
      invitationCode: '',
      imageUrl: '',
      QRurl: 'https://m.go-master.com/sharepurchaseA/#/',
      nickname: '',
      displayText: '',
    }
  },
  created() {
    var token = localStorage.getItem('userinfo');
    if (!token) {
      this.$router.push('/m-logonB2');
    }

    this.user_id = JSON.parse(localStorage.getItem('userinfo')).user_id;
    this.nickname = JSON.parse(localStorage.getItem('userinfo')).nickname;
    // this.displayText = this.nickname.length > 12 ? this.nickname.slice(0, 12) + '...' : this.nickname;
    this.displayText = this.nickname;
    if (JSON.parse(localStorage.getItem('userinfo')).invitation_code) {
      this.invitationCode = JSON.parse(localStorage.getItem('userinfo')).invitation_code;
    } else {
      this.getData();
    }
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
    this.draw();

    window.addEventListener('resize', this.draw);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.draw);
  },
  methods: {
    // async alt() {
    //   alert('请各位代理长按图片进行分享或保存推广图！');

    // },
    getData() {
      var thisc = this;
      axios.post('https://teach-api.go-master.com/v4/Gomaster/getuserifoData', {
        user_id: this.user_id,
      })
        .then(function (response) {
          thisc.invitationCode = response.data;
          console.log('个人数据', thisc.userInfo);
        })
        .catch(function (error) {
          console.error('Error fetching user list:', error);
        });
    },

    async draw() {
      const container = this.$refs.container;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // 设置画布大小
      canvas.width = container.offsetWidth;
      // canvas.height = 750;
      canvas.height = 750;

      // 绘制背景图
      const bgImage = new Image();

      // bgImage.src = require('../assets/images/agent/fenxiang.png');
      // bgImage.src = require('../assets/images/agent/fenxiangB.png');
      bgImage.src = require('../assets/images/agent/fenxiangD.png');

      bgImage.onload = async () => {
        ctx.drawImage(bgImage, 0, 0, canvas.width, canvas.height);

        // 绘制文本
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.font = 'bold 17px Arial';
        ctx.fillStyle = '#D42E13';
        ctx.fillText(`${this.displayText}`, canvas.width / 1.5222, 565);


        // 生成二维码
        try {
          const qrDataURL = await QRCode.toDataURL(`${this.QRurl}?code=${this.invitationCode}`);
          const qrImage = new Image();
          qrImage.src = qrDataURL;
          qrImage.onload = () => {
            const qrWidth = 160; // 调整二维码图片宽度
            const qrHeight = 160; // 调整二维码图片高度
            const qrX = (canvas.width - qrWidth) / 1.15;
            const qrY = 350; // 调整二维码图片Y轴位置
            ctx.drawImage(qrImage, qrX, qrY, qrWidth, qrHeight);

            // 绘制图片
            const image = new Image();
            image.src = require('@/assets/images/app.png'); // 替换为您的图片路径
            image.onload = () => {
              const imageWidth = 35; // 图片宽度
              const imageHeight = 35; // 图片高度
              const imageX = (canvas.width - imageWidth) / 1.35; // 图片X轴位置
              const imageY = 415; // 图片Y轴位置
              ctx.drawImage(image, imageX, imageY, imageWidth, imageHeight);

              // 将绘制的内容转换为 data URL
              this.imageUrl = canvas.toDataURL();

              // 移除临时 canvas 元素
              canvas.remove();
            };
          };
        } catch (error) {
          console.error('Error generating QR code:', error);
        }
      };
    },
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.canvas-container {
  /* position: absolute; */
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100vh;
  top: 48px;
  overflow-y: auto;
  /* 当内容超出最大高度时启用垂直滚动条 */
  background-color: #FBFFD7;

  img {
    width: 100vw;
    /* height: 100vh; */
    height: auto;
    object-fit: contain;
  }
}

.classBox {
  height: 44px;
  line-height: 44px;
  font-size: 18px;
  font-weight: 600;
}

.pBox {
  position: relative;
  top: -225px;
  left: 90px;
  /* font-size: 20px; */
  width: 250px;
  margin: auto;
  color: black;
  text-align: center;
  background-color: #F6FFE9;
  border-radius: 5px;
  white-space: nowrap;

  p {
    font-size: 18px;
    color: #D42F14;
    font-weight: 600;
    line-height: 30px;
  }
}
</style>
