<template>
  <div class="home-item-1">
    <BlockTitle>四大学习理由<br />孩子智力开发的绝佳选择</BlockTitle>
    <ul>
      <li v-for="(o, i) in list" :key="i" @click="active = i" @touchstart="active = i" :class="active==i?'active-li':''">
        <div class="front">
          <img :src="o.icon" :alt="o.title" />
          <p v-html="o.title"></p>
        </div>
        <div class="back">
          <p>{{ o.text }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import BlockTitle from "./blocktitle";
export default {
  name: "home-item-1",
  components: {
    BlockTitle,
  },
  data() {
    return {
      active: -1,
      list: [
        {
          icon: require("../assets/images/srwq_top_introduce_thinking_img@2x.png"),
          title: "逻辑思维<br>能力",
          text: "孩子逻辑思维、专注力、记忆力、数理计算、问题解决等能力的全方面提升。",
        },
        {
          icon: require("../assets/images/srwq_top_introduce_learning_img@2x.png"),
          title: "培养学习<br>能力",
          text: "围棋教育对于孩子的数理能力、公式化能力、空间感知能力等综合智能的提升有促进作用。",
        },
        {
          icon: require("../assets/images/srwq_top_introduce_likes_img@2x.png"),
          title: "发展兴趣<br>爱好",
          text: "激发孩子内在动力，培养积极向上不放弃的顽强毅力，自主克服生活中的各种各样的困难和险境。",
        },
        {
          icon: require("../assets/images/srwq_top_introduce_plane_img@2x.png"),
          title: "助力未来<br>成长",
          text: "围棋对儿童的性格有着积极的影响。经研究调查，孩子语言和数理的学习成绩，与思维能力呈正比关系。",
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.home-item-1 {
  width: 100%;
  background: #f7f9fc;
  padding-bottom: 45px;
  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    li {
      width: 137px;
      height: 152px;
      opacity: 1;
      box-shadow: -2px 0px 12px 5px rgba(140, 174, 214, 0.07);
      margin-bottom: 8px;
      position: relative;
      transition:all 0.3s ease-in-out;
      transform-style:preserve-3d;

      &:not(:nth-of-type(2n)) {
        margin-right: 8px;
      }
      .front,.back {
        backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        background: #ffffff;   
      border-radius: 8px;
    overflow: hidden;
      }
      .front {
        width: 100%;
        height: 100%;
        padding: 23px 28px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        z-index: 5;
        img {
          display: block;
          width: 52px;
          margin-bottom: 8px;
        }
        p {
          font-size: 16px;
          font-family: PingFangTC, PingFangTC-Medium;
          font-weight: 500;
          text-align: center;
          color: #2fccc4;
        }
      }

      .back {
        width: 100%;
        height: 100%;
        padding: 15px;
        z-index: 2;

        p {
          font-size: 13px;
          font-family: PingFangTC, PingFangTC-Light;
          font-weight: 300;
          text-align: justify;
          color: #666666;
          line-height: 19px;
      border-radius: 8px;
      background: #ffffff;
        }
      }
    }
    .active-li{
      transform: rotateY(-180deg);
      .back{
        transform: rotateY(-180deg);
      }
    }
  }
}
</style>
