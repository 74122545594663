<template>
  <div class="pay">
    <div class="pay-body">
      <div class="pay-body-title">报名手机号</div>
      <div class="pay-body-card" v-if="hasDouAuth">
        <Cell :title="`+${form.code} ${form.mobile}`">
          <template #right-icon>
            <Button
              color="#ffdd00"
              round
              size="mini"
              @click="hasDouAuth = false"
              >&nbsp;<span style="color: #333333">修 改</span>&nbsp;</Button
            >
          </template>
        </Cell>
      </div>
      <div class="pay-body-card pay-body-contact" v-else>
        <div class="pay-body-contact-list">
          <CellGroup>
            <Field
              label="手机号"
              v-model="form.mobile"
              clearable
              required
              class="mobile-field"
            >
              <template slot="input">
                <div class="mobile-input">
                  <div class="input-select" @click="showTelList = true">
                    <span>+{{ form.code }}</span
                    ><Icon name="arrow-down" size="10px" />&nbsp;
                  </div>
                  <Field
                    v-model="form.mobile"
                    type="tel"
                    placeholder="请输入联系方式"
                  />
                </div>
              </template>
            </Field>
            <Field
              label="验证码"
              v-model="form.validCode"
              center
              clearable
              type="digit"
              maxlength="4"
              required
              placeholder="请输入短信验证码"
            >
              <template #button>
                <Button
                  size="mini"
                  v-show="canSend"
                  type="primary"
                  class="get-code"
                  @click="handleGetCode"
                  >获取验证码</Button
                >
                <CountDown
                  v-show="!canSend"
                  ref="countDown"
                  :auto-start="false"
                  :time="time"
                  format="ss s"
                  @finish="handleResetCountDown"
                ></CountDown>
              </template>
            </Field>
          </CellGroup>
        </div>
      </div>
      <div class="pay-body-title">商品信息</div>
      <div class="pay-body-card">
        <CellGroup>
          <Cell :title="`【${course.title}】`">
            <template #right-icon>
              <span style="color: #ee0a24">¥ {{ course.price }}</span>
            </template>
          </Cell>
          <div class="product-img">
            <img
              :src="
                course.coverUrl ||
                '../assets/images/dou/sewq_lesson_banner_img@2x.png'
              "
              alt=""
            />
          </div>
        </CellGroup>
      </div>
    </div>
    <SubmitBar
      :decimal-length="2"
      :safe-area-inset-bottom="true"
      button-text="确认下单"
      :price="course.price * 100"
    >
      <template #button>
        <Button
          class="submit"
          type="info"
          :loading="isSubmit"
          loading-text="下单中..."
          @click="handleSubmit"
        >
          <img
            class="pay-loading"
            slot="loading"
            src="../assets/images/loading.svg"
            alt=""
          />
          确认下单
        </Button>
      </template>
    </SubmitBar>
    <Popup
      class="tellist"
      v-model="showTelList"
      position="bottom"
      round
      lock-scroll
      closeable
      :safe-area-inset-bottom="true"
      :close-on-click-overlay="false"
      :close-icon="require('../assets/images/srwq_pop_deleted_icon@2x.png')"
    >
      <div class="pop-title">选择手机号所属国家/地区</div>
      <Picker :columns="codes" :default-index="0" @change="onChange"></Picker>
      <div class="picker-btn" @click="handleSelectChange">确定</div>
    </Popup>
  </div>
</template>

<script>
import {
  SubmitBar,
  Button,
  Toast,
  CellGroup,
  Field,
  Popup,
  Picker,
  Cell,
  Dialog,
  CountDown,
  Icon,
  Divider,
} from "vant";
import { code } from "../assets/js/code";
import wx from "../assets/images/wx.svg";
export default {
  name: "Pay",
  components: {
    SubmitBar,
    Button,
    CellGroup,
    Field,
    Popup,
    Picker,
    Cell,
    CountDown,
    Icon,
    Divider,
  },
  data() {
    return {
      course: {},
      hasDouAuth: false,
      showDetail: false,
      canSend: true,
      time: 60 * 1000,
      isSubmit: false, //支付loading状态
      wx,
      form: {
        contact: "",
        mobile: "",
        code: code[0].code,
        remark: "",
        validCode: "",
      },
      showTelList: false,
      activeIndex: 0,
      DouyinSDK: null,
    };
  },
  computed: {
    codes() {
      const codes = code;
      return codes.map((v) => {
        return `+${v.code} ${v.name}`;
      });
    },
    douVersionAccess() {
      const APP_VERSION_REGX = {
        android: new RegExp("app_version/", "i"),
        ios: new RegExp("aweme_", "i"),
      };
      const ua = navigator.userAgent;
      const os = /(Android);?\s+([\d.]+)?/i.test(ua) ? "android" : "ios";
      let version = parseFloat(ua.split(APP_VERSION_REGX[os])[1]);
      let flag = true;
      let standVersion = "10.4".split(".");
      version = String(version).split(".");
      if (standVersion[0] < version[0]) {
        flag = true;
      } else {
        flag = false;
      }
      return flag;
    },
  },
  created() {
    this.handleGetAllCourses();
  },
  mounted() {
    // if (window.DouyinOpenJSBridge) {
    //   this.DouyinSDK=window.DouyinOpenJSBridge
    //   this.$nextTick(()=>{
    //     this.handleGetDouSdkParams();
    //   })
    // }
  },
  methods: {
    handleGetAllCourses() {
      this.$http.get("/products/information_1").then((res) => {
        this.course = { ...res };
      });
    },
    handleResetCountDown() {
      this.canSend = true;
      this.$refs.countDown.reset();
    },
    handleCheck() {
      if (!this.form.mobile) {
        Toast({
          message: "请输入手机号",
        });
        return false;
      } else if (isNaN(Number(this.form.mobile))) {
        Toast({
          message: "手机号格式不正确",
        });
        return false;
      }
      return true;
    },
    handleGetCode() {
      if (this.handleCheck()) {
        this.canSend = false;
        const { code, mobile } = this.form;
        this.$http
          .post("/sms/send", {
            code,
            mobile,
          })
          .then((res) => {
            this.$refs.countDown.start();
          })
          .catch((value) => {
            this.canSend = true;
          });
      }
    },
    onChange(picker, value, index) {
      this.activeIndex = index;
    },
    handleSelectChange() {
      const current = code[this.activeIndex];
      this.form = {
        ...this.form,
        code: current.code,
      };
      this.showTelList = false;
    },
    handleCheckParams() {
      const { contact, validCode } = this.form;
      if (!validCode) {
        Toast({
          message: "请输入验证码",
        });
        return false;
      }
      return true;
    },
    handleSubmit() {
      if (this.handleCheck() && this.handleCheckParams()) {
        this.handleGetPayLink();
      }
    },
    handleDouJsBridge(data) {
      const { client_key } = data;
      if (this.douVersionAccess) {
        this.DouyinSDK.showOpenAuth({
          params: {
            client_key,
            state: "",
            scope: {
              user_info: 0,
            },
            response_type: "code",
          },
          success: ({ ticket, grant_permissions }) => {
            console.log("获取用户信息");
            console.log(ticket);
            console.log(grant_permissions);
          },
          error: (err) => {
            console.log(err);
          },
        });
      } else {
        this.DouyinSDK.jumpOpenAuth({
          params: {
            client_key,
            state: "",
            scope: {
              user_info: 0,
            },
            response_type: "code",
          },
          success: (res) => {
            console.log(111);
            console.log(res);
          },
          error: (err) => {
            console.log(111);

            console.log(err);
          },
        });
      }
      //抖音授权
    },
    handleGetPayLink() {
      this.isSubmit = true;
      const loading = Toast.loading({
        message: "下单中...",
        forbidClick: true,
        duration: 0,
      });
      this.$http
        .post("/orders/confirm", {
          ...this.form,
          productNo: this.course.productNo,
          channelId: "4534543",
          link: JSON.parse(sessionStorage.getItem("link")),
        })
        .then((res) => {
          loading.clear();
          this.$router.push({path:'/m-dou-success',query:{userId:res.userId,outTradeNo:res.orderNo,courseId:this.course.id}})
        })
        .catch((err) => {
          this.isSubmit = false;
          loading.clear();
        });
    },
    handlePayment(data) {
      this.isSubmit = true;
      const loading = Toast.loading({
        message: "支付中...",
        forbidClick: true,
        duration: 0,
      });
      this.$http
        .post("/orders/pay", {
          ...data,
          method: "WECHAT_H5_PAY",
        })
        .then((res) => {
          const a = document.createElement("a");
          a.href =
            res.credential.h5_url +
            `&redirect_url=${encodeURIComponent(window.location.href)}`;
          a.click();
          loading.clear();
          setTimeout(() => {
            this.isSubmit = false;
          }, 500);
        })
        .catch((err) => {
          this.isSubmit = false;
          loading.clear();
        });
    },
    handleGetDouSdkParams() {
      this.$http
        .get("/douyin/jsapi", {
          url: window.location.href,
        })
        .then((res) => {
          const _this = this;
          this.DouyinSDK.config({
            params: { ...res },
          });
          this.DouyinSDK.ready(() => {
            _this.handleDouJsBridge(res);
          });
          this.DouyinSDK.error((err) => {
            console.log(err);
          });
        });
    },
  },
};
</script>
<style scoped lang="less">
::v-deep.pay {
  width: 100%;
  min-height: 100vh;
  padding: 0 12px;
  background: #f3f4f7;
  font-size: 16px;
  position: relative;
  .van-count-down {
    color: #b4b4b4;
  }
  .van-overlay {
    z-index: 9999 !important;
  }
  .van-popup {
    z-index: 9999 !important;
    padding-bottom: 50px;
    .van-icon__image {
      width: 36px;
      height: 36px;
      transform: translateY(-4px);
    }
    .pop-title {
      height: 56px;
      font-size: 22px;
      font-family: PingFangTC, PingFangTC-Medium;
      font-weight: 500;
      text-align: center;
      color: #ffdd00;
      line-height: 65px;
      margin-bottom: 10px;
    }
    .van-picker {
      margin-bottom: 10px;
      .van-picker-column__item {
        .van-ellipsis {
          opacity: 1;
          font-size: 14px;
          font-family: PingFangTC, PingFangTC-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
        }
      }
      .van-picker-column__item--selected {
        .van-ellipsis {
          font-size: 17px;
          font-family: PingFangTC, PingFangTC-Medium;
          font-weight: 500;
          text-align: left;
          color: #ffdd00;
        }
      }
    }
    .picker-btn {
      width: 324px;
      height: 45px;
      margin: 0 auto;
      line-height: 48px;
      text-align: center;
      opacity: 1;
      background: #ffdd00;
      border-radius: 23px;
      font-size: 20px;
      font-family: PingFangTC, PingFangTC-Medium;
      font-weight: 500;
      color: #333333;
    }
  }
  .van-submit-bar__bar {
    padding: 0;
    padding-left: 12px;
    & > span {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #00000d;
    }
    .van-submit-bar__text {
      & > span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .van-submit-bar__price {
        font-size: 18px;
        font-weight: 600;
        color: #ffdd00;
      }
    }
  }
  &-title {
    text-align: center;
    font-size: 19px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    padding: 25px 0;
  }
  &-back {
    display: block;
    width: 20px;
    position: absolute;
    left: 22px;
    top: 22px;
  }
  &-body {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    &-title {
      color: #333333;
      font-size: 18px;
      padding: 10px 0;
      font-weight: bold;
    }
    &-card {
      background: #ffffff;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
      &:not(:nth-last-of-type(1)) {
        margin-bottom: 12px;
      }
      .product-img {
        width: 100%;
        padding: 20px 15px;
        img {
          display: block;
          width: 100%;
        }
      }
      &-title {
        width: 100%;
        height: 51px;
        line-height: 51px;
        text-align: left;
        padding: 0 20px;
        border-bottom: 1px solid #eeeeee;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
    }
    &-course {
      &-item {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 14px 20px;
        &:not(:nth-last-of-type(1)) {
          border-bottom: 1px solid #eee;
        }
        &-l {
          width: 124px;
          height: 64px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 13px;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        &-r {
          flex: 1;
          width: 0;
          span {
            display: block;
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #00000d;
            line-height: 21px;
            margin-bottom: 10px;
            white-space: pre-wrap;
          }
          div {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            i {
              display: block;
              font-style: normal;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #80858a;
            }
            b {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: normal;
              color: #00000d;
            }
          }
        }
      }
    }
    &-contact {
      &-list {
        .get-code {
          background-color: #ffdd00;
          border-color: #ffdd00;
          color: #333333;
        }
        .mobile-field {
          .mobile-input {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            .input-select {
              background: #ffdd00;
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: flex-start;
              padding: 0 8px;
              padding-right: 0;
              margin-right: 5px;
              border-radius: 2px;
              span {
                font-size: 14px;
                color: #333333;
              }
              img {
                width: 15px;
              }
            }
            .van-field {
              padding: 0;
            }
          }
        }
      }
    }
    &-payType {
      width: 100%;
      .right-icon {
        width: 20px;
      }
      &-list {
        width: 100%;

        &-item {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 17px 28px 17px 21px;
          &-type {
            display: flex;
            flex-wrap: nowrap;
            flex: 1;
            width: 0;
            align-items: center;
            justify-content: flex-start;
            img {
              display: block;
              width: 20px;
              margin-right: 12px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #191919;
            }
          }
          .check-item {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            overflow: hidden;
          }
          .noChecked {
            border: 1px solid #abadb1;
          }
          .isChecked {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
              display: block;
            }
          }
        }
      }
    }
  }
  @keyframes rotate {
    from {
      transform: rotateZ(-180deg);
    }
    to {
      transform: rotateZ(180deg);
    }
  }
  &-loading {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 6px;
    animation: rotate 1s linear infinite;
  }
  .submit {
    width: 130px;
    height: 50px;
    background: #ffdd00;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    border-radius: 0;
    border-color: #ffdd00;
  }
  .van-dialog {
    .van-button__text {
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
}
</style>
