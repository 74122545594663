<template>
  <div id="app">
    <Main>
      <router-view />
    </Main>
  </div>
</template>

<script>
import Main from "./layouts/main";
import logo from '@/assets/images/app.png'
import { getBrowser } from "@/assets/js/tool";
import {
  isWxBrowser,
  handleInWxGetOpenId,
  getQueryVariable,
} from "./assets/js/tool";
export default {
  name: "App",
  components: {
    Main,
  },
  data() {
    return {};
  },
  created() {
    // if (isWxBrowser()) {
    //   handleInWxGetOpenId();
    // }
    if (getQueryVariable("channelId")) {
      localStorage.setItem("channelId", getQueryVariable("channelId"));
    }
    // if (getBrowser.versions.weixin) {
    //     this.handleGetWxShareConfig()
    //   }
    
  },
  methods: {
    handleGetWxShareConfig() {
      this.$http.get('/wechat/jsapi',{
        url:encodeURIComponent(window.location.href)
      }).then((res) => {
        this.handleShareSettings(res)
      })
    },
    handleShareSettings(res) {
      const wx=this.$wx
      wx.config({
        debug: false, // 默认为false  为true的时候是调试模式，会打印出日志
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareWeibo"
        ],
        ...res
      });
      //配置自定义分享内容
      window.share_config = {
        share: {
          imgUrl:logo, // 这里是需要展示的图标
          desc: "Go Master - AI数智人围棋导师", // 这是分享展示的摘要
          title: "下好人生第一盘棋", // 这是分享展示卡片的标题
          link: window.location.href, // 这里是分享的网址
          success: function (res) {
          },
          cancel: function (err) {
          },
        },
      };
      wx.ready(function () {
        wx.updateAppMessageShareData(share_config.share); // 微信好友
        wx.updateTimelineShareData(share_config.share); // 微信朋友圈
        wx.onMenuShareWeibo(share_config.share); // QQ
      });
    },
  },
};
</script>

<style>
body {
  font-size: 16px;
}
#app {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
