<template>
  <div class="home-item-2">
    <BlockTitle>强大的师资阵<br />形成产品质量的有力保障</BlockTitle>
    <Swipe
      class="teacher"
      :loop="false"
      :show-indicators="false"
      touchable
      stop-propagation
      indicator-color="#FFB538"
      :width="175"
    >
      <SwipeItem v-for="(o, i) in teachers" :key="i" class="teacher-swipe">
        <div
          class="teacher-swipe-item"
          :style="{ backgroundImage: `url(${o.avatar})` }"
        >
          <div class="teacher-swipe-item-tag" v-if="o.job">
            <span>{{ o.job }}</span>
          </div>
          <div class="teacher-swipe-item-text">
            <span>{{ o.name }}</span>
            <p v-html="o.intro"></p>
          </div>
        </div>
      </SwipeItem>
      <SwipeItem></SwipeItem>
    </Swipe>
  </div>
</template>

<script>
import BlockTitle from "./blocktitle";
import { Swipe, SwipeItem } from "vant";
export default {
  name: "home-item-2",
  components: {
    BlockTitle,
    Swipe,
    SwipeItem,
  },
  data() {
    return {
      teachers: [
        // {
        //   name: "陈 刚",
        //   avatar: require("../assets/images/srwq_teacher_chenggong_img.png"),
        //   job: "围棋教学总监",
        //   intro:
        //     "· 围棋业余5段 <br>· 国家一级裁判员 <br>· 围棋教练员证 <br>· 围棋教材主编",
        // },
        {
          name: "马盈馨",
          avatar: require("../assets/images/srwq_teacher_mayingxing_img.png"),
          job: "",
          intro: "· 围棋业余5段 <br>· 国家二级裁判员 <br>· 围棋教练员证 <br>· 多次斩获省级围棋比赛冠军",
        },
        {
          name: "戴悦诚",
          avatar: require("../assets/images/srwq_teacher_daiyuecheng_img.png"),
          job: "",
          intro: "· 围棋业余5段 <br>· 国家二级裁判员 <br>· 围棋教练员证 <br>· 英语专业 雅思7.5",
        },
        {
          name: "刘 晨",
          avatar: require("../assets/images/srwq_teacher_liuocheng_img.png"),
          job: "",
          intro: "· 围棋业余5段 <br>· 国家二级裁判员 <br>· 多次斩获省级围棋比赛冠军 <br>· 上海围棋协会教练员讲师",
        },
        // {
        //   name: "丁明珠",
        //   avatar: require("../assets/images/srwq_teacher_dingmingzhu_img.png"),
        //   job: "",
        //   intro: "· 围棋业余5段 <br>· 国家二级裁判员 <br>· 围棋教练员证 <br>· 绍兴围棋比赛冠军",
        // },
        {
          name: "秦 洁",
          avatar: require("../assets/images/srwq_teacher_qingjie_img.png"),
          job: "",
          intro: "· 国家二级裁判员 <br>· 围棋教练员证 <br>· 师范英语专业",
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
::v-deep.home-item-2 {
  width: 100%;
  background: #ffffff;
  padding-bottom: 100px;
  .teacher {
    height: 200px;
    padding: 0 14px;
    overflow: visible;
    &-swipe {
      width: 175px !important;
        padding-right: 10px;

      &-item {
        height: 260px;
        opacity: 1;
        border-radius: 5px;
        box-shadow: -2px 0px 12px 5px rgba(140, 174, 214, 0.07);
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
        justify-content: center;
        &-tag {
          width: 100px;
          background-image: url("../assets/images/srwq_teacher_title_img@2x.png");
          background-repeat: no-repeat;
          background-position: center top;
          background-size: 100px auto;
          font-size: 10px;
          font-family: PingFangTC, PingFangTC-Semibold;
          text-align: left;
          color: #874904;
          text-align: center;
          line-height: 22px;
          position: absolute;
          top: -5px;
          left: 50%;
          transform: translateX(-50%);
        }
        &-text {
          width: 100%;
          height: 130px;
          padding: 11px 12px;
          padding-right:6px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          span {
            font-size: 16px;
            font-family: PingFangTC, PingFangTC-Medium;
            font-weight: 500;
            text-align: left;
            color: #2fccc4;
            margin-bottom: 6px;
          }
          p {
            font-size: 12px;
            font-family: PingFangSC-Light, sans-serif;
            font-weight: 500;
            text-align: justify;
            color: #666666;
            line-height: 16px;
          }
        }
      }
    }
    .van-swipe__indicators{
      bottom: 0;
      .van-swipe__indicator{
        width: 9px;
        height: 9px;
        background-color: #E6EBF2;
        opacity: 1;
        &:last-of-type{
          display: none;
        }
      }
    }
  }
}
</style>
