<template>
	<div class="course">
		<ul>
			<div class="login">
				<div class="login_input">
					<div class="user">
						<img src="@/assets/images/human-PE.png" alt="" />
						<input type="text" placeholder="请输入账号" v-model="userData"
							@input="setUser($event.target.value)" />
					</div>
					<div class="password">
						<img src="@/assets/images/lock-PE.png" alt="" />
						<input :type="nowPassWord ? 'password' : 'text'" placeholder="请输入密码" v-model="passWordData" />
						<img src="@/assets/images/eyes.png" v-if="nowPassWord" @click="hidenPassword" alt=""
							@input="setpassWord($event.target.value)" />
						<img src="@/assets/images/openEyes.png" v-if="!nowPassWord" @click="hidenPassword" alt="" />
					</div>
				</div>
				<div class="login_btn" @click="login">
					登录
				</div>
				<!-- <div class="xieyi">
					<div class="imgBox">
						<img @click="tongy" src="../assets/images/agent/weixuan.png" alt="" v-show="showxuanzhong">
						<img @click="tongy" src="../assets/images/agent/xuanzhon.png" alt="" v-show="!showxuanzhong">
					</div>
					<div>
						<p>我已阅读并同意<span @click="yedu" style="color: #45B70F;">《玖连星商务推广合作协议》</span></p>
					</div>
				</div> -->
			</div>
		</ul>
	</div>
</template>


<script>
import axios from 'axios'
export default {
	name: "course",
	data() {
		return {
			courses: [],
			baseURL: process.env.VUE_APP_BASE_ROUTER,
			nowPassWord: true,
			userData: '',
			passWordData: '',
			passWordType: 'text',
			showxuanzhong: true,
		};
	},
	created() {
		this.handleGetAllCourses()
	},
	methods: {
		setpassWord(newValue) {
			this.passWordData = newValue
		},
		setUser(newValue) {
			this.userData = newValue;
		},
		login() {
			// if (this.showxuanzhong) {
			// 	alert("请先阅读并勾选协议");
			// 	return;
			// }
			if (this.userData) {
				if (this.passWordData) {
					axios.post('https://teach-api.go-master.com/v3/user/mobileLogin', {
						mobile: this.userData,
						password: this.passWordData,
					})
						.then(response => {
							if (response.data.success == 1) {
								sessionStorage.setItem('userinfo', JSON.stringify(response.data.result.userInfo));
								this.$router.push('/m-course');
							} else {
								alert("登录失败，请重新登录！")
								this.userData = '';
								this.passWordData = '';
							}
						})
				}
				else {
					alert("您未输入密码！")
				}
			} else {
				alert("您未输入用户名！")
			}
			// console.log(this.userData);
			// console.log(this.passWordData);

		},
		hidenPassword() {
			this.nowPassWord = !this.nowPassWord
			console.log("点击触发眼睛");
		},
		handleGetAllCourses() {
			this.$http.get("/products").then((res) => {
				let list = [];
				if (res && res.length) {
					list = [...res];
				}
				this.courses = [...list]
			});
		},
		tongy() {
			this.showxuanzhong = !this.showxuanzhong
		},
		yedu() {
			console.log('查看');
			this.$router.push('/agreement');
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="less">
.login {
	width: 90%;
	height: 10rem;
	background-image: url(../assets/images/loginBG-PE.png);
	background-size: 100%;
	background-repeat: no-repeat;
	position: relative;
}

.xieyi {
	position: absolute;
	top: 9.1rem;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	height: 14px;
	.imgBox {
		margin-right: 2px;
		height: 14px;
		img {
			width: 14px;
			height: 14px;
		}
	}
}

.login_text {
	width: 200px;
	text-align: center;
	font-size: 0.65rem;
	color: #154400;
	position: absolute;
	top: 0.8rem;
}

.login_input {
	width: 80%;
	margin: auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	position: absolute;
	left: 50%;
	top: 4.7rem;
	transform: translate(-50%, -50%);
	font-size: 0.50rem;
}

.login_btn {
	width: 80%;
	height: 1.4rem;
	background-image: url(../assets/images/loginBtn-PE.png);
	background-size: 100%;
	background-repeat: no-repeat;
	position: absolute;
	left: 50%;
	top: 8.1rem;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	font-size: 0.50rem;
}

.user {
	width: 100%;
	height: 1.5rem;
	background-color: #F0F3EB;
	border-radius: 60px;
	position: relative;
}

.user>img {
	width: 0.6rem;
	height: 0.7rem;
	position: absolute;
	left: 12%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.user>input {
	width: 60%;
	height: 80%;
	border: none;
	background-color: transparent;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.password {
	width: 100%;
	height: 1.5rem;
	background-color: #F0F3EB;
	border-radius: 60px;
	position: relative;
	top: 20px;
}

.password>img:nth-child(1) {
	width: 0.6rem;
	height: 0.7rem;
	position: absolute;
	left: 12%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.password>img:nth-of-type(2) {
	width: 0.6rem;
	height: 0.4rem;
	position: absolute;
	right: 3%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.password>input {
	width: 60%;
	height: 80%;
	border: none;
	background-color: transparent;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.course {
	height: 100vh;
	background: #f7f9fc;
	&-banner {
		width: 100%;

		img {
			display: block;
			width: 100%;
		}
	}

	ul {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: center;
		padding: 58px 0;
		background: #f7f9fc;

		li {
			width: 273px;
			height: 246px;
			background: #ffffff;
			border-radius: 8px;
			box-shadow: -2px 0px 12px 5px rgba(140, 174, 214, 0.07);
			padding: 19px 35px;
			padding-top: 26px;
			display: flex;
			flex-wrap: nowrap;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 22px;

			.top {
				width: 100%;
				display: flex;
				flex-wrap: nowrap;
				align-items: flex-start;
				justify-content: space-between;
				margin-bottom: 15px;

				b,
				p,
				span {
					word-break: normal;
					white-space: nowrap;
				}

				b {
					font-size: 17px;
					font-family: PingFangTC, PingFangTC-Semibold;
					text-align: center;
					color: #2fccc4;
					font-weight: normal;
				}

				p {
					font-size: 13px;
					font-family: PingFangTC, PingFangTC-Medium;
					font-weight: 500;
					text-align: justify;
					color: #2fccc4;

					span {
						color: #2fccc4;
					}
				}
			}

			img {
				display: block;
				width: 203px;
				margin-bottom: 20px;
			}

			.btn {
				width: 121px;
				height: 32px;
				opacity: 1;
				background: #ffcb38;
				border-radius: 16px;
				text-align: center;
				line-height: 36px;
				font-size: 17px;
				font-family: PingFangTC, PingFangTC-Semibold;
				text-align: center;
				color: #ffffff;

				a {
					color: #ffffff;

				}
			}
		}
	}
}
</style>
